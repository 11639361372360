import React from 'react'
import { observer } from 'mobx-react-lite'
import {
  Button,
  Select,
  RadioGroup,
  Radio,
  FormControl,
} from '@material-ui/core'
import { withStyles, WithStyles } from '@material-ui/core/styles'
import TextInput from 'common/components/TextInput'
import RootStore from 'common/stores/RootStore'
import { useField, Formik, FormikProps, Form } from 'formik'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import {
  FIELDS,
  AWS_SUPPORT_TYPES,
  CUSTOMER_TYPES,
  FIELD_HELPER_TEXTS,
  ORG_FORM_INITIAL_STATE,
} from './constants'

import {
  computeFieldStyleProps,
  renderSelectLabel,
  renderSelectHelperText,
  validateRequired,
  SelectAndLabel,
  parseAndSetField,
} from './helpers'

const styles = () => ({
  select: {
    '& > fieldset': {
      backgroundColor: 'white !important',
    },
  },
  selectError: {
    '& > fieldset': {
      backgroundColor: 'white !important',
      borderColor: 'red !important',
    },
  },
})

type ComponentProps = {
  selectedCustomerId?: string
  marketplaceSignupValue?: boolean
}

type Props = FormikProps<typeof ORG_FORM_INITIAL_STATE> &
  WithStyles &
  ComponentProps

const AddOrgFormWithoutStyles: React.FC<Props> = observer(
  ({ classes, selectedCustomerId, handleSubmit }) => {
    const { billingroot, customer, organisation } = React.useContext(RootStore)

    const [
      customerIdField,
      { error: customerIdError, touched: customerIdTouched },
    ] = useField({ name: FIELDS.CUSTOMER, validate: validateRequired })

    const selectedCustomer = customer.list.find(
      (customer) => customer.id === customerIdField.value,
    )
    const customerTypeDirect =
      selectedCustomer && selectedCustomer.type === CUSTOMER_TYPES.DIRECT

    const defaultOrg =
      selectedCustomer &&
      organisation
        .list(selectedCustomer.id)
        .find(({ name }) => name === 'default')

    const [marketplaceSignupField, _, marketplaceFieldHelperProps] = useField({
      name: FIELDS.MARKETPLACE_SIGNUP,
      validate: validateRequired,
    })

    const [
      billingRootField,
      { touched: billingRootTouched, error: billingRootError },
    ] = useField({ name: FIELDS.BILLING_ROOT, validate: validateRequired })

    const [orgAliasField, { touched: orgAliasTouched, error: orgAliasError }] =
      useField({ name: FIELDS.ORG_ALIAS, validate: validateRequired })

    const [
      emailTemplateField,
      { touched: emailTemplateTouched, error: emailTemplateError },
    ] = useField({
      name: FIELDS.EMAIL_TEMPLATE,
      validate: validateRequired,
    })

    const [
      awsSupportTypeField,
      { error: awsSupportTypeError, touched: awsSupportTypeTouched },
    ] = useField({ name: FIELDS.AWS_SUPPORT_TYPE, validate: validateRequired })

    const [partnerSupportField] = useField({
      name: FIELDS.PARTNER_SUPPORT,
      validate: !customerTypeDirect ? validateRequired : undefined,
    })

    const [salesforceIdField] = useField({
      name: FIELDS.SALESFORCE_ID,
      validate: validateRequired,
    })

    const [spotlightIdField] = useField({ name: FIELDS.SPOTLIGHT_ID })

    const [
      idamUserEmailField,
      { touched: idamUserEmailTouched, error: idamUserEmailError },
    ] = useField({ name: FIELDS.IDAM_USER_EMAIL, validate: validateRequired })

    const [
      idamUserFirstNameField,
      { touched: idamUserFirstNameTouched, error: idamUserFirstNameError },
    ] = useField({
      name: FIELDS.IDAM_USER_FIRST_NAME,
      validate: validateRequired,
    })

    const [
      idamUserLastNameField,
      { touched: idamUserLastNameTouched, error: idamUserLastNameError },
    ] = useField({
      name: FIELDS.IDAM_USER_LAST_NAME,
      validate: validateRequired,
    })

    React.useEffect(() => {
      billingroot.fetch('UNUSED')
      customer.fetch()
    }, [])

    React.useEffect(() => {
      const fetchOrgs = async () => {
        if (selectedCustomer) {
          await organisation.fetch(selectedCustomer.id)
          const defaultOrg =
            selectedCustomer &&
            organisation
              .list(selectedCustomer.id)
              .find(({ name }) => name === 'default')
          if (defaultOrg) {
            marketplaceFieldHelperProps.setValue(
              Boolean(Number(defaultOrg.marketplaceSignup)),
            )
            // marketplaceMetaProps.touched
          }
        }
      }
      fetchOrgs()
    }, [selectedCustomer])

    return (
      <Form onSubmit={handleSubmit}>
        <FormControl fullWidth={true}>
          <SelectAndLabel>
            {renderSelectLabel(customerIdField.name, 'Customer*')}
            <Select
              disabled={typeof selectedCustomerId === 'string'}
              {...customerIdField}
            >
              <option disabled value="">
                Select a customer
              </option>
              {customer.list.map(({ id, name }) => (
                <option value={id} key={id}>
                  {name}
                </option>
              ))}
            </Select>
            {renderSelectHelperText(customerIdError, customerIdTouched)}
          </SelectAndLabel>
        </FormControl>

        {customerTypeDirect === false ? (
          <SelectAndLabel>
            {renderSelectLabel(partnerSupportField.name, 'Partner Support?')}
            <RadioGroup row>
              <div className="radio">
                <Radio
                  name={partnerSupportField.name}
                  value={true}
                  checked={partnerSupportField.value === true}
                  onChange={parseAndSetField(partnerSupportField.onChange)}
                />
                Yes
              </div>
              <div className="radio">
                <Radio
                  name={partnerSupportField.name}
                  value={false}
                  checked={partnerSupportField.value === false}
                  onChange={parseAndSetField(partnerSupportField.onChange)}
                />
                No
              </div>
            </RadioGroup>
          </SelectAndLabel>
        ) : null}

        <SelectAndLabel>
          {defaultOrg
            ? renderSelectLabel(
                marketplaceSignupField.name,
                'AWS Marketplace Signup?',
              )
            : null}
          <React.Fragment>
            {defaultOrg ? (
              <RadioGroup row>
                <div className="radio">
                  <Radio
                    name={marketplaceSignupField.name}
                    value={true}
                    checked={marketplaceSignupField.value}
                    onChange={parseAndSetField(marketplaceSignupField.onChange)}
                  />
                  Yes
                </div>
                <div className="radio">
                  <Radio
                    name={marketplaceSignupField.name}
                    value={false}
                    checked={!marketplaceSignupField.value}
                    onChange={parseAndSetField(marketplaceSignupField.onChange)}
                  />
                  No
                </div>
              </RadioGroup>
            ) : null}
          </React.Fragment>
        </SelectAndLabel>

        <FormControl fullWidth={true}>
          <SelectAndLabel>
            {renderSelectLabel(billingRootField.name, 'Billing Root*')}
            <Select {...billingRootField}>
              <option disabled value="">
                Select a billing root
              </option>
              {billingroot.poolList().map((root) => (
                <option value={root.awsAccountId} key={root.awsAccountId}>
                  {root.email}
                </option>
              ))}
            </Select>
            {renderSelectHelperText(billingRootError, billingRootTouched)}
          </SelectAndLabel>
        </FormControl>

        <TextInput
          fullWidth
          monospace
          id={orgAliasField.name}
          label="Alias*"
          {...orgAliasField}
          {...computeFieldStyleProps(
            orgAliasError,
            orgAliasTouched,
            FIELD_HELPER_TEXTS[FIELDS.ORG_ALIAS],
          )}
        />
        <div style={{ opacity: 1 }}>
          <TextInput
            fullWidth
            monospace
            id={emailTemplateField.name}
            label="Email domain template*"
            {...emailTemplateField}
            {...computeFieldStyleProps(
              emailTemplateError,
              emailTemplateTouched,
              'e.g. aws-${Stax::AccountId}@fakecompany.com',
            )}
          />
        </div>

        <FormControl fullWidth={true}>
          <SelectAndLabel>
            {renderSelectLabel(awsSupportTypeField.name, 'AWS Support Type*')}
            <Select {...awsSupportTypeField}>
              {Object.values(AWS_SUPPORT_TYPES).map((awsSupportType, index) => (
                <option value={awsSupportType} key={index}>
                  {awsSupportType}
                </option>
              ))}
            </Select>
            {renderSelectHelperText(awsSupportTypeError, awsSupportTypeTouched)}
          </SelectAndLabel>
        </FormControl>

        <TextInput
          fullWidth
          monospace
          id={spotlightIdField.name}
          label="Spotlight External ID"
          helperText={FIELD_HELPER_TEXTS[FIELDS.SPOTLIGHT_ID]}
          {...spotlightIdField}
        />
        <TextInput
          fullWidth
          monospace
          id={salesforceIdField.name}
          label="Salesforce ID*"
          helperText={FIELD_HELPER_TEXTS[FIELDS.SALESFORCE_ID]}
          {...salesforceIdField}
        />
        <div>
          <h2>First IDAM User</h2>
          <TextInput
            fullWidth
            monospace
            id={idamUserEmailField.name}
            label="Email*"
            {...idamUserEmailField}
            {...computeFieldStyleProps(
              idamUserEmailError,
              idamUserEmailTouched,
              FIELD_HELPER_TEXTS[FIELDS.IDAM_USER_EMAIL],
            )}
          />
          <TextInput
            fullWidth
            monospace
            id={idamUserFirstNameField.name}
            label="First Name*"
            {...idamUserFirstNameField}
            {...computeFieldStyleProps(
              idamUserFirstNameError,
              idamUserFirstNameTouched,
              FIELD_HELPER_TEXTS[FIELDS.IDAM_USER_FIRST_NAME],
            )}
          />
          <TextInput
            fullWidth
            monospace
            id={idamUserLastNameField.name}
            label="Last Name*"
            {...idamUserLastNameField}
            {...computeFieldStyleProps(
              idamUserLastNameError,
              idamUserLastNameTouched,
              FIELD_HELPER_TEXTS[FIELDS.IDAM_USER_LAST_NAME],
            )}
          />
        </div>
        <Button
          type="submit"
          disableRipple
          style={{
            height: '4rem',
            width: '100%',
            backgroundColor: 'red',
            color: 'white',
          }}
        >
          <b>Create Organisation</b>
        </Button>
      </Form>
    )
  },
)

const AddOrgForm = withStyles(styles)(AddOrgFormWithoutStyles)

const AddOrgContainer: React.FC<RouteComponentProps & ComponentProps> = ({
  history,
  selectedCustomerId,
}) => {
  const { provision } = React.useContext(RootStore)

  const handleSubmit = async (values: typeof ORG_FORM_INITIAL_STATE) => {
    const payload = {
      CustomerId: values.customerId,
      BillingAccount: values.billingRoot,
      OrgAlias: values.orgAlias,
      MarketplaceSignup: values.marketplaceSignup,
      AwsSupportType: values.awsSupportType,
      IdamUserEmail: values.idamUserEmail,
      IdamUserFirstName: values.idamUserFirstName,
      IdamUserLastName: values.idamUserLastName,
    }

    // Optional payload keys
    if (values.spotlightId)
      Object.assign(payload, { SpotlightId: values.spotlightId })
    if (values.emailTemplate)
      Object.assign(payload, { EmailTemplate: values.emailTemplate })
    if (values.salesforceId)
      Object.assign(payload, { SalesforceId: values.salesforceId })

    if (values.partnerSupport) {
      Object.assign(payload, { AwsPartnerSupport: values.partnerSupport })
    } else {
      Object.assign(payload, { AwsPartnerSupport: false })
    }

    const res = await provision.add_organisation(payload)

    if (res.status === 200) {
      window.alert('Success! Factory execution started')
      history.push(
        selectedCustomerId ? `/customers/${selectedCustomerId}` : '/provision',
      )
    } else {
      window.alert('An unknown error occurred')
    }
  }

  return (
    <Formik
      validateOnMount={true}
      initialValues={{
        ...ORG_FORM_INITIAL_STATE,
        customerId: selectedCustomerId || '',
      }}
      validateOnChange={false}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <AddOrgForm selectedCustomerId={selectedCustomerId} {...formikProps} />
      )}
    </Formik>
  )
}

export default withRouter(AddOrgContainer)
