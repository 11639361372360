import React, { Fragment } from 'react'
import CustomerListContainer from 'customer/containers/CustomerListContainer'
import PageTitle from 'common/components/PageTitle'
import RootStore from 'common/stores/RootStore'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import Can, { Role } from 'common/components/Can'
import Routes from '../../routes'
import { Button } from '@material-ui/core'

const CustomerListScreen: React.FC<RouteComponentProps> = ({ history }) => {
  const { billingroot } = React.useContext(RootStore)

  React.useEffect(() => {
    billingroot.fetch('UNUSED')
  }, [])

  const numActiveBillingRoots = billingroot.poolList().length

  return (
    <Fragment>
      <PageTitle title="Customers">
        <Can
          requiredRole={Role.deployer}
          yes={
            <div>
              {numActiveBillingRoots > 0 ? (
                <Button onClick={() => history.push(Routes.provision.ADDCUST)}>
                  + New Customer
                </Button>
              ) : null}
            </div>
          }
          no={
            <div>
              <Button
                title="You aren't allowed to create new customers"
                style={{
                  opacity: 0.4,
                }}
              >
                + New Customer
              </Button>
            </div>
          }
        />
      </PageTitle>

      <CustomerListContainer />
    </Fragment>
  )
}

export default withRouter(CustomerListScreen)
