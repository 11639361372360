import React from 'react'
import { observer } from 'mobx-react-lite'
import { createStyles, withStyles, Theme } from '@material-ui/core/styles'
import {
  Grow,
  ClickAwayListener,
  Popper,
  Paper,
  MenuItem,
  MenuList,
  Typography,
  WithStyles,
} from '@material-ui/core'
import AvatarButton from 'navigation/components/AvatarButton'

const styles = ({ spacing, palette }: Theme) =>
  createStyles({
    icon: {
      backgroundColor: '#01ECB4',
      color: 'white',
      textTransform: 'uppercase',
    },
    container: {
      display: 'flex',
      position: 'relative',
      paddingLeft: spacing(2),
      paddingRight: spacing(2),
      zIndex: 1,
    },
    popper: {
      minWidth: spacing(32),
      paddingTop: spacing(2),
    },
    infoContainer: {
      marginLeft: spacing(2),
      marginRight: spacing(2),
      paddingBottom: spacing(2),
      borderBottom: `1px solid ${palette.grey[200]}`,
    },
    infoGroup: {
      paddingTop: spacing(1.5),
    },
    bold: {
      fontWeight: 500,
    },
  })

type HocProps = WithStyles<typeof styles>

interface Props extends HocProps {
  name?: string
  company?: string | null
  region?: string
  orgId?: string
  userId?: string
  roles?: string[]
  customerId?: string
  initials?: string
  logout: Function
}

const NavAccountMenu = observer((props: Props) => {
  const anchorEl = React.createRef<any>()
  const [isOpen, setIsOpen] = React.useState(false)
  const handleClickAway = () => {
    if (!isOpen) return
    setIsOpen(false)
  }
  const logout = () => {
    props.logout()
  }
  const handleOnClick = () => {
    setIsOpen(!isOpen)
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={props.classes.container} ref={anchorEl}>
        <React.Fragment>
          <AvatarButton
            onClick={handleOnClick}
            classes={props.classes.icon}
            text={props.initials}
          />

          <Popper
            open={isOpen}
            anchorEl={anchorEl.current}
            placement="bottom-end"
            transition
            disablePortal
            style={{
              right: '1rem',
              top: '3rem',
              position: 'absolute',
            }}
          >
            {({ TransitionProps }) => (
              <Grow {...TransitionProps}>
                <Paper className={props.classes.popper}>
                  <div className={props.classes.infoContainer}>
                    <Typography variant="body1" className={props.classes.bold}>
                      {props.name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {props.company}
                    </Typography>

                    <div className={props.classes.infoGroup}>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        className={props.classes.bold}
                      >
                        Roles
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {props.roles && props.roles.join(', ')}
                      </Typography>
                    </div>

                    <div className={props.classes.infoGroup}>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        className={props.classes.bold}
                      >
                        Region
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {props.region}
                      </Typography>
                    </div>

                    {props.userId && (
                      <div className={props.classes.infoGroup}>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          className={props.classes.bold}
                        >
                          User ID
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {props.userId}
                        </Typography>
                      </div>
                    )}

                    {props.orgId && (
                      <div className={props.classes.infoGroup}>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          className={props.classes.bold}
                        >
                          Organistation ID
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {props.orgId}
                        </Typography>
                      </div>
                    )}

                    {props.customerId && (
                      <div className={props.classes.infoGroup}>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          className={props.classes.bold}
                        >
                          Customer ID
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {props.customerId}
                        </Typography>
                      </div>
                    )}
                  </div>
                  <MenuList>
                    <MenuItem onClick={logout}>Sign out</MenuItem>
                  </MenuList>
                </Paper>
              </Grow>
            )}
          </Popper>
        </React.Fragment>
      </div>
    </ClickAwayListener>
  )
})

export default withStyles(styles)(NavAccountMenu)
