import React from 'react'
import { createStyles, withStyles, Theme } from '@material-ui/core/styles'
import SvgIcon from '@material-ui/core/SvgIcon'

interface NoDataIconProps {
  classes: any
  className: any
}

const styles = (theme: Theme) =>
  createStyles({
    root: {},
  })

const NoDataIcon: React.SFC<NoDataIconProps> = (props: NoDataIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 56.2 56.2">
      <path d="M53.6 29.5c-.6 0-1-.4-1.5-.8-.6-.5-1.4-.9-2.1-1.3-1.5-.7-3.1-1.2-4.7-1.5-3-.5-6.2-.4-9.2.1-1 .2-2.1.4-3.1.7-1.8.4-3.6.9-5.6.9-2.2.1-4-.4-5.4-1.4-1.7-1.3-2.3-3.6-1.6-6 .4-1.5 1.3-2.8 2-4 .4-.7 1.4-.9 2.1-.5.7.4.9 1.4.5 2.1-.7 1-1.3 2.1-1.6 3.2-.3 1-.3 2.2.6 2.8.8.6 2 .8 3.5.8 1.6 0 3.2-.4 5-.9 1.1-.3 2.2-.5 3.4-.7 6.1-1 13.9-.8 18.8 3.6.2.2.4.4.6.7.5 1-.2 2-1.2 2.2h-.5z" />
      <path d="M12.4 39c-2.2 0-4.2-.7-6-1.6-1.7-.9-3.3-2.2-4.4-3.8-2.2-3.1-2.6-7.4-.7-10.7s6.1-5.1 9.7-3.8c1.9.7 3.5 2.2 4.3 4 .8 1.9.8 4-.1 5.9-.3.8-1.1 1.6-1.9 1.3-.6-.2-.9-.8-.9-1.4 0-.6.2-1.2.3-1.8.3-2-.8-4.1-2.6-5-1.8-.9-4.2-.5-5.6 1-1.7 1.8-1.7 4.8-.8 7.2.6 1.6 1.6 3 2.9 4 2 1.5 4.6 1.8 7 1.5 2.4-.3 4.7-1.2 7-2 9.4-3.4 19.6-5.8 29.3-3.5 2.1.5 4.3 1.4 5.6 3.2.6.8.7 2.2-.2 2.6-.6.2-1.3-.2-1.8-.6-5.6-3.8-12.9-3.9-19.5-2.4-2.4.5-4.7 1.2-7 2s-4.6 1.6-7 2.3c-1.1.3-2.2.7-3.3 1-.5.2-1.1.3-1.7.4-.5.1-1 .2-1.6.2" />
      <path d="M32.7 53.3c-.7 0-1.3-.1-1.9-.3-2.4-.8-4.1-3-4.3-5.4-.2-3.1 1.3-6.3 4-8.2 2.3-1.6 4.9-2.8 7.6-3.3 2.9-.6 6.1-.5 9.4.3 2.6.6 5.1 1.7 7.9 3.4.7.4.9 1.4.5 2.1-.4.7-1.4.9-2.1.5-2.5-1.5-4.8-2.5-7-3-2.9-.7-5.6-.8-8.1-.3-2.3.5-4.5 1.5-6.5 2.9-1.8 1.3-2.9 3.4-2.7 5.5.1 1.2 1 2.4 2.2 2.8.9.3 2.2.1 2.9-.4.4-.3.5-.6.5-1.1-.1-.6-.4-1.2-.7-1.8-.4-.7-.2-1.6.5-2.1.7-.4 1.6-.2 2.1.5.5.9 1 1.9 1.1 3.1.1 1.5-.4 2.8-1.6 3.7-1 .6-2.4 1.1-3.8 1.1M33 12h4v4h-4zM2.1 43.8h4v4h-4zM16.9 47.6h4.5v4.5h-4.5zM7 4h7v7H7z" />
    </SvgIcon>
  )
}

export default withStyles(styles)(NoDataIcon)
