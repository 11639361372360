import {
  observable,
  action,
  computed,
  ObservableMap,
  makeObservable,
} from 'mobx'
import { Status } from 'common/api'
import { Customer } from 'customer/stores/types'
import { CustomerApi } from 'customer/stores/CustomerApi'
import { RootStore } from 'common/stores/RootStore'
import { Organisation } from 'organisation/stores/types'

type CustomerId = string

export default class CustomerStore {
  root: RootStore
  api: CustomerApi
  endpoint: string

  error: Error | undefined

  constructor(root: RootStore, api: CustomerApi, endpoint = '/customers') {
    makeObservable(this, {
      customers: observable,
      active: observable,
      status: observable,
      fetch: action,
      decorateWithOrgs: action,
      setActive: action,
      list: computed,
      isLoading: computed,
    })

    this.root = root
    this.api = api
    this.endpoint = endpoint
  }

  customers: ObservableMap<CustomerId, Customer> = observable.map()

  active: Customer | undefined

  status: Status = Status.Idle

  sorted = (attribute: keyof Customer = 'name') => {
    return this.list.sort((a: Customer, b: Customer): number => {
      const fieldA = a[attribute]
      const fieldB = b[attribute]

      if (typeof fieldA === 'string' && typeof fieldB === 'string') {
        const textA = fieldA.toUpperCase()
        const textB = fieldB.toUpperCase()
        return textA < textB ? -1 : textA > textB ? 1 : 0
      } else {
        return 0
      }
    })
  }

  fetch = async (force = false) => {
    try {
      this.status = Status.Pending
      const res = await this.api.fetch(this.endpoint, force)
      res.data.customers.forEach((customer: Customer) => {
        this.customers.set(customer.id, customer)
      })
      await this.decorateWithOrgs()
      this.status = Status.Success
    } catch (err: any) {
      this.status = Status.Error
      this.error = err
    }
  }

  decorateWithOrgs = async () => {
    try {
      const res = await this.api.fetch(`/organisations`)
      const organisations: Organisation[] = res.data.organisations
      this.customers.forEach((customer) => {
        this.customers.set(customer.id, {
          ...customer,
          orgs: organisations
            .filter((org) => org.customerId === customer.id)
            .map(({ id }) => id),
        })
      })

      console.log({ customers: JSON.parse(JSON.stringify(this.customers)) })
    } catch (error) {
      console.error('Error fetching orgs to decorate customers, moving on...', {
        error,
      })
    }
  }

  setActive = async (id: CustomerId) => {
    if (!this.customers.has(id)) {
      await this.fetch()
    }

    const customer = this.customers.get(id)
    if (customer) {
      this.active = customer
    }
  }

  get list() {
    return Array.from(this.customers.values())
  }

  get isLoading() {
    return this.status === Status.Pending
  }

  get = (customerId: CustomerId) => {
    return this.customers.get(customerId)
  }
}
