import { WithStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { createStyles, Theme, withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React from 'react'

const styles = ({ spacing, breakpoints }: Theme) =>
  createStyles({
    top: {
      flexWrap: 'wrap',
      paddingBottom: '0.5rem',
      [breakpoints.up('sm')]: {
        flexWrap: 'nowrap',
      },
    },
    section: {},
    actions: {
      flexWrap: 'nowrap',
      justifyContent: 'flex-start',
      [breakpoints.up('sm')]: {
        justifyContent: 'flex-end',
      },
    },
    title: {
      fontWeight: 'bold',
    },
  })

interface Props extends WithStyles<typeof styles> {
  title: string
  children?: React.ReactChild
  actions?: React.ComponentType
}

const PageSubtitle = ({ title, children, actions, classes }: Props) => {
  const Actions = actions
  return (
    <Grid container={true} className={classes.top} alignItems="center">
      <Grid className={classes.section} item={true}>
        <Typography
          component="h2"
          variant="h5"
          noWrap={true}
          className={classes.title}
        >
          {title}
        </Typography>
      </Grid>
      {children && <Grid item={true}>{children}</Grid>}
      {Actions && (
        <Grid container={true} className={classes.actions}>
          <Grid item={true}>
            <Actions />
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default withStyles(styles)(PageSubtitle)
