import { Route, Switch } from 'react-router'

import PageContent from 'common/components/PageContent'

import Routes from '../../routes'
import CustomerListScreen from './CustomerListScreen'
import CustomerDetailScreen from './CustomerDetail'

const CustomerScreen = () => (
  <PageContent>
    <Switch>
      <Route
        exact
        path={Routes.customer.INDEX}
        component={CustomerListScreen}
      />
      <Route
        path={Routes.customer.ORGDETAIL}
        component={CustomerDetailScreen}
      />
      <Route path={Routes.customer.DETAIL} component={CustomerDetailScreen} />
    </Switch>
  </PageContent>
)

export default CustomerScreen
