import React from 'react'
import { observer } from 'mobx-react-lite'
import Snackbar from 'common/components/Snackbar'
import RootStore from 'common/stores/RootStore/RootStore'
import { Button } from '@material-ui/core'

const redirect = async () => {
  // TODO: there must be a better way
  window.location.reload()
}

const Message = <span id="message-id">Your session expired</span>

const Action = (
  <Button
    key="login"
    variant="text"
    size="small"
    onClick={redirect}
    style={{ color: '#fff' }}
  >
    Login
  </Button>
)

/**
 * Handles authentication things - currently only expired session
 */
const AuthContainer = observer(() => {
  const { auth } = React.useContext(RootStore)
  return (
    <Snackbar
      open={auth.hasError}
      variant="error"
      message={Message}
      action={Action}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      aria-describedby="message-id"
    />
  )
})

export default AuthContainer
