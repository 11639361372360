import React from 'react'
import {
  createStyles,
  withStyles,
  WithStyles,
  Theme,
} from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { formatDistance, parseISO } from 'date-fns'
import ComponentVersion from 'component/components/ComponentVersion'

type Props = WithStyles<typeof styles> & {
  componentName: string
  status: string
  version: string
  commitAuthor: string
  createdTS: string
  changeLog: string
  componentDeploymentOrder: number
  onRemove: any
  successfulRelease: boolean | undefined
}

const styles = ({ spacing, palette }: Theme) =>
  createStyles({
    container: {
      borderBottom: `1px solid ${palette.divider}`,
      marginBottom: spacing(1.5),
    },
    header: {
      fontWeight: 'bold',
    },
    version: {},
    body: {
      paddingTop: spacing(0.5),
      paddingBottom: spacing(0.5),
      color: palette.grey[400],
    },
    deploymentOrder: {
      paddingBottom: spacing(1),
    },
    removeLink: {
      textDecoration: 'underline',
      color: palette.grey[400],
    },
    itemFooter: {
      display: 'flex',
      justifyContent: 'space-between',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  })

const ReleaseItem: React.FC<Props> = ({
  classes,
  componentName,
  status,
  version,
  commitAuthor,
  createdTS,
  changeLog,
  componentDeploymentOrder,
  onRemove,
  successfulRelease,
}) => {
  console.log(status)

  const renderStatusEmoji = () => {
    if (successfulRelease === undefined) return ''
    if (successfulRelease === true) return '✅'
    if (successfulRelease === false) return '💀'
    return ''
  }

  const renderHeader = (): string => {
    let statusString: string = ''
    switch (status) {
      case 'DEPRECATED':
        statusString = 'rollback'
        break
      case 'ACTIVE':
        statusString = 'redeploy'
        break
      case 'AVAILABLE':
        statusString = 'new'
        break
      default:
        statusString = status
    }

    return `${componentName} (${statusString}) ${renderStatusEmoji()}`
  }

  const renderBody = (): string => {
    const parsedTime = formatDistance(parseISO(createdTS), new Date())
    return `${commitAuthor} (${parsedTime} ago): ${changeLog}`
  }

  const removeClick = (componentName: string, version: string) => (e: any) => {
    onRemove(componentName, version)
  }

  return (
    <div className={classes.container}>
      <Typography
        children={renderHeader()}
        variant={'subtitle1'}
        className={classes.header}
      />
      <ComponentVersion version={version} type="GitHash" />
      <Typography children={renderBody()} className={classes.body} />
      <div className={classes.itemFooter}>
        <Typography
          children={`Deployment order: ${componentDeploymentOrder}`}
          variant={'caption'}
          className={classes.deploymentOrder}
        />
        <Typography
          children={'remove'}
          variant={'caption'}
          onClick={removeClick(componentName, version)}
          className={classes.removeLink}
        />
      </div>
    </div>
  )
}

export default withStyles(styles)(ReleaseItem)
