import Paper from '@material-ui/core/Paper'
import { Typography, Grid } from '@material-ui/core'
import React from 'react'
import { buildOAuthUrl, Provider } from 'auth/helper/oAuthURL'
import { OAuthConfig } from 'config'
import { SolidButton } from 'common/components/SolidButton'

interface Props {
  redirect: Function
}
class LoginScreen extends React.Component<Props> {
  handleSignIn = (provider: Provider) => {
    const clientId = window.AWS_CONFIG.JumaAuth.userPoolWebClientId || ''
    const url = buildOAuthUrl(OAuthConfig.oauth, clientId, provider)
    window.location.assign(url)
    this.props.redirect()
  }
  render = () => {
    return (
      <Grid
        container
        alignContent="center"
        justifyContent="center"
        style={{ height: '100vh', background: '#EBEFF1' }}
      >
        <Grid item>
          <Paper
            style={{
              minHeight: '14rem',
              minWidth: '14rem',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '4rem',
            }}
          >
            <img
              style={{
                margin: '2rem',
                marginTop: '0',
                width: '6rem',
                height: '6rem',
              }}
              src="/images/stax-icon-black.svg"
            />

            <Typography
              variant="h4"
              component="h1"
              style={{ paddingBottom: '2rem' }}
            >
              Log in to Juma Admin
            </Typography>
            <SolidButton
              variant="contained"
              color="primary"
              size="large"
              onClick={() => this.handleSignIn(Provider.OKTA)}
            >
              Log in with Okta
            </SolidButton>
          </Paper>
        </Grid>
      </Grid>
    )
  }
}
export default LoginScreen
