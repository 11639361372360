import {
  observable,
  action,
  ObservableMap,
  computed,
  makeObservable,
} from 'mobx'

import { Status } from 'common/api'
import { AccessRequest, Organisation } from 'organisation/stores/types'
import { RootStore } from 'common/stores/RootStore'
import OrganisationApi from 'organisation/stores/OrganisationApi'

// const fetchOrganisationsEndpoint = (customerId: string) => `/organisations/${customerId}`

type CustomerId = string
type OrgId = string
type StaxAccountId = string

class OrganisationStore {
  root: RootStore
  // Separate out concrete API calls to make it easier to
  // test the stores in isolation
  api: OrganisationApi
  endpoint: string
  error: Error | undefined

  constructor(
    root: RootStore,
    api: OrganisationApi,
    endpoint = '/organisations',
  ) {
    makeObservable(this, {
      organisations: observable,
      organisationConfigs: observable,
      current: observable,
      status: observable,
      accessRequestAccounts: observable,
      fetch: action,
      fetchAll: action,
      fetchConfig: action,
      setCurrent: action,
      setDefaultOrg: action,
      invokeSaga: action,
      runAllAccountsSaga: action,
      rediscoverAllAccounts: action,
      runOrgValidation: action,
      runApplyStaxPolicies: action,
      runPolicySync: action,
      runOrganisationalUnitSync: action,
      setConfig: action,
      reset: action,
      isLoading: computed,
      hasError: computed,
      list: action,
      listAll: action,
      listConfig: action,
      submitAccessRequest: action,
    })

    this.root = root
    this.api = api
    this.endpoint = endpoint
  }

  organisations: ObservableMap<CustomerId, Organisation[]> = observable.map()

  organisationConfigs: ObservableMap<OrgId, any[]> = observable.map()

  current: Organisation | undefined

  status: Status = Status.Idle

  accessRequestAccounts: StaxAccountId[] = []

  async fetch(customerId: CustomerId, force = false) {
    try {
      this.status = Status.Pending
      const res = await this.api.fetch(`${this.endpoint}/${customerId}`, force)
      this.organisations.set(customerId, res.data.organisations)
      await this.fetchConfig(customerId)
      this.status = Status.Success
    } catch (err: any) {
      this.status = Status.Error
      this.error = err
    }
  }

  async fetchAll(force = false) {
    try {
      this.status = Status.Pending
      const res = await this.api.fetch(`${this.endpoint}`, force)
      this.organisations.set('all', res.data.organisations)
      this.status = Status.Success
    } catch (err: any) {
      this.status = Status.Error
      this.error = err
    }
  }

  async fetchConfig(customerId: CustomerId) {
    try {
      await Promise.all(
        this.list(customerId).map(async (org) => {
          const res = await this.api.fetch(
            `${this.endpoint}/${org.id}/idam/config`,
          )
          this.organisationConfigs.set(org.id, res.data)
        }),
      )
    } catch (err) {
      console.error('Error fetching org config, moving on...', {
        err,
      })
    }
  }

  setCurrent(customerId: CustomerId, orgId: OrgId) {
    this.current = this.list(customerId).find(
      (organisation) => organisation.id === orgId,
    )
  }

  setDefaultOrg(customerId: CustomerId) {
    this.current = this.list(customerId).find(({ name }) => name === 'default')
  }

  invokeSaga = (orgId: OrgId) => {
    return this.api.invoke('/saga/organisation', orgId)
  }

  runAllAccountsSaga = (orgId: OrgId) => {
    return this.api.runAllAccountsSaga('/saga/organisation/all-accounts', orgId)
  }

  rediscoverAllAccounts = (orgId: OrgId) => {
    return this.api.rediscoverAllAccounts('/saga/accountdiscovery', orgId)
  }

  runOrgValidation = (orgId: OrgId) => {
    return this.api.invoke('/saga/organisation/validation', orgId)
  }

  runApplyStaxPolicies = (orgId: OrgId) => {
    return this.api.applyStaxPolicies(orgId)
  }

  runPolicySync = (orgId: OrgId) => {
    const confirmation = window.prompt(
      `This action will sync the AWS Organizations policies to the Stax DB.\n
      You should only be performing this action if you understand the impact.\n
      Are you sure you want to sync AWS Policies to Stax?\n
      If you are sure, type 'YES' below:\n`,
    )

    if (confirmation === 'YES') {
      return this.api.syncPolicies(orgId)
    }

    return
  }

  runOrganisationalUnitSync = (orgId: OrgId) => {
    const confirmation = window.prompt(
      `This action will sync the AWS Organizations OUs to the Stax DB.\n
      You should only be performing this action if you understand the impact.\n
      Are you sure you want to sync AWS OUs to Stax?\n
      If you are sure, type 'YES' below:\n`,
    )

    if (confirmation === 'YES') {
      return this.api.syncOrganisationalUnits(orgId)
    }

    return
  }

  setConfig = (
    orgId: OrgId,
    key: string,
    valueBool: boolean,
    comment = 'via admin UI',
  ) => {
    const value = valueBool ? 'true' : 'false'
    return this.api.setConfig(
      `${this.endpoint}/${orgId}/idam/config/${key}`,
      value,
      comment,
    )
  }

  reset() {
    this.current = undefined
  }

  get isLoading() {
    return this.status === Status.Pending
  }

  get hasError() {
    return this.error !== undefined
  }

  list = (customerId: CustomerId) => {
    return this.organisations.get(customerId) || []
  }

  listAll = () => {
    return this.organisations.get('all') || []
  }

  listConfig = (orgId: OrgId) => {
    return this.organisationConfigs.get(orgId) || []
  }

  submitAccessRequest = (orgId: OrgId, accessRequest: AccessRequest) => {
    this.accessRequestAccounts.push(accessRequest.staxAccountId)
    return this.api.requestAccountAccess(orgId, accessRequest)
  }
}

export default OrganisationStore
