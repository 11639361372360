import Grid from '@material-ui/core/Grid'
import { createStyles, Theme, withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { Warning } from '@material-ui/icons'
import React, { ReactNode } from 'react'

import NoDataIcon from '../icons/NoDataIcon'

interface NoResultsProps {
  classes: any
  title: string
  subtitle?: string | ReactNode
  isError?: boolean
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {},
    title: {
      color: '#82868e',
      fontWeight: 500,
    },
    description: {
      marginTop: spacing(1),
      color: '#8e8e8e',
    },
    icon: {
      color: '#d8dfe4',
      margin: spacing(2),
      width: '5rem',
      height: '5rem',
    },
  })

// TODO: rename this component to something more generic
const NoResults: React.SFC<NoResultsProps> = (props) => {
  const { classes, title, subtitle = null, isError = false } = props
  return (
    <Grid container={true} justifyContent="center">
      <Grid
        container={true}
        className={classes.root}
        direction="column"
        alignItems="center"
      >
        {!isError && <NoDataIcon className={classes.icon} />}

        {isError && <Warning className={classes.icon} />}

        <Typography className={classes.title} variant="h4">
          {title}
        </Typography>
      </Grid>
      <Grid item={true}>
        <Typography variant="body1" className={classes.description}>
          {subtitle}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(NoResults)
