import { styled } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'

export const SolidButton = styled(Button)(() => ({
  backgroundColor: '#0A7BA7',
  border: 'none',
  color: '#FFF',
  '&:hover, &:disabled': {
    color: '#FFF',
    backgroundColor: '#0A7BA7',
  },
}))
