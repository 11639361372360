import React from 'react'
import { Button, Select, FormControl } from '@material-ui/core'
import RootStore from 'common/stores/RootStore'
import { useField, Formik, FormikProps, Form } from 'formik'
import TextInput from 'common/components/TextInput'
import { withStyles, WithStyles } from '@material-ui/core/styles'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import {
  computeFieldStyleProps,
  renderSelectLabel,
  renderSelectHelperText,
  SelectAndLabel,
  validateRequired,
  validateAwsId,
  validateEmail,
} from './helpers'

enum ACCOUNT_ORIGIN {
  STAX = 'STAX',
  EXTERNAL = 'EXTERNAL',
}

enum FIELDS {
  ACCOUNT_EMAIL = 'accountEmail',
  ACCOUNT_ID = 'accountId',
  ACCOUNT_ORIGIN = 'accountOrigin',
}

const FIELD_HELPER_TEXTS = {
  [FIELDS.ACCOUNT_EMAIL]: 'The Email associated with the billing root account',
  [FIELDS.ACCOUNT_ID]: 'The AWS Account Id of the billing account',
}

type State = {
  accountEmail: string
  accountId: string
  accountOrigin: ACCOUNT_ORIGIN
}

const INITIAL_STATE: State = {
  accountEmail: '',
  accountId: '',
  accountOrigin: ACCOUNT_ORIGIN.STAX,
}

const styles = () => ({
  select: {
    '& > fieldset': {
      backgroundColor: 'white !important',
    },
  },
  selectError: {
    '& > fieldset': {
      backgroundColor: 'white !important',
      borderColor: 'red !important',
    },
  },
})

type Props = FormikProps<typeof INITIAL_STATE> & WithStyles

const AddBillingRootFormWithoutStyles: React.FC<Props> = ({
  handleSubmit,
  classes,
}) => {
  const [
    accountOriginField,
    { touched: accountOriginTouched, error: accountOriginError },
  ] = useField({ name: FIELDS.ACCOUNT_ORIGIN, validate: validateRequired })

  const [
    accountEmailField,
    { touched: accountEmailTouched, error: accountEmailError },
  ] = useField({ name: FIELDS.ACCOUNT_EMAIL, validate: validateEmail })

  const [accountIdField, { touched: accountIdTouched, error: accountIdError }] =
    useField({ name: FIELDS.ACCOUNT_ID, validate: validateAwsId })

  return (
    <Form onSubmit={handleSubmit}>
      <FormControl>
        <SelectAndLabel>
          {renderSelectLabel(accountOriginField.name, 'Account Origin*')}
          <Select {...accountOriginField}>
            {Object.values(ACCOUNT_ORIGIN).map((origin, index) => (
              <option value={origin} key={index}>
                {origin}
              </option>
            ))}
          </Select>
          {renderSelectHelperText(accountOriginError, accountOriginTouched)}
        </SelectAndLabel>
      </FormControl>
      <TextInput
        fullWidth
        id={accountEmailField.name}
        label="AWS Account Email*"
        {...accountEmailField}
        {...computeFieldStyleProps(
          accountEmailError,
          accountEmailTouched,
          FIELD_HELPER_TEXTS[FIELDS.ACCOUNT_EMAIL],
        )}
      />
      <TextInput
        fullWidth
        id={accountIdField.name}
        label="AWS Account Id*"
        {...accountIdField}
        {...computeFieldStyleProps(
          accountIdError,
          accountIdTouched,
          FIELD_HELPER_TEXTS[FIELDS.ACCOUNT_ID],
        )}
      />
      <Button
        type="submit"
        disableRipple
        style={{
          height: '4rem',
          width: '100%',
          backgroundColor: 'red',
          color: 'white',
        }}
      >
        <b>Add billing root</b>
      </Button>
    </Form>
  )
}

const AddBillingRootForm = withStyles(styles)(AddBillingRootFormWithoutStyles)

const AddBillingRootContainer: React.FC<RouteComponentProps> = ({
  history,
}) => {
  const { provision } = React.useContext(RootStore)

  const handleSubmit = async (values: typeof INITIAL_STATE) => {
    const payload = {
      AccountId: values.accountId,
      AccountEmail: values.accountEmail,
      AccountOrigin: values.accountOrigin,
    }

    const res = await provision.add_root(payload)

    if (res.status === 200) {
      window.alert('Success! Factory execution started')
      history.push('/provision')
    } else {
      window.alert('An unknown error occurred')
    }
  }

  return (
    <Formik
      initialValues={INITIAL_STATE}
      validateOnChange={false}
      onSubmit={handleSubmit}
    >
      {(formikProps) => <AddBillingRootForm {...formikProps} />}
    </Formik>
  )
}

export default withRouter(AddBillingRootContainer)
