import React, { Fragment } from 'react'
import { observer } from 'mobx-react-lite'
import { Route, RouteComponentProps, useHistory } from 'react-router'
import {
  Button,
  List,
  ListItem,
  ListItemText,
  styled,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'

import RootStore from 'common/stores/RootStore'
import NoResults from 'common/components/NoResults'
import Loading from 'common/components/Loading'
import { useServices, Service } from 'common/api'
import { Organisation } from 'organisation/stores/types'
import { DateText } from 'common/components/DateText'
import { omit } from 'lodash'
import { Status } from 'common/components/Status'
import customerRoutes, {
  configureSecurityHubLink,
  configureRegionsLink,
} from 'customer/routes'
import { ConfigureSecurityHubModal } from './ConfigureSecurityHubModal'
import { ConfigureRegionsModal } from './ConfigureRegionsModal'

const headers = [
  { title: 'Service' },
  { title: 'Status' },
  { title: 'Created' },
  { title: 'Info' },
  { title: 'Actions' },
]

// type Service = {
//   configuration: Record<string, any>
//   createdBy: string | null
//   createdTS: string
//   modifiedTS: string
//   enabled: boolean
//   id: string
//   organisationId: string
//   service: 'SECURITYHUB' | string
//   status: string
//   userTaskId: string
// }

const Table = styled(MuiTable)({
  '& td': {
    verticalAlign: 'top',
  },
})

export const ServicesListContainer: React.FC<
  RouteComponentProps<{ id: string }>
> = observer(({ history, match }) => {
  const { organisation: organisationStore } = React.useContext(RootStore)

  const organisationId = (organisationStore.current as Organisation)?.id || ''
  const customerId =
    (organisationStore.current as Organisation)?.customerId || ''

  const { services = [], loading, error } = useServices(organisationId || '')

  console.log({ services, loading, error })

  if (!organisationStore.current) {
    return <NoResults title="No organisation selected" />
  }

  if (organisationStore.current.status === 'SUSPENDED') {
    return <NoResults title="Organisation is suspended" />
  }

  if (services.length === 0) {
    return <NoResults title="No services found" />
  }

  if (organisationStore.isLoading || loading) return <Loading />

  const Actions = ({ service }: { service: Service }) => {
    if (service.Service === 'SECURITYHUB') {
      return (
        <SecurityHubActions
          service={service}
          organisationId={organisationId}
          customerId={customerId}
        />
      )
    } else if (service.Service === 'REGIONS') {
      return (
        <RegionsActions
          service={service}
          organisationId={organisationId}
          customerId={customerId}
        />
      )
    }
    return null
  }

  const Info = ({ service }: { service: Service }) => {
    if (
      service.Service === 'SECURITYHUB' &&
      typeof service.Configuration === 'object'
    ) {
      return (
        <Fragment>
          <b>Standards: </b>
          <br />
          <List dense>
            {Object.entries(
              omit(service.Configuration.Standards, 'AWS_REGIONS'),
            ).map(([standard, value]) => (
              <ListItem key={standard} disableGutters>
                <ListItemText
                  primary={standard}
                  secondary={value ? 'On' : 'Off'}
                />
              </ListItem>
            ))}
          </List>
          <b>Regions: </b>
          <div>{service.Configuration.Standards.AWS_REGIONS?.join(', ')}</div>
        </Fragment>
      )
    }
    return <Fragment>{JSON.stringify(service.Configuration)}</Fragment>
  }

  return (
    <Fragment>
      <Route
        exact
        path={customerRoutes.SERVICES_CONFIGURE_SECURITY_HUB}
        render={() => (
          <ConfigureSecurityHubModal
            organisationId={organisationId}
            customerId={customerId}
            orgAlias={organisationStore.current?.alias || ''}
          />
        )}
      />
      <Route
        exact
        path={customerRoutes.SERVICES_CONFIGURE_REGIONS}
        render={() => (
          <ConfigureRegionsModal
            organisationId={organisationId}
            customerId={customerId}
            orgAlias={organisationStore.current?.alias || ''}
          />
        )}
      />
      <Table>
        <TableHead>
          <TableRow>
            {headers.map((header, index) => (
              <TableCell key={index}>{header.title}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {services.map((service, index) => (
            <TableRow key={service.Id}>
              <TableCell>{service.Service}</TableCell>
              <TableCell>
                <Status status={service.Status} />
              </TableCell>
              <TableCell>
                <DateText dateString={service.CreatedTS} />
              </TableCell>
              <TableCell width={'20%'}>
                <Info service={service} />
              </TableCell>
              <TableCell>
                <Actions service={service} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Fragment>
  )
})

const SecurityHubActions = ({
  service,
  organisationId,
  customerId,
}: {
  service: Service
  organisationId: string
  customerId: string
}) => {
  const history = useHistory()
  const configureSecurityHub = () =>
    history.push(configureSecurityHubLink(customerId, organisationId))

  if (service.Service !== 'SECURITYHUB') return null

  if (service.Status === 'ACTIVE') {
    return (
      <Button
        onClick={configureSecurityHub}
        style={{ color: 'red', borderColor: 'red' }}
      >
        Configure
      </Button>
    )
  }

  return (
    <Typography variant="body2">
      Users must self-service to activate Security Hub
    </Typography>
  )
}

const RegionsActions = ({
  service,
  organisationId,
  customerId,
}: {
  service: Service
  organisationId: string
  customerId: string
}) => {
  const history = useHistory()
  const configureRegions = () =>
    history.push(configureRegionsLink(customerId, organisationId))

  if (service.Service !== 'REGIONS') return null

  return (
    <Button
      onClick={configureRegions}
      style={{ color: 'red', borderColor: 'red' }}
    >
      Configure
    </Button>
  )
}
