import { Fragment, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import Amplify from '@aws-amplify/core'
import { init as initSentry } from '@sentry/browser'

import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline'
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles'
import config from 'config'
import { RouterAuthWrapper } from 'auth/containers/AuthWrapper'

import { AmplifyAuthProvider } from 'common/AmplifyListener/AmplifyListener'
import Layout from 'common/components/Layout'
import GlobalStyles from 'common/styles/GlobalStyles'
import NavContainer from 'navigation'
import CustomerScreen from 'customer/screens/Customers'
import ComponentScreen from 'component/screens/Components'
import DashboardScreen from 'dashboard/screens/Dashboard'
import ProvisionScreen from 'provision/screens/ProvisionScreen'
import themeConfig from 'config/theme'

import Routes from 'routes'
import AuthContainer from 'common/containers/AuthContainer'

import './app.css'
import { SWRConfig } from 'swr'
import { fetcher } from 'common/api/api-fetchers'

Amplify.configure({
  API: window.AWS_CONFIG.API,
  // Auth gets configured dynamically in AuthWrapper
})
Amplify.Logger.LOG_LEVEL = 'ERROR'

const theme = createTheme(themeConfig)

const CustomerRedirect = () => <Redirect to={Routes.customer.INDEX} />

const onMount = () => {
  if (import.meta.env.PRO) {
    initSentry(config.sentry) // error monitoring
    console.debug('Sentry: loaded')
  } else {
    console.debug('Sentry: disabled')
  }
}

const App = observer(() => {
  useEffect(onMount, [])
  return (
    <BrowserRouter>
      <MuiThemeProvider theme={theme}>
        <AmplifyAuthProvider>
          <RouterAuthWrapper config={config.OAuthConfig}>
            <SWRConfig
              value={{
                // refreshInterval: 3000,
                fetcher,
              }}
            >
              <CssBaseline />
              <GlobalStyles />
              <NavContainer />
              <AuthContainer />
              <Layout>
                <Switch>
                  <Route
                    path={Routes.dashboard.INDEX}
                    component={DashboardScreen}
                  />
                  <Route
                    path={Routes.customer.INDEX}
                    component={CustomerScreen}
                  />
                  <Route
                    path={Routes.components.INDEX}
                    component={ComponentScreen}
                  />
                  <Route
                    path={Routes.provision.INDEX}
                    component={ProvisionScreen}
                  />
                  <Route exact path="/*" component={CustomerRedirect} />
                </Switch>
              </Layout>
            </SWRConfig>
          </RouterAuthWrapper>
        </AmplifyAuthProvider>
      </MuiThemeProvider>
    </BrowserRouter>
  )
})

const AppWithTools = observer(() => (
  <Fragment>
    <App />
  </Fragment>
))

export default AppWithTools
