import React from 'react'
import {
  createStyles,
  withStyles,
  Theme,
  WithStyles,
} from '@material-ui/core/styles'
import PageTitle from 'common/components/PageTitle'
import PageContent from 'common/components/PageContent'
import PageSubtitle from 'common/components/PageSubtitle'
import ComponentContainer from 'component/containers/ComponentContainer'
import RootStore from 'common/stores/RootStore'
import ReleaseSidebar from 'release/components/ReleaseSidebar'
import { Observer } from 'mobx-react-lite'
import { Status } from 'common/api'
import { Drawer } from '@material-ui/core'

import ShoppingBagIcon from 'common/icons/ShoppingBagIcon'
import Can, { Role } from 'common/components/Can'

const styles = ({ spacing }: Theme) =>
  createStyles({
    actions: {
      height: '47px',
      width: '44.5px',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    sidebar: {
      zIndex: 0,
      position: 'absolute',
      width: '400px',
    },
  })

type Props = WithStyles<typeof styles> & {}

const ComponentScreen: React.FC<Props> = ({ classes }) => {
  const { release } = React.useContext(RootStore)
  const [sidebarOpen, setSidebarOpen] = React.useState<boolean>(false)

  const toggleSidebar = (open: boolean) => (event: any) => {
    setSidebarOpen(open)

    if (release.status === Status.Success) {
      release.reset()
    }
  }

  const renderActions = () => {
    return (
      <Can
        requiredRole={Role.deployer}
        yes={
          <Observer>
            {() => (
              <div onClick={toggleSidebar(true)}>
                <ShoppingBagIcon
                  className={classes.actions}
                  text={`${release.releaseItems.length}`}
                />
              </div>
            )}
          </Observer>
        }
      />
    )
  }

  return (
    <PageContent>
      <PageTitle title="Components" />
      <PageSubtitle title="Deployed Components" actions={renderActions} />

      <ComponentContainer />

      <Drawer
        anchor="right"
        open={sidebarOpen}
        onClose={toggleSidebar(false)}
        children={<ReleaseSidebar />}
        className={classes.sidebar}
      />
    </PageContent>
  )
}

export default withStyles(styles)(ComponentScreen)
