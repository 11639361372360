import React, { Fragment } from 'react'
import { observer } from 'mobx-react-lite'
import { RouteComponentProps } from 'react-router'
import {
  styled,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'

import RootStore from 'common/stores/RootStore'
import NoResults from 'common/components/NoResults'
import Loading from 'common/components/Loading'
import { useOrganisationalUnits } from 'common/api'
import { Organisation } from 'organisation/stores/types'
import { DateText } from 'common/components/DateText'
import { Status } from 'common/components/Status'

const headers = [
  { title: 'Id' },
  { title: 'Name' },
  { title: 'Parent' },
  { title: 'Type' },
  { title: 'Status' },
]

const Table = styled(MuiTable)({
  '& td': {
    verticalAlign: 'top',
  },
})

export const OrganisationalUnitsListContainer: React.FC<
  RouteComponentProps<{ id: string }>
> = observer(({ history, match }) => {
  const { organisation: organisationStore } = React.useContext(RootStore)

  const organisationId = (organisationStore.current as Organisation)?.id || ''

  const {
    organisational_units = [],
    loading,
    error,
  } = useOrganisationalUnits(organisationId || '')

  console.log({ organisational_units, loading, error })

  if (!organisationStore.current) {
    return <NoResults title="No organisation selected" />
  }

  if (organisationStore.current.status === 'SUSPENDED') {
    return <NoResults title="Organisation is suspended" />
  }

  if (organisational_units.length === 0) {
    return (
      <React.Fragment>
        <NoResults title="No organisational units found" />
      </React.Fragment>
    )
  }

  if (organisationStore.isLoading || loading) return <Loading />

  return (
    <Fragment>
      <Table>
        <TableHead>
          <TableRow>
            {headers.map((header, index) => (
              <TableCell key={header.title}>{header.title}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {organisational_units.map((organisational_unit, index) => (
            <TableRow key={organisational_unit.Id}>
              <TableCell>{organisational_unit.Id}</TableCell>
              <TableCell>{organisational_unit.Name}</TableCell>
              <TableCell>
                {organisational_unit.ParentOrganisationalUnitId}
              </TableCell>
              <TableCell>
                {organisational_unit.OrganisationalUnitType}
              </TableCell>
              <TableCell>
                <Status status={organisational_unit.Status} />
              </TableCell>
              <TableCell>
                <DateText dateString={organisational_unit.ModifiedTS} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Fragment>
  )
})
