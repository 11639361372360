const INDEX = '/customers'
const WITH_ORG_ID = '?orgId=:orgId'

export const customerRoutes = {
  INDEX,
  DETAIL: `${INDEX}/:id`,
  ORGDETAIL: `${INDEX}/:id/OrganisationId/:orgId`,
  SERVICES: `${INDEX}/:id/services`,
  SERVICES_CONFIGURE_SECURITY_HUB: `${INDEX}/:id/services/configure-security-hub`,
  SERVICES_CONFIGURE_REGIONS: `${INDEX}/:id/services/configure-regions`,
}

export default customerRoutes

export const customerDetailLink = (customerId: string, tab = '') =>
  customerRoutes.DETAIL.replace(':id', customerId).concat(`/${tab}`)

export const configureSecurityHubLink = (customerId: string, orgId: string) =>
  customerRoutes.SERVICES_CONFIGURE_SECURITY_HUB.replace(':id', customerId)
    .concat(WITH_ORG_ID)
    .replace(':orgId', orgId)

export const configureRegionsLink = (customerId: string, orgId: string) =>
  customerRoutes.SERVICES_CONFIGURE_REGIONS.replace(':id', customerId)
    .concat(WITH_ORG_ID)
    .replace(':orgId', orgId)
