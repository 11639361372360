// import React, { ReactChild } from 'react'
import {
  createStyles,
  withStyles,
  WithStyles,
  Theme,
} from '@material-ui/core/styles'

type Props = WithStyles<typeof styles> & {}

const styles = (theme: Theme) =>
  createStyles({
    '@global': {
      '@keyframes shine': {
        '0%': {
          borderColor: 'rgba(92,112,128,.12)',
          background: 'rgba(92,112,128,.12)',
        },
        '100%': {
          borderColor: 'rgba(92,112,128,.2)',
          background: 'rgba(92,112,128,.2)',
        },
      },

      '@keyframes shineAcross': {
        '0%': {
          transform: 'translateX(-100%)',
        },
        '100%': {
          transform: 'translateX(100%)',
        },
      },
    },
  })

export default withStyles(styles)((props: Props) => null)
