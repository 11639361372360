import { ReactNode } from 'react'
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles'

import List from '@material-ui/core/List'

interface Props extends WithStyles<typeof styles> {
  children: ReactNode
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flex: '1 1 auto',
      alignItems: 'center',
      padding: 0,
    },
  })

const NavItemList = (props: Props) => {
  const { classes, children } = props
  return (
    <List className={classes.root} component="nav">
      {children}
    </List>
  )
}

export default withStyles(styles)(NavItemList)
