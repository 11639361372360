import React from 'react'
import {
  createStyles,
  withStyles,
  WithStyles,
  Theme,
} from '@material-ui/core/styles'
import className from 'classnames'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { teal, amber } from '@material-ui/core/colors'

import ComponentDiff from 'component/components/ComponentDiff'
import ComponentTime from 'component/components/ComponentTime'
import ComponentVersion from 'component/components/ComponentVersion'
import { Deployment } from 'component/stores/types'
import RootStore from 'common/stores/RootStore'
import { Status } from 'component/stores/types'

import classnames from 'classnames'

import ShoppingBagIcon from 'common/icons/ShoppingBagIcon'
import Can, { Role } from 'common/components/Can'
import { SolidButton } from 'common/components/SolidButton'

const IDLE_TIMEOUT = 5000

type Props = WithStyles<typeof styles> & {
  deployment: Deployment
  isCurrent: boolean
  isNew: boolean
  locked: boolean
  activeVersion?: string | undefined
}

const styles = ({ spacing, palette }: Theme) =>
  createStyles({
    root: {
      alignItems: 'center',
      width: '100%',
    },
    innerWrapper: {
      display: 'flex',
      flexDirection: 'column',
      paddingRight: spacing(1),
    },
    center: {
      textAlign: 'center',
    },
    current: {
      backgroundColor: palette.grey[100],
      padding: spacing(2),
    },
    new: {
      color: teal.A400,
      fontWeight: 600,
    },
    time: {
      color: 'black',
      paddingRight: spacing(1),
    },
    title: {
      display: 'flex',
      alignItems: 'center',
    },
    changelog: {
      color: palette.grey[400],
    },
    buttonContainer: {
      position: 'relative',
      height: spacing(5),
    },
    cartButton: {
      position: 'absolute',
      marginLeft: spacing(1),
      height: '100%',
    },
    shoppingBagIcon: {
      height: '23px',
      width: '20.5px',
    },
    statusBadge: {
      display: 'flex',
      alignItems: 'center',
      padding: spacing(1),
      fontSize: '0.75rem',
      height: spacing(5),
      borderRadius: '4px',
      background: amber[500],
      color: 'white',
      fontWeight: 'bold',
      width: '50%',
    },
  })

const ComponentBuild = ({
  activeVersion,
  classes,
  deployment,
  isCurrent,
  isNew,
  locked,
  ...restProps
}: Props) => {
  const { component, release } = React.useContext(RootStore)
  const [status, setStatus] = React.useState<Status>('IDLE')

  const handleDeploy = () => {
    if (deployment) {
      setStatus('PENDING')
      component
        .deploy(deployment.id)
        .then(() => setStatus('SUCCESS'))
        .catch(() => setStatus('ERROR'))
        .finally(() => setTimeout(() => setStatus('IDLE'), IDLE_TIMEOUT))
    }
  }

  const addToRelease = () => {
    release.addReleaseItem(deployment)
  }

  return (
    <Grid
      container
      justifyContent="center"
      className={className(classes.root, {
        [classes.current]: isCurrent,
      })}
      {...restProps}
    >
      <Grid item xs={6} className={classes.innerWrapper}>
        <div className={classes.title}>
          <ComponentTime
            className={classes.time}
            time={deployment.modifiedTS}
            isNew={isNew}
          />
          <Typography>by {deployment.commitAuthor || 'Unknown'}</Typography>
        </div>
        <ComponentVersion type="GitHash" version={deployment.version} />
        {isNew && (
          <ComponentDiff
            activeVersion={activeVersion || undefined}
            targetVersion={deployment.version}
            repoName={deployment.pipelineSlug}
          />
        )}
        <Typography className={classes.changelog}>
          {deployment.changeLog}
        </Typography>
      </Grid>
      <Grid className={classes.center} item xs={2}>
        {isCurrent && <Typography variant="caption">Current</Typography>}
        {isNew && (
          <Typography className={classes.new} variant="caption">
            New
          </Typography>
        )}
      </Grid>
      {!locked && (
        <Grid
          className={classnames(classes.center, classes.buttonContainer)}
          item
          xs={2}
        >
          <Can
            requiredRole={Role.deployer}
            yes={
              <SolidButton
                onClick={handleDeploy}
                disabled={status === 'PENDING'}
              >
                {(function () {
                  if (status === 'PENDING') return 'Loading...'
                  if (status === 'ERROR') return 'Error :('
                  if (status === 'SUCCESS') return 'Deployed :)'
                  return isCurrent ? 'Redeploy' : 'Deploy'
                })()}
              </SolidButton>
            }
            no={
              <SolidButton disabled>
                {isCurrent ? 'Redeploy' : 'Deploy'}
              </SolidButton>
            }
          />
        </Grid>
      )}
      {!locked && (
        <Grid
          className={classnames(classes.center, classes.buttonContainer)}
          item
          xs={2}
        >
          <Can
            requiredRole={Role.deployer}
            yes={
              <SolidButton
                // biome-ignore lint/style/noCommaOperator: what is this meant to be doing?
                className={(classes.cartButton, classes.center)}
                onClick={addToRelease}
              >
                <ShoppingBagIcon
                  className={classes.shoppingBagIcon}
                  fill="white"
                />
              </SolidButton>
            }
          />
        </Grid>
      )}
    </Grid>
  )
}

export default withStyles(styles)(ComponentBuild)
