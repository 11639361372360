import dashboard from 'dashboard/routes'
import customer from 'customer/routes'
import components from 'component/routes'
import provision from 'provision/routes'

const routes = {
  dashboard,
  customer,
  components,
  provision,
}

export default routes
