import React, { Fragment } from 'react'
import { observer } from 'mobx-react-lite'
import {
  createStyles,
  withStyles,
  Theme,
  WithStyles,
} from '@material-ui/core/styles'
import {
  match as RouterMatch,
  withRouter,
  RouteComponentProps,
  Route,
} from 'react-router'
import Grid from '@material-ui/core/Grid'
import { Tabs, Tab, Divider } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import PageTitle from 'common/components/PageTitle'
import OrganisationListAndDetailsContainer from 'organisation/containers/OrganisationListAndDetails'
import AccountsContainer from 'account//containers/AccountsContainer'
import WorkloadsContainer from 'workload/containers/WorkloadsContainer'
import CatalogueContainer from 'catalogue/containers/CatalogueContainer'
import UsersContainer from 'user/containers/UsersContainer'
import RootStore from 'common/stores/RootStore'
import { ServicesListContainer } from 'services/containers/ServicesListContainer'
import { PoliciesListContainer } from 'services/containers/PoliciesListContainer'
import { OrganisationalUnitsListContainer } from 'services/containers/OrganisationalUnitsListContainer'

const styles = ({ spacing }: Theme) =>
  createStyles({
    title: {
      paddingTop: '1rem',
      marginBottom: spacing(3),
      fontWeight: 600,
    },
  })

type Props = RouteComponentProps &
  WithStyles<typeof styles> & {
    match: RouterMatch<{ id: string; orgId?: string }>
  }

// TODO: make the Tab component layout below reusable
const tabs = [
  {
    label: 'Accounts',
    // subroute: ['accounts', ''],
    // Open this tab by default
    subroute: '',
    Component: AccountsContainer,
    exact: true,
  },
  {
    label: 'Workloads',
    // subroute: ['workloads'],
    subroute: '/workloads',
    Component: WorkloadsContainer,
    exact: true,
  },
  {
    label: 'Catalogue',
    // subroute: ['catalogue'],
    subroute: '/catalogue',
    Component: CatalogueContainer,
    exact: true,
  },
  {
    label: 'Users',
    // subroute: ['users'],
    subroute: '/users',
    Component: UsersContainer,
    exact: true,
  },
  {
    label: 'Services',
    // subroute: ['users'],
    subroute: '/services',
    Component: ServicesListContainer,
    exact: false, // allow for disable modal
  },
  {
    label: 'Policies',
    // subroute: ['policies'],
    subroute: '/policies',
    Component: PoliciesListContainer,
    exact: false, // allow for disable modal
  },
  {
    label: 'Organisational Units',
    // subroute: ['organisational-units'],
    subroute: '/organisational-units',
    Component: OrganisationalUnitsListContainer,
    exact: false, // allow for disable modal
  },
]

const CustomerDetailScreen = observer(
  ({ classes, history, match, ...restProps }: Props) => {
    const { customer, billingroot, organisation } = React.useContext(RootStore)

    const [selectedTab, setSelectedTab] = React.useState(0)

    React.useEffect(() => {
      customer.setActive(match.params['id'])
      billingroot.fetch('UNUSED')
    }, [])

    const changeTab = (_: any, value: number) => {
      setSelectedTab(value)
      history.push(`${match.url}${tabs[value].subroute}`)
    }

    return (
      <Fragment>
        <PageTitle title={customer.active && customer.active.name} />

        <Grid container>
          <OrganisationListAndDetailsContainer
            customerId={match.params.id}
            organisationId={match.params.orgId}
          />

          {!organisation.current?.id && 'Loading...'}

          {!!organisation.current?.id && (
            <Grid item xs={12}>
              <Paper>
                <Tabs
                  value={selectedTab}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={changeTab}
                >
                  {tabs.map((tab, index) => (
                    <Tab label={tab.label} value={index} key={index} />
                  ))}
                </Tabs>
                <Divider />
                {tabs.map(({ subroute, Component, exact = true }, index) => (
                  <Route
                    exact={exact}
                    path={`${match.path}${subroute}`}
                    component={Component}
                    key={index}
                  />
                ))}
              </Paper>
            </Grid>
          )}
        </Grid>
      </Fragment>
    )
  },
)

export default withRouter(withStyles(styles)(CustomerDetailScreen))
