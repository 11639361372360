import React from 'react'
import AsyncRequest from 'common/components/AsyncRequest'
import { Button } from '@material-ui/core'

type Props = React.ComponentProps<typeof Button>

const FactoryButton = ({
  onClick,
  children,
  disabled = false,
  ...rest
}: Props) => {
  const [request, setRequest] = React.useState<Promise<any> | undefined>(
    undefined,
  )

  const handleOnClick: typeof onClick = (event) => {
    const promise = onClick && onClick(event)
    promise && setRequest(promise)
  }

  return (
    <AsyncRequest request={request}>
      {({ status }) => (
        <Button
          style={{
            opacity: disabled ? 0.4 : 1,
          }}
          onClick={handleOnClick}
          disabled={disabled}
          {...rest}
        >
          {status !== 'PENDING' && status !== 'FAILED' && children}
          {status === 'PENDING' && 'Loading...'}
          {status !== 'PENDING' && status === 'FAILED' && 'Error'}
        </Button>
      )}
    </AsyncRequest>
  )
}

export default FactoryButton
