import { SentryConfig, SegmentConfig } from './types'
import routes from 'auth/routes'
import { AwsCognitoOAuthOpts } from '@aws-amplify/auth/lib/types'
if (!window.AWS_CONFIG.Segment || !window.AWS_CONFIG.Sentry) {
  console.warn('AWS_CONFIG missing Segment and/or Sentry: ', window.AWS_CONFIG)
}

const sentry: SentryConfig = {
  ...window.AWS_CONFIG.Sentry!,
  environment: window.AWS_CONFIG.Juma.stage || '',
  version: import.meta.env.REACT_APP_STAX_VERSION || '',
  release: `${window.AWS_CONFIG.Sentry!.projectName}@${
    import.meta.env.REACT_APP_STAX_VERSION
  }`,
}

export const segment: SegmentConfig = {
  key: (window.AWS_CONFIG.Segment && window.AWS_CONFIG.Segment.key) || '',
}

const scope: string[] = [import.meta.env.REACT_APP_SCOPE].filter(
  (v): v is string => !!v,
)

const oauth: AwsCognitoOAuthOpts = {
  domain: `admin-jumaauth-${window.AWS_CONFIG.Juma.stage}-master.auth.${window.AWS_CONFIG.JumaAuth.region}.amazoncognito.com`,

  // Authorized scopes
  scope,

  // Callback URL, this is loaded AFTER sign in to AWS / Ping and is responsible for signalling to
  // amplify that the auth code needs to be read from the URL and then waits for async login event
  // using HUB.
  redirectSignIn: `${window.location.origin}${routes.PROCESS_SIGN_IN}`,

  // Callback URL, this is loaded AFTER a user has signed out and currently just a place to clean up
  // state and send the user back to the home/login page.
  redirectSignOut: `${window.location.origin}${routes.SIGN_OUT}`,

  // 'code' for Authorization code grant,
  // 'token' for Implicit grant
  responseType: 'code',

  // optional, for Cognito hosted ui specified options
  options: {
    // Indicates if the data collection is enabled to support Cognito advanced security features. By
    // default, this flag is set to true.
    AdvancedSecurityDataCollectionFlag: true,
  },
}

export const OAuthConfig = {
  ...window.AWS_CONFIG.JumaAuth,
  oauth,
}

export default {
  sentry,
  segment,
  OAuthConfig,
}
