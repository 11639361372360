import { action, observable, makeObservable } from 'mobx'

import { RootStore } from 'common/stores/RootStore'
import { Status } from 'common/api'
import ComponentApi from 'component/stores/ComponentStore/ComponentApi'

import { ReleaseItem } from 'release/stores/types'

import { Deployment } from 'component/stores/types'

export default class ComponentStore {
  root: RootStore

  api: ComponentApi
  endpoint: string
  error: Error | undefined

  constructor(root: RootStore, api: ComponentApi, endpoint = '/component') {
    makeObservable<ComponentStore, 'sortReleaseItems'>(this, {
      status: observable,
      releaseItems: observable,
      numReleaseSuccesses: observable,
      numReleaseFails: observable,
      addReleaseItem: action,
      removeReleaseItem: action,
      sortReleaseItems: action,
      runRelease: action,
      reset: action,
    })

    this.root = root
    this.api = api
    this.endpoint = endpoint
  }

  status: Status = Status.Idle

  releaseItems = observable<ReleaseItem>([])

  numReleaseSuccesses = 0

  numReleaseFails = 0

  addReleaseItem = (input: Deployment) => {
    console.log(input)

    const newItem = this.deriveReleaseItem(input)

    let alreadyExists: any = undefined
    this.releaseItems.forEach((item: ReleaseItem, i: number) => {
      if (
        item.componentName.toLowerCase() === newItem.componentName.toLowerCase()
      ) {
        alreadyExists = i
      }
    })

    if (alreadyExists === undefined) {
      this.releaseItems.push(newItem)
    } else {
      this.releaseItems[alreadyExists] = newItem
    }

    this.sortReleaseItems()
  }

  removeReleaseItem = (componentName: string, version: string) => {
    for (let i = 0; i < this.releaseItems.length; i++) {
      if (
        this.releaseItems[i].componentName.toLowerCase() ===
          componentName.toLowerCase() &&
        this.releaseItems[i].version === version
      ) {
        this.releaseItems.remove(this.releaseItems[i])
        break
      }
    }
  }

  private sortReleaseItems = () => {
    this.releaseItems.replace(
      this.releaseItems.slice().sort((x: ReleaseItem, y: ReleaseItem) => {
        if (x.deploymentOrder < y.deploymentOrder) return -1
        if (x.deploymentOrder > y.deploymentOrder) return 1
        return 0
      }),
    )
  }

  private deriveReleaseItem(input: Deployment): ReleaseItem {
    const component = this.root.component.components.get(
      input.componentName.toLowerCase(),
    )

    if (!component) {
      throw new Error(`Cannot find component for deployment ${input}`)
    }

    return {
      deploymentId: input.id,
      componentName: input.componentName,
      status: input.status,
      version: input.version,
      commitAuthor: input.commitAuthor,
      createdTS: input.createdTS,
      modifiedTS: input.modifiedTS,
      changeLog: input.changeLog,
      deploymentOrder: component.deploymentOrder,
    }
  }

  runRelease = () => {
    this.status = Status.Pending

    return this.releaseItems
      .reduce(async (previousPromise, releaseItem: ReleaseItem) => {
        await previousPromise
        return this.deploy(releaseItem.deploymentId)
          .then((_) => {
            releaseItem.releaseSuccess = true
            this.numReleaseSuccesses++
          })
          .catch((_) => {
            this.numReleaseFails++
          })
      }, Promise.resolve())
      .then((_) => {
        if (this.numReleaseFails === this.releaseItems.length) {
          this.status = Status.Error
        }

        this.status = Status.Success
      })
  }

  private deploy = (id: string) => {
    return this.api.deploy(`${this.endpoint}/deployment`, id)
  }

  reset = () => {
    this.releaseItems.clear()
    this.numReleaseSuccesses = 0
    this.numReleaseFails = 0
    this.status = Status.Idle
  }
}
