import memoize from 'lodash/memoize'
import { get, post } from 'common/api'

export interface CustomerApi {
  fetch: (endpoint: string, force?: boolean, data?: any) => Promise<any>
  invoke: (endpoint: string, customerId: string) => Promise<any>
  delete: (endpoint: string, customerId: string) => Promise<any>
}

class CustomerService implements CustomerApi {
  cachedGet = memoize(get)

  fetch = (endpoint: string, force: boolean = false, data = {}) => {
    if (force) {
      const res = get(endpoint, data)
      this.cachedGet.cache.set(endpoint, res)
      return res
    }

    return this.cachedGet(endpoint, data)
  }

  invoke = (endpoint: string, customerId: string) => {
    return post(endpoint, { Id: customerId })
  }

  delete = (endpoint: string, customerId: string) => {
    return post(endpoint, { Id: customerId })
  }
}

export default CustomerService
