import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { put } from 'common/api'
import Loading from 'common/components/Loading'
import { useAsync } from 'common/hooks'
import { Notice } from 'component/components/Notice'
import { customerDetailLink } from 'customer/routes'
import { Fragment, useState } from 'react'
import { useHistory } from 'react-router'

export const ConfigureRegionsModal = ({
  customerId,
  organisationId,
  orgAlias = '',
}: {
  customerId: string
  organisationId: string
  orgAlias: string
}) => {
  const history = useHistory()
  const handleClose = () =>
    history.push(customerDetailLink(customerId, 'services'))

  const [orgAliasInput, setOrgAlias] = useState('')

  const orgAliasMatches = orgAlias !== '' && orgAliasInput === orgAlias

  const configureRegions = async () =>
    await put(
      `/organisations/${organisationId || ''}/configuration/regions/configure`,
    )
  const { execute, status, error, value } = useAsync(configureRegions, false)

  return (
    <Dialog
      open
      onClose={handleClose}
      aria-labelledby="configure-regions-title"
      aria-describedby="configure-regions-description"
      fullWidth
    >
      <DialogTitle id="configure-regions-title">
        Reconfigure Organisation Regions
      </DialogTitle>

      {status === 'pending' && (
        <DialogContent>
          <Loading />
        </DialogContent>
      )}

      {status === 'success' && (
        <DialogContent>
          <Tooltip title={JSON.stringify(value.data)}>
            <Typography>✅ Reconfiguring Organisation Regions...</Typography>
          </Tooltip>

          <Typography>
            Please close this dialog and check back later for updates
          </Typography>
        </DialogContent>
      )}

      {(status === 'idle' || status === 'error') && (
        <Fragment>
          <DialogContent>
            <Notice>
              <Typography>
                You are making changes to the organisation{' '}
                <Tooltip title={`Org ID: ${organisationId}`}>
                  <b>{orgAlias}</b>
                </Tooltip>
                's organisation regions
              </Typography>
            </Notice>

            {status === 'error' && <Notice intent="error">{error}</Notice>}

            <Typography variant="body2" style={{ margin: '1rem 0' }}>
              <b>
                Please type the Organisation's Alias to confirm this change:
              </b>
            </Typography>

            <TextField
              value={orgAliasInput}
              onChange={(event) => setOrgAlias(event.target.value)}
              placeholder="Organisation Alias"
              variant="outlined"
              size="small"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>

            <Button
              onClick={execute}
              variant="contained"
              style={orgAliasMatches ? dangerButtonStyles : undefined}
              disabled={!orgAliasMatches}
            >
              Reconfigure
            </Button>
          </DialogActions>
        </Fragment>
      )}
    </Dialog>
  )
}

const dangerButtonStyles = {
  color: 'white',
  borderColor: 'red',
  backgroundColor: 'red',
}
