import List from '@material-ui/core/List'
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles'
import { ReactNode } from 'react'

interface Props extends WithStyles<typeof styles> {
  children: ReactNode
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      overflowX: 'auto',
      overflowY: 'scroll',
      height: '100%',
      backgroundColor: theme.palette.grey[100],
      padding: 0,
    },
  })

const ComponentList = ({ classes, children, ...restProps }: Props) => (
  <List className={classes.root} component="ul" {...restProps}>
    {children}
  </List>
)

export default withStyles(styles)(ComponentList)
