import { format, parseISO } from 'date-fns'
import React from 'react'

import { ClickToCopy } from 'common/components/ClickToCopy'
import { Tooltip } from '@material-ui/core'

export enum DateTimeFormat {
  StandardDate = 'd MMMM y',
  // the blessed data format based on consultation with BenS
  StandardDateTime = 'E MMM dd yyyy hh:mm:ss a',
  StandardDateTimeWithOffset = 'E MMM dd yyyy hh:mm:ss a z',
}

type Props = {
  dateString: string
  dateTimeFormat?: DateTimeFormat
  showUtcTooltip?: boolean
  className?: string
}

export const DateText: React.FC<Props> = ({
  dateString,
  dateTimeFormat = DateTimeFormat.StandardDateTime,
  showUtcTooltip = true,
  className,
}) => {
  try {
    const date = parseISO(dateString)
    const formattedDateText = format(date, dateTimeFormat)

    if (showUtcTooltip)
      return (
        <UtcTooltipWrapper showUtcTooltip date={date}>
          <span className={className}>{formattedDateText}</span>
        </UtcTooltipWrapper>
      )

    return <span className={className}>{formattedDateText}</span>
  } catch (error) {
    console.error(error)

    return null
  }
}

export const UtcTooltipWrapper = ({
  date,
  children,
  showUtcTooltip,
}: {
  showUtcTooltip?: boolean
  date: Date
  children: React.ComponentProps<typeof Tooltip>['children']
  className?: string
}) => {
  if (!showUtcTooltip) return <React.Fragment>{children}</React.Fragment>

  const isoDate = date.toISOString()

  return (
    <Tooltip
      title={
        <span className="tx-body">
          {isoDate}
          <ClickToCopy
            text={isoDate}
            style={{ display: 'inline-block', marginLeft: '0.25rem' }}
          />
        </span>
      }
    >
      {children}
    </Tooltip>
  )
}

export const dateTextWithTzAndOffset = (date: Date) => {
  const localAbsoluteDate = format(
    date,
    DateTimeFormat.StandardDateTimeWithOffset,
  )
  const tz = timezoneAbbreviation(date)
  const dateWithTzAndOffset = `${localAbsoluteDate} (${tz})`
  return dateWithTzAndOffset
}

export const timezoneAbbreviation = (date: Date = new Date()) =>
  Intl?.DateTimeFormat('default', {
    timeZoneName: 'short',
  })
    .formatToParts?.(date)
    .find((part) => part.type === 'timeZoneName')?.value || ''
