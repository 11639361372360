import React from 'react'
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles'
import RootStore from 'common/stores/RootStore'

interface Props extends WithStyles<typeof styles> {}

const styles = ({ breakpoints }: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      background: 'white',
      color: 'black',
      [breakpoints.up('lg')]: {
        justifyContent: 'center',
      },
    },
  })

const NavGutter = ({ classes, ...restProps }: Props) => {
  const { navigation } = React.useContext(RootStore)

  const setRef = (node: HTMLDivElement) => {
    navigation.setPageSection(node)
  }

  return <div className={classes.root} ref={setRef} {...restProps} />
}

export default withStyles(styles)(NavGutter)
