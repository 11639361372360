import { MouseEventHandler } from 'react'
import { observer } from 'mobx-react-lite'
import { createStyles, withStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { NavLinkName } from 'navigation/NavLinks'
import {
  AddCircleOutline,
  HelpOutline,
  LocalHospitalOutlined,
  Home,
  MonetizationOnOutlined,
  SettingsInputComposite,
  SvgIconComponent,
} from '@material-ui/icons'

type LayoutProperty = 'row' | 'column'

interface TextIconProps {
  title: string
  name: NavLinkName
  layout: LayoutProperty
  classes: any
  compact: boolean
  onClick?: MouseEventHandler<any>
  component?: string
}

type TNameToIcon = Record<NavLinkName, SvgIconComponent>
const NameToIcon: TNameToIcon = {
  customers: Home,
  'aws-support': HelpOutline,
  'aws-health-events': LocalHospitalOutlined,
  components: SettingsInputComposite,
  provision: AddCircleOutline,
  billing: MonetizationOnOutlined,
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      background: 'transparent',
      border: 'none',
      cursor: 'pointer',
    },
    icon: {
      color: 'inherit',
      marginBottom: '0.25rem',
      width: '18px',
      maxHeight: '18px',
    },
    text: {
      fontSize: '12px',
      color: 'inherit',
    },
    left: {
      paddingLeft: spacing(1),
    },
    top: {
      paddingTop: spacing(1),
    },
  })

const TextIcon = observer((props: TextIconProps) => {
  const { classes, compact, name } = props
  const Component = props.onClick ? 'button' : 'div'
  const Icon = NameToIcon[name]
  return (
    <Component className={classes.root} onClick={props.onClick}>
      {!compact && <Icon className={classes.icon} />}
      <Typography className={classes.text}>{props.title}</Typography>
    </Component>
  )
})

export default withStyles(styles)(TextIcon)
