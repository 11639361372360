import React from 'react'
import { observer } from 'mobx-react-lite'
import { withRouter, RouteComponentProps } from 'react-router'
import {
  createStyles,
  withStyles,
  Theme,
  WithStyles,
} from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Select from '@material-ui/core/Select'

import NavItem from 'navigation/components/NavItem'
import NavGutter from 'navigation/components/NavGutter'
import NavLogo from 'navigation/components/NavLogo'
import NavItemList from 'navigation/components/NavItemList'
import NavAccountMenu from 'navigation/components/NavAccountMenu'

import { NavLinks } from '../NavLinks'
import routes from 'auth/routes'
import { AmplifyAuthConsumer } from 'common/AmplifyListener/AmplifyListener'
import {
  HocConsumerProps,
  User,
} from 'common/AmplifyListener/AmplifyListener.d'
import { stripFirstLetters } from 'common/utility/StringUtil'
import { FormControl, OutlinedInput } from '@material-ui/core'

import { STAGES, currentStageId } from '../constants'

const styles = ({ breakpoints, palette }: Theme) =>
  createStyles({
    root: {
      position: 'sticky',
      top: 0,
      left: 0,
      right: 0,
      flexDirection: 'column',
      background: '#043143',
    },
    navBar: {
      display: 'flex',
      [breakpoints.up('lg')]: {
        justifyContent: 'center',
      },
    },
    navBarInner: {
      display: 'flex',
      width: '100%',
      [breakpoints.up('lg')]: {
        width: '1280px',
      },
    },
    stage: {
      fontSize: '1rem',
      fontWeight: 'bold',
      color: palette.common.white,
      '& svg': {
        color: palette.common.white,
      },
      '& option': {
        color: palette.text.primary,
      },
      '&::before': { borderBottom: 'none!important' },
      '&::after': { borderBottom: 'none!important' },
    },
  })

type Props = RouteComponentProps & WithStyles<typeof styles> & {}

const NavContainer = observer(({ classes, ...restProps }: Props) => {
  const [compact, setCompact] = React.useState(false)
  const logout = () => {
    console.debug('push signout')
    restProps.history.push(routes.SIGN_OUT)
  }

  const currentStage = currentStageId()

  const versentEmailEnding = '@versent.com.au'
  const oktaEmailEnding = '@stax.io'
  const initials = (user: User) => {
    if (user.name) {
      return stripFirstLetters(
        user.name.replace(versentEmailEnding, '').replace(oktaEmailEnding, ''),
      )
    }
    return ''
  }
  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [])
  const handleScroll = () => {
    const scrollTop = window.pageYOffset || 0
    if (scrollTop > 0 && compact === false) {
      setCompact(true)
    } else if (scrollTop === 0 && compact === true) {
      setCompact(false)
    }
  }

  console.log({ currentStage, STAGES })

  return (
    <AppBar classes={{ root: classes.root }} elevation={1} position="static">
      <div className={classes.navBar}>
        <div className={classes.navBarInner} style={{ alignItems: 'center' }}>
          <NavLogo compact={compact} />
          <NavItemList>
            {NavLinks.map((navLink, index) => (
              <NavItem {...navLink} compact={compact} key={index} />
            ))}
          </NavItemList>

          <FormControl variant="outlined">
            <Select
              // native
              value={currentStage}
              variant="outlined"
              className={classes.stage}
              onChange={(event) => {
                const stage = STAGES.find(
                  (stage) => stage.stage === event.target.value,
                )

                if (!stage) return console.error('oh no') // should never happen

                if (stage.stage === currentStage) return // no worries, stay put

                window.location.assign(stage.link)
              }}
              input={<OutlinedInput labelWidth={0} />}
              inputProps={{
                name: 'stage',
                id: 'stage',
              }}
            >
              {STAGES.map(({ stage }) => (
                <option key={stage} value={stage}>
                  {stage}
                </option>
              ))}
            </Select>
          </FormControl>

          <AmplifyAuthConsumer>
            {(state: HocConsumerProps) => (
              <NavAccountMenu
                name={
                  state.authState.user ? state.authState.user.name : undefined
                }
                region={window.AWS_CONFIG.JumaAuth.region}
                userId={
                  state.authState.user ? state.authState.user.id : undefined
                }
                roles={
                  state.authState.user
                    ? state.authState.user.idamRoles
                    : undefined
                }
                initials={
                  state.authState.user
                    ? initials(state.authState.user)
                    : undefined
                }
                logout={logout}
              />
            )}
          </AmplifyAuthConsumer>
        </div>
      </div>
      <NavGutter />
    </AppBar>
  )
})

export default withStyles(styles)(withRouter(NavContainer))
