import React from 'react'
import { observer } from 'mobx-react-lite'
import { add } from 'date-fns/add'
import { isAfter } from 'date-fns/isAfter'
import { parseISO } from 'date-fns/parseISO'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'

import { AuthContext } from 'common/AmplifyListener/AmplifyListener'
import OrganisationStore from 'organisation/stores/OrganisationStore'
import { Account } from 'account/stores/types'
import {
  LoginToAwsButton,
  RequestLoginToAwsButton,
} from 'common/components/ConfirmLinkButton'
import { currentStageId } from 'navigation/constants'
import { IsAny, Role } from 'common/components/Can'

interface State {
  duration: number
  isOpen: boolean
  reason: string
}

type Props = {
  account: Account
  organisation: OrganisationStore
}

const ADMIN_ACCESS_EXEMPT_ALIASES = ['juma', 'smoko']
const DURATIONS = [30, 60, 90, 120]

const baseState = {
  duration: DURATIONS[0],
  isOpen: false,
  reason: '',
}

const isBillingRoot = ({ billingAccountId }: { billingAccountId: string }) =>
  !billingAccountId

// If an account is either created by stax OR a billing root it is "Stax Managed"
const isStaxManaged = (account: Account): boolean => {
  return !!account.staxCreated || isBillingRoot(account)
}

const MaybeLogin = observer(({ account, organisation }: Props) => {
  const [state, setState] = React.useState<State>(baseState)
  const { authState } = React.useContext(AuthContext)

  if (!organisation.current || !authState) {
    return null
  }

  const hasRequestedAccess = organisation.accessRequestAccounts.includes(
    account.id,
  )

  const handleClose = () => setState(baseState)
  const handleChangeDuration: React.ComponentProps<typeof Select>['onChange'] =
    (event) =>
      setState({ ...state, duration: event.currentTarget.value as number })
  const handleChangeReason = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, reason: event.currentTarget.value })
  }
  const handleStartRequest = () => setState({ ...state, isOpen: true })
  const handleSubmit = () => {
    const { duration, reason } = state
    // validate
    if (!duration || !reason) return

    organisation.submitAccessRequest(organisation.current!.id, {
      accessDurationInMins: state.duration,
      reason: state.reason,
      requestor: authState.user?.id || '',
      staxAccountId: account.id,
    })
    setState(baseState)
  }

  const adminAccessExempt =
    currentStageId().includes('dev') ||
    (organisation.current?.alias &&
      ADMIN_ACCESS_EXEMPT_ALIASES.includes(organisation.current.alias)) ||
    account.accountRole === 'MANAGEMENT_RESELL'
  const orgConfig = organisation.listConfig(organisation.current.id)
  const accountAdminAccessRolesTTLInHours: number =
    orgConfig.find(
      (config) => config.key === `AdminAccessRolesTTLInHours/${account.id}`,
    )?.value || 0
  const accountAdminAccessRolesExpiry = add(parseISO(account.createdTS), {
    hours: accountAdminAccessRolesTTLInHours,
  })

  const orgAdminAccessRolesTTLInHours: number =
    orgConfig.find((config) => config.key === 'AdminAccessRolesTTLInHours')
      ?.value || 0
  const orgAdminAccessRolesExpiry = add(
    parseISO(organisation.current.createdTS),
    {
      hours: orgAdminAccessRolesTTLInHours,
    },
  )

  const accountAdminAccessExpired: boolean = isAfter(
    new Date(),
    accountAdminAccessRolesExpiry,
  )
  const orgAdminAccessExpired: boolean = isAfter(
    new Date(),
    orgAdminAccessRolesExpiry,
  )
  const adminAccessExpired: boolean =
    accountAdminAccessExpired && orgAdminAccessExpired

  console.debug(
    account.name,
    { adminAccessExpired, adminAccessExempt },
    {
      account: {
        accountAdminAccessExpired,
        accountAdminAccessRolesTTLInHours,
        accountAdminAccessRolesExpiry,
      },
      org: {
        orgAdminAccessExpired,
        orgAdminAccessRolesTTLInHours,
        orgAdminAccessRolesExpiry,
      },
    },
  )

  const awsLoginURLRegex =
    /(.+)(\/auth\/realms\/master\/protocol\/saml\/clients\/)(\d+)/
  const supportAwsLoginURL = isStaxManaged(account)
    ? account.awsLoginURL.replace(awsLoginURLRegex, '$1$2support-$3')
    : account.awsLoginURL

  const loginText = hasRequestedAccess ? 'Requested' : 'Login'
  const loginTip = hasRequestedAccess
    ? 'Open in AWS Console (access may not be provisioned for up to 5 minutes)'
    : 'Open in AWS Console'
  const LoginButton = ({ targetURL }: { targetURL: string }) => (
    <LoginToAwsButton
      customerType={organisation.current?.organisationType}
      isBillingRoot={isBillingRoot(account)}
      memberAccountName={account.name}
      organisationAlias={organisation.current?.alias}
      organisationName={organisation.current?.name}
      targetURL={targetURL}
      text={loginText}
      tooltip={loginTip}
    />
  )

  return (
    <>
      {adminAccessExpired && !adminAccessExempt && !hasRequestedAccess ? (
        <RequestLoginToAwsButton
          customerType={organisation.current?.organisationType}
          isBillingRoot={isBillingRoot(account)}
          memberAccountName={account.name}
          organisationAlias={organisation.current?.alias}
          tooltip={
            accountAdminAccessRolesTTLInHours && accountAdminAccessExpired
              ? `Admin access expired (${account.createdTS} + ${accountAdminAccessRolesTTLInHours} hours)`
              : `Admin access expired (${organisation?.current?.createdTS} + ${orgAdminAccessRolesTTLInHours} hours)`
          }
          onClick={handleStartRequest}
        />
      ) : (
        <IsAny
          requiredRoles={[Role.customersupport]}
          yes={<LoginButton targetURL={supportAwsLoginURL} />}
          no={<LoginButton targetURL={account.awsLoginURL} />}
        />
      )}
      <Dialog
        open={state.isOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Request Account Access</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This account is owned by the customer, not Stax.
            <br />
            <br />
            You should not log into this account without permission from the
            customer.
            <br />
            <br />
            Logging into this account will trigger an INCIDENT.
            <br />
            <br />
            Are you sure you want to log into this customer account?
            <br />
            <br />
            If you are sure, select the desired access duration and provide a
            link to the support ticket below.
            <br />
            <br />
            Please wait up to 5 minutes for access to be provisioned (login
            button may be displayed before provisioning is complete)
            <br />
            <br />
          </DialogContentText>
          <TextField
            disabled
            fullWidth
            label="AWS Account"
            margin="dense"
            type="text"
            value={`${account.name} - ${account.awsAccountId}`}
          />
          <TextField
            disabled
            fullWidth
            label="Requester"
            margin="dense"
            type="text"
            value={`${authState.user?.name} - ${authState.user?.id}`}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel id="duration-label">Duration in minutes</InputLabel>
            <Select
              fullWidth
              inputProps={{
                style: {
                  paddingTop: '0.7rem',
                  paddingBottom: '0.7rem',
                },
              }}
              label="Duration in minutes"
              labelId="duration-label"
              margin="dense"
              native
              onChange={handleChangeDuration}
              value={state.duration}
              variant="outlined"
            >
              {DURATIONS.map((d) => (
                <option value={d} key={d}>
                  {d}
                </option>
              ))}
            </Select>
          </FormControl>
          <TextField
            autoFocus
            required
            margin="dense"
            value={state.reason}
            onChange={handleChangeReason}
            id="reason"
            label="Support ticket ID"
            type="text"
            fullWidth
            placeholder="ID of support ticket or Jira card"
          />
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              background: 'transparent',
              color: 'grey',
              border: '1px solid grey',
            }}
            onClick={handleClose}
            color="primary"
          >
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Request
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
})

export default MaybeLogin
