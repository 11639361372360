import memoize from 'lodash/memoize'
import { get } from 'common/api'

export interface CatalogueApi {
  fetch: (endpoint: string, force?: boolean, data?: any) => Promise<any>
}

class CatalogueService implements CatalogueApi {
  cachedGet = memoize(get)

  fetch = (endpoint: string, force: boolean = false, data = {}) => {
    if (force) {
      const res = get(endpoint, data)
      this.cachedGet.cache.set(endpoint, res)
      return res
    }

    return this.cachedGet(endpoint, data)
  }
}

export default CatalogueService
