import {
  createStyles,
  withStyles,
  WithStyles,
  Theme,
} from '@material-ui/core/styles'

import { styled, Tooltip, Typography } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell, { TableCellProps } from '@material-ui/core/TableCell'
import { Link, withRouter, RouteComponentProps } from 'react-router-dom'
import { Customer } from 'customer/stores/types'
import Loading from 'common/components/Loading'

import routes from '../../routes'
import { Status } from 'common/components/Status'
import { grey } from '@material-ui/core/colors'
import { StaxLogo } from 'component/components/StaxLogo'

type Props = RouteComponentProps &
  WithStyles<typeof styles> & {
    isLoading: boolean
    headers: string[]
    customers: Customer[]
  }

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: 'white',
      borderCollapse: 'collapse',
    },

    cell: {
      border: 'none',
      '& span > a': {
        color: theme.palette.grey[800],
        textDecoration: 'none',
      },
    },
    header: {
      border: 'none',
      color: theme.palette.grey[700],
      fontWeight: 600,
      fontSize: 13,
      textTransform: 'uppercase',
    },
    danger: {
      color: theme.palette.error.main,
      fontWeight: 500,
    },
  })

const Row = styled(TableRow)({
  padding: '0.25rem 1rem',
  cursor: 'pointer',

  '&:nth-of-type(odd)': {
    backgroundColor: grey[50],
  },

  '&:hover': {
    backgroundColor: grey[100],
  },
})

// const STAX_MANAGED_CUSTOMERS = ['Stax Control Plane']

// const isStaxManaged = ({ name }: { name: string }) =>
//   STAX_MANAGED_CUSTOMERS.includes(name)

const renderCustomers = (props: Props, customers: Customer[]) => {
  return customers.map((customer: Customer) => {
    const link = `${routes.INDEX}/${customer.id}`
    const onClick = () => props.history.push(link)

    // Make all cells clickable, except the "More actions" and "run factory"
    const ClickableCell = ({ children, ...rest }: TableCellProps) => (
      <TableCell
        className={props.classes.cell}
        onClick={onClick}
        title={`View customer details (${customer.name})`}
        {...rest}
      >
        {children}
      </TableCell>
    )

    return (
      <Row key={customer.id}>
        <ClickableCell>
          <Typography component="span">
            <Link to={link}>
              <strong>{customer.name}</strong>{' '}
              {customer.internal && (
                <Tooltip title="Stax-managed Internal Customer">
                  <span>
                    <StaxLogo
                      style={{ marginBottom: '-7px', marginLeft: '0.5rem' }}
                    />
                  </span>
                </Tooltip>
              )}
            </Link>
          </Typography>
        </ClickableCell>

        <ClickableCell>{customer.supportPlan}</ClickableCell>

        <ClickableCell>{customer.type}</ClickableCell>

        <ClickableCell>
          <Status status={customer.status} />
        </ClickableCell>
      </Row>
    )
  })
}

const CustomerTable = (props: Props) => {
  const { classes, headers, customers, isLoading } = props

  if (isLoading) return <Loading />

  return (
    <Table className={classes.root}>
      <TableHead>
        <TableRow>
          {headers.map((header) => (
            <TableCell
              key={header}
              classes={{ root: props.classes.header }}
              variant="head"
            >
              {header}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>{!isLoading && renderCustomers(props, customers)}</TableBody>
    </Table>
  )
}

export default withRouter(withStyles(styles)(CustomerTable))
