import React from 'react'
import {
  createStyles,
  withStyles,
  Theme,
  WithStyles,
} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Layout from 'common/components/Layout'
import HeaderContainer from 'navigation/containers/HeaderContainer'

const styles = ({ spacing, breakpoints }: Theme) =>
  createStyles({
    title: {
      textAlign: 'center',
      fontWeight: 'bold',
    },
    container: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: '1rem',
      padding: `${spacing(2)}px ${spacing(4)}px`,
    },
  })

interface Props extends WithStyles<typeof styles> {
  title?: string
}

const PageTitle: React.FC<Props> = ({ children, classes, title }) => (
  <HeaderContainer>
    <Layout>
      <div className={classes.container}>
        {title && (
          <Typography className={classes.title} component="h1" variant="h6">
            {title}
          </Typography>
        )}
        {children}
      </div>
    </Layout>
  </HeaderContainer>
)

export default withStyles(styles)(PageTitle)
