import Paper from '@material-ui/core/Paper'

import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles'
import isEmpty from 'lodash/isEmpty'
import times from 'lodash/times'
import { observer } from 'mobx-react-lite'
import React, { Fragment } from 'react'
import { Route, RouteComponentProps, withRouter } from 'react-router-dom'

import ComponentItemContent from 'component/components/ComponentItemContent'
import ComponentList from 'component/components/ComponentList'
import ComponentListItem from 'component/components/ComponentListItem'

import RootStore from 'common/stores/RootStore'
import { Component } from 'component/stores/types'
import NoResults from 'common/components/NoResults'

type ComponentCatalogueProps = RouteComponentProps &
  WithStyles<typeof styles> & {
    appliedQuery: string
    components: Component[]
    isLoading: boolean
    route: string
  }

const styles = ({ palette, spacing }: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      borderRadius: 0,
    },
    container: {
      display: 'flex',
      height: '100vh',
      overflow: 'hidden',
    },
    list: {
      width: '50%',
      maxWidth: '25rem',
      overflow: 'scroll',
    },
    content: {
      flex: 1,
      borderLeft: `1px solid ${palette.grey[200]}`,
      overflow: 'scroll',
    },
    textfield: {
      marginRight: spacing(1),
    },
    input: {
      padding: spacing(1),
    },
  })

interface State {
  selected: string
  failing: Component[]
  brief: boolean
  lastUpdated: string[]
  needUpdate: string[]
  loaded: boolean
}

const filterComponent = (appliedQuery: string) => (component: Component) =>
  component.name.toLocaleLowerCase().includes(appliedQuery.toLowerCase())

// FIXME: this component is SLOW. needs rewriting.

const ComponentCatalogue = observer(
  ({ route, ...props }: ComponentCatalogueProps) => {
    const [state, setState] = React.useState<State>({
      selected: '',
      failing: [],
      brief: true,
      lastUpdated: [],
      needUpdate: [],
      loaded: false,
    })

    const { component } = React.useContext(RootStore)

    React.useEffect(() => {
      if (component.status === 'success') {
        if (!state.brief) {
          const failingList: Component[] = []
          component.components.forEach((item, key) => {
            if (!item.deployPassing) failingList.push(item)
          })
          setState({
            ...state,
            failing: failingList.sort((item) => item.deploymentOrder),
          })
        } else {
          if (state.brief && state.lastUpdated.length === 0) {
            const briefList: string[] = []
            component.components.forEach((item) => {
              briefList.push(item.name)
            })
            setState({ ...state, needUpdate: briefList })
          } else {
            let list = [...state.needUpdate]
            list = list.filter((item) => !state.lastUpdated.includes(item))
            setState({ ...state, brief: list.length !== 0, needUpdate: list })
          }
        }
      }
    }, [component.status, state.lastUpdated])

    React.useEffect(() => {
      if (state.brief) {
        if (state.needUpdate.length > 0) {
          let list = [...state.needUpdate]
          const sliceLength = 4
          if (list.length > sliceLength) {
            list = list.slice(0, sliceLength)
          }
          if (state.needUpdate.indexOf(state.selected) > 0) {
            list.push(state.selected)
          }
          component.fetch(true, undefined, list)
          setState({ ...state, lastUpdated: list, loaded: true })
        }
      }
    }, [state.needUpdate])

    // set selection on mount if it's there
    React.useEffect(() => {
      const [, componentNameFromUrl] = props.location.pathname.split(
        `${route}/`,
      )
      if (state.selected === '' && !!componentNameFromUrl) {
        setState({ ...state, selected: componentNameFromUrl })
      }
    }, [])

    // this needs to be here otherwise you are unable to load directly into a component
    React.useEffect(() => {}, [component.list])

    React.useEffect(() => {
      const [, componentNameFromUrl] = props.location.pathname.split(
        `${route}/`,
      )

      if (
        state.selected !== '' &&
        !!componentNameFromUrl &&
        componentNameFromUrl !== state.selected
      ) {
        setState({ ...state, selected: componentNameFromUrl })
      }
    }, [props.location.pathname])

    const handleChange = (name: string) => {
      props.history.push(`${route}/${name}`)
      setState({ ...state, selected: name })
    }

    const ItemContent = observer(() => {
      const item = component.components.get(state.selected)
      return (
        <ComponentItemContent
          component={item}
          failing={state.failing}
          isLoading={item !== undefined && state.needUpdate.includes(item.name)}
        />
      )
    })

    const ListItems = observer(() => {
      const empty = isEmpty(props.components)
      if (empty && state.brief) {
        const elements = times(3, (i) => (
          <ComponentListItem key={i} isLoading active={false} />
        ))
        return <Fragment>{elements}</Fragment>
      }

      const filter = filterComponent(props.appliedQuery)

      const elements = !empty ? (
        props.components
          .filter(filter)
          .map((component: Component) => (
            <ComponentListItem
              key={component.name}
              data={component}
              isLoading={
                state.needUpdate.includes(component.name) ||
                (state.loaded && state.needUpdate.length > 0)
              }
              onClick={handleChange}
              active={state.selected === component.name}
            />
          ))
      ) : (
        <NoResults title="No Components with that name" />
      )

      return <Fragment>{elements}</Fragment>
    })

    return (
      <Paper className={props.classes.root} elevation={1}>
        <div className={props.classes.container}>
          <div className={props.classes.list}>
            <ComponentList>
              <ListItems />
            </ComponentList>
          </div>
          <div className={props.classes.content}>
            <Route path={[route, `${route}/:name`]} component={ItemContent} />
          </div>
        </div>
      </Paper>
    )
  },
)

export default withStyles(styles)(withRouter(ComponentCatalogue))
