import FormControl from '@material-ui/core/FormControl'
import InputBase, { InputBaseProps } from '@material-ui/core/InputBase'
import FormHelperText, {
  FormHelperTextProps,
} from '@material-ui/core/FormHelperText'
import InputLabel from '@material-ui/core/InputLabel'
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles'
import classnames from 'classnames'
import { ReactNode } from 'react'

type Props = WithStyles<typeof styles> &
  InputBaseProps & {
    id: string
    label: string
    helperText?: ReactNode
    FormHelperTextProps?: FormHelperTextProps
    monospace?: boolean
  }

const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginBottom: '2rem',
    },
    inputRoot: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.common.white,
      border: '1px solid #ced4da',
      fontSize: 16,
      width: 'auto',
      padding: '10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff', // TODO: use pallette
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
    inputFullWidth: {
      width: '100%',
    },
    inputMonospace: {
      fontFamily: 'monospace',
    },
    label: {
      fontSize: 18,
    },
  })

const TextInput = ({ classes, label, id, helperText, ...props }: Props) => {
  const inputClasses = classnames({
    [classes.input]: true,
    [classes.inputFullWidth]: props.fullWidth,
    [classes.inputMonospace]: props.monospace,
  })

  return (
    <FormControl className={classes.root} fullWidth={props.fullWidth}>
      <InputLabel shrink htmlFor={id} className={classes.label}>
        {label}
        {props.required && '*'}
      </InputLabel>

      <InputBase
        id={id}
        classes={{
          root: classes.inputRoot,
          input: inputClasses,
        }}
        {...props}
      />

      {helperText && (
        <FormHelperText {...props.FormHelperTextProps}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default withStyles(styles)(TextInput)
