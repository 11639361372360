import React from 'react'
import AddBillingRootContainer from '../containers/AddBillingRootContainer'
import { Grid } from '@material-ui/core'
import PageTitle from 'common/components/PageTitle'

const AddBillingRootScreen = () => (
  <React.Fragment>
    <PageTitle title="Provision" />
    <Grid container justifyContent="center">
      <Grid item md={4}>
        <h2>Add New Billing Root to Stax Installation</h2>
        <AddBillingRootContainer />
      </Grid>
    </Grid>
  </React.Fragment>
)

export default AddBillingRootScreen
