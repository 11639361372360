import { CircularProgress, Grid, Typography } from '@material-ui/core'

const Loading = () => (
  <Grid
    container
    direction="column"
    justifyContent="center"
    alignContent="center"
    alignItems="center"
    style={{ width: '100%', margin: '1rem' }}
  >
    <Grid item>
      <CircularProgress />
    </Grid>
    <Grid item>
      <Typography>Loading...</Typography>
    </Grid>
  </Grid>
)

export default Loading
