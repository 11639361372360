import { observer } from 'mobx-react-lite'
import {
  createStyles,
  withStyles,
  WithStyles,
  Theme,
} from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'

type VersionType = 'GitHash' | 'SemVer'

type Props = WithStyles<typeof styles> & {
  version: string
  type: VersionType
  truncation?: number
}

const styles = (theme: Theme) =>
  createStyles({
    root: {},
  })

function parseVersion(
  version: string,
  type: VersionType,
  truncation: number,
): string {
  if (type === 'GitHash') {
    return version.substr(0, truncation)
  }
  return version
}

const ComponentVersion = observer((props: Props) => {
  const { classes, version, type, truncation = 7 } = props

  const parsedVersion =
    version === '' ? 'unknown' : parseVersion(version, type, truncation)

  return (
    <Typography className={classes.root} variant="caption">
      Version {parsedVersion}
    </Typography>
  )
})

export default withStyles(styles)(ComponentVersion)
