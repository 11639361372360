import { createContext } from 'react'

import AuthStore from 'common/stores/AuthStore'
import CustomerStore from 'customer/stores/CustomerStore'
import ComponentStore from 'component/stores/ComponentStore'
import NavigationStore from 'navigation/stores/NavigationStore'
import OrganisationStore from 'organisation/stores/OrganisationStore'
import WorkloadStore from 'workload/stores/WorkloadStore'
import AccountStore from 'account/stores/AccountStore'
import CatalogueStore from 'catalogue/stores/CatalogueStore'
import ReleaseStore from 'release/stores/ReleaseStore'
import BillingRootStore from 'provision/stores/BillingRootStore'
import ProvisionStore from 'provision/stores/ProvisionStore'
import UserStore from 'user/stores/UserStore'

import AccountService from 'account/stores/AccountApi'
import CustomerService from 'customer/stores/CustomerApi'
import OrganisationService from 'organisation/stores/OrganisationApi'
import WorkloadService from 'workload/stores/WorkloadApi'
import ComponentService from 'component/stores/ComponentStore/ComponentApi'
import CatalogueService from 'catalogue/stores/CatalogueApi'
import ProvisionService from 'provision/stores/ProvisionApi'
import UserService from 'user/stores/UserApi'

export class RootStore {
  constructor() {
    this.account = new AccountStore(this, new AccountService())
    this.navigation = new NavigationStore()
    this.auth = new AuthStore(this)
    this.customer = new CustomerStore(this, new CustomerService())
    this.component = new ComponentStore(this, new ComponentService())
    this.catalogue = new CatalogueStore(this, new CatalogueService())
    this.workload = new WorkloadStore(this, new WorkloadService())
    this.organisation = new OrganisationStore(this, new OrganisationService())
    this.release = new ReleaseStore(this, new ComponentService())
    this.billingroot = new BillingRootStore(this, new AccountService())
    this.provision = new ProvisionStore(this, new ProvisionService())
    this.user = new UserStore(this, new UserService())
  }

  account: AccountStore
  billingroot: BillingRootStore
  navigation: NavigationStore
  auth: AuthStore
  catalogue: CatalogueStore
  customer: CustomerStore
  component: ComponentStore
  workload: WorkloadStore
  organisation: OrganisationStore
  release: ReleaseStore
  provision: ProvisionStore
  user: UserStore
}

export default createContext(new RootStore())
