import classNames from 'classnames'
import React from 'react'

type NoticeIntent = 'info' | 'success' | 'error'

type Props = {
  intent?: NoticeIntent
  title?: string
  className?: string
}

const intentStyles: { [NoticeIntent: string]: string } = {
  info: 'border-amber500 bg-amber050',
  error: 'border-pink500 bg-pink050',
  success: 'border-green500 bg-green050',
}

export const Notice: React.FC<Props> = ({
  intent = 'info',
  className = '',
  title,
  children,
}) => (
  <div
    className={classNames(
      'p-100 border rounded',
      intentStyles[intent],
      className,
    )}
  >
    <div className="flex flex-column items-start break-word">
      {title && <h2 className="tx-body semibold my-0">{title}</h2>}
      {children}
    </div>
  </div>
)
