import React from 'react'
import {
  createStyles,
  withStyles,
  WithStyles,
  Theme,
} from '@material-ui/core/styles'
import { ReleaseItem as ReleaseItemType } from 'release/stores/types'
import ReleaseItem from './ReleaseItem'

type Props = WithStyles<typeof styles> & {
  items: ReleaseItemType[]
  onRemoveItem: any
}

const styles = ({ spacing, palette }: Theme) =>
  createStyles({
    container: {
      backgroundColor: '#F2F2F2',
      height: '100%',
      width: '100%',
      paddingTop: spacing(3),
      paddingBottom: spacing(3),
      paddingLeft: spacing(4),
      paddingRight: spacing(4),
    },
  })

const ReleaseSidebarBody: React.FC<Props> = ({
  classes,
  items,
  onRemoveItem,
}) => {
  const renderItems = () => {
    return items.map((item: ReleaseItemType) => {
      return (
        <ReleaseItem
          key={item.componentName + item.version}
          componentName={item.componentName}
          status={item.status}
          version={item.version}
          commitAuthor={item.commitAuthor}
          createdTS={item.createdTS}
          changeLog={item.changeLog}
          componentDeploymentOrder={item.deploymentOrder}
          onRemove={onRemoveItem}
          successfulRelease={item.releaseSuccess}
        />
      )
    })
  }

  return <div className={classes.container}>{renderItems()}</div>
}

export default withStyles(styles)(ReleaseSidebarBody)
