import React from 'react'
import { Grid } from '@material-ui/core'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import PageTitle from 'common/components/PageTitle'
import { VerificationContainer } from 'provision/containers/VerificationContainer'

type Props = RouteComponentProps

const VerificationScreen: React.FC<Props> = (props) => (
  <React.Fragment>
    <PageTitle title="Verify Onboarding" />
    <Grid container justifyContent="center">
      <Grid item md={4}>
        <p className="tx-body">
          Verify the onboarding status of an existing AWS Account into Stax
        </p>
        <VerificationContainer {...props} />
      </Grid>
    </Grid>
  </React.Fragment>
)

export default withRouter(VerificationScreen)
