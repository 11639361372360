import React from 'react'
import AddCustomerContainer from '../containers/AddCustomerContainer'
import { Grid } from '@material-ui/core'
import PageTitle from 'common/components/PageTitle'

const AddCustomerScreen = () => (
  <React.Fragment>
    <PageTitle title="Provision" />
    <Grid container justifyContent="center">
      <Grid item md={4}>
        <h2>Add New Customer to Stax installation</h2>
        <AddCustomerContainer />
      </Grid>
    </Grid>
  </React.Fragment>
)

export default AddCustomerScreen
