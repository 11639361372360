import React from 'react'
import { observer } from 'mobx-react-lite'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import { Tooltip } from '@material-ui/core'

import RootStore from 'common/stores/RootStore'
import { CanAny, Role } from 'common/components/Can'

interface State {
  comment: string | null
  enabled: boolean
  open: boolean
}

type OrgId = string

type Props = {
  className?: string
  enabled: boolean
  orgId: OrgId
}

const LocalUserInviteSwitch = observer(({ enabled, ...restProps }: Props) => {
  const { organisation } = React.useContext(RootStore)
  const [state, setState] = React.useState<State>({
    comment: '',
    enabled: enabled,
    open: false,
  })

  const handleChange = () => {
    setState({ ...state, open: true })
  }

  const toggleLock = () => {
    if (state.comment) {
      console.log(organisation, restProps)
      organisation.setConfig(
        restProps.orgId,
        'EnableLocalUserInvite',
        !state.enabled,
        state.comment,
      )
      setState({
        ...state,
        enabled: !state.enabled,
        open: false,
        comment: state.comment,
      })
    } else {
      setState({ ...state, open: false })
    }
  }

  const handleClose = () => {
    setState({ ...state, open: false })
  }

  const updateText = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setState({ ...state, comment: event.target.value })
  }

  return (
    <CanAny
      requiredRoles={[Role.customersupport, Role.deployer]}
      yes={
        <React.Fragment>
          <FormControlLabel
            control={
              <Switch
                checked={state.enabled}
                onChange={handleChange}
                value={true}
                size="small"
              />
            }
            label=""
          />
          <Dialog
            open={state.open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              Enable Local User Invites
            </DialogTitle>
            {!state.enabled ? (
              <DialogContent>
                <DialogContentText>
                  To enable local user invites a comment is required.
                </DialogContentText>
                <TextField
                  autoFocus
                  required
                  margin="dense"
                  value={state.comment ? state.comment : ''}
                  onChange={updateText}
                  id="comment"
                  label="comment"
                  type="text"
                  fullWidth
                />
              </DialogContent>
            ) : (
              <DialogContent>
                <DialogContentText>
                  To disable local user invites a comment is required.
                </DialogContentText>
                <TextField
                  autoFocus
                  required
                  margin="dense"
                  value={state.comment ? state.comment : ''}
                  onChange={updateText}
                  id="comment"
                  label="comment"
                  type="text"
                  fullWidth
                />
              </DialogContent>
            )}
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              {state.enabled ? (
                <Button onClick={toggleLock} color="primary">
                  Disable
                </Button>
              ) : (
                <Button onClick={toggleLock} color="primary">
                  Enable
                </Button>
              )}
            </DialogActions>
          </Dialog>
        </React.Fragment>
      }
      no={
        <Tooltip
          title={`You don't have permission to ${
            state.enabled ? 'disable' : 'enable'
          } local user invites.`}
        >
          <FormControlLabel
            control={
              <Switch
                checked={state.enabled}
                value={true}
                disabled={true}
                size="small"
              />
            }
            label=""
          />
        </Tooltip>
      }
    />
  )
})

export default LocalUserInviteSwitch
