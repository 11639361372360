import { Chip, Typography } from '@material-ui/core'
import { cyan, amber, pink, grey } from '@material-ui/core/colors'
import React from 'react'

type Props = React.ComponentProps<typeof Chip> & {
  status?: StatusType
}

type StatusType =
  | string
  | 'ACTIVE'
  | 'CREATE_FAILED'
  | 'INITIALIZING'
  | 'UPDATE_AVAILABLE'
  | 'SUSPENDED'
  | 'PROSPECT'
  | 'DELETED'
  | 'DELETE_IN_PROGRESS'
  | 'UPDATE_IN_PROGRESS'
  | 'NEW'
  | 'UPDATE_FAILED'
  | 'DISABLED'
  | 'INVITED'
  | 'ACTION_REQUIRED'
  | 'OPEN'
  | 'RESOLVED'
  | 'PENDING'
  | 'FAILED'
  | 'CLOSED'
  | 'ONBOARDING'
  | 'ERROR'
  | 'LOCKED'
  | 'DISCOVERED'
  | 'CREATE_IN_PROGRESS'

const colors = {
  cyan: cyan[500],
  amber: amber[500],
  pink: pink[500],
  grey: grey[500],
}

const StatusToColor = new Map<StatusType, string>([
  ['ACTIVE', colors.cyan],
  ['OPEN', colors.cyan],
  ['UP', colors.cyan],
  ['NEW', colors.amber],
  ['PENDING', colors.amber],
  ['INITIALIZING', colors.amber],
  ['UPDATE_IN_PROGRESS', colors.amber],
  ['ONBOARDING', colors.amber],
  ['UPDATE_AVAILABLE', colors.amber],
  ['CREATE_IN_PROGRESS', colors.amber],
  ['LOCKED', colors.amber],
  ['ACTION_REQUIRED', colors.pink],
  ['DELETE_IN_PROGRESS', colors.pink],
  ['SUSPENDED', colors.pink],
  ['CREATE_FAILED', colors.pink],
  ['UPDATE_FAILED', colors.pink],
  ['ERROR', colors.pink],
  ['FAILED', colors.pink],
  ['DOWN', colors.pink],
  ['DELETED', colors.grey],
  ['DISABLED', colors.grey],
  ['INVITED', colors.grey],
  ['PROSPECT', colors.grey],
  ['RESOLVED', colors.grey],
  ['UNKNOWN', colors.grey],
  ['CLOSED', colors.grey],
  ['DISCOVERED', colors.grey],
])

export const Status = ({ children, status, ...props }: Props) => {
  const text = children || status
  return (
    <Chip
      label={
        <Typography variant="overline" style={{ color: '#fff' }}>
          <b>{text}</b>
        </Typography>
      }
      style={{ backgroundColor: status && StatusToColor.get(status) }}
      {...props}
    />
  )
}
