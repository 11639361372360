import React from 'react'
import { observer } from 'mobx-react-lite'

import CustomerTable from 'customer/components/CustomerTable'
import RootStore from 'common/stores/RootStore'
import { sortBy } from 'lodash'
import NoResults from 'common/components/NoResults'
import Loading from 'common/components/Loading'

const headers = ['Customer', 'Support Level', 'Type', 'Status']

const CustomerListContainer = observer(() => {
  const { customer: customerStore } = React.useContext(RootStore)

  React.useEffect(() => {
    customerStore.fetch()
  }, [])

  if (customerStore.isLoading) return <Loading />
  if (customerStore.error) return <NoResults title="Error fetching Customers" />
  if (customerStore.customers.size < 1)
    return <NoResults title="No Customers" />

  return (
    <CustomerTable
      customers={sortBy(customerStore.list, ['name'])}
      headers={headers}
      isLoading={customerStore.isLoading}
    />
  )
})

export default CustomerListContainer
