import {
  action,
  computed,
  observable,
  ObservableMap,
  makeObservable,
} from 'mobx'
import { Account, OrgId, OrganisationType } from 'account/stores/types'
import { AccountApi } from 'account/stores/AccountApi'
import { RootStore } from 'common/stores/RootStore'
import { Status } from 'common/api'

/* AccountStore queries the API for a list of all AWS Accounts within a chosen
 * Stax Organisation.
 */

export default class AccountStore {
  root: RootStore
  // Separate out concrete API calls to make it easier to
  // test the stores in isolation
  api: AccountApi
  endpoint: string
  error: Error | undefined

  constructor(root: RootStore, api: AccountApi, endpoint = '/accounts') {
    makeObservable(this, {
      status: observable,
      accounts: observable,
      fetch: action,
      billingAccounts: observable,
      fetchBilling: action,
      invokeSaga: action,
      invokeDiscoverySaga: action,
      reonboard: action,
      isLoading: computed,
    })

    this.root = root
    this.api = api
    this.endpoint = endpoint
  }

  status: Status = Status.Idle

  // Store Accounts under Organisation ID in a Map in order to facilitate
  // faster lookups when switching between organisations
  accounts: ObservableMap<OrgId, Account[]> = observable.map()

  fetch = async (orgId: string, force = false) => {
    try {
      this.status = Status.Pending
      const res = await this.api.fetch(
        `${this.endpoint}?organisation=${orgId}`,
        force,
      )
      this.accounts.set(orgId, res.data.accounts)
      this.status = Status.Success
    } catch (err: any) {
      this.status = Status.Error
      this.error = err
    }
  }

  billingAccounts: ObservableMap<OrganisationType, Account[]> = observable.map()

  fetchBilling = async (organisationType: string, force = false) => {
    try {
      this.status = Status.Pending
      const res = await this.api.fetch(
        `${this.endpoint}?organisation_type=${organisationType}`,
        force,
      )
      this.billingAccounts.set(organisationType, res.data.accounts)
      this.status = Status.Success
    } catch (err: any) {
      this.status = Status.Error
      this.error = err
    }
  }

  invokeSaga = (accountId: string) => {
    return this.api.invoke('/saga/account', accountId)
  }

  invokeDiscoverySaga = (orgId: string, awsAccountId: string) => {
    return this.api.rediscover('/saga/account/rediscover', orgId, awsAccountId)
  }

  // This is the same as "invoke". I'm duplicating the method for clarity.
  reonboard = (accountId: string) => {
    return this.api.invoke('/saga/account', accountId)
  }

  get isLoading() {
    return this.status === Status.Pending
  }

  list = (orgId: OrgId) => {
    return this.accounts.get(orgId) || []
  }

  billingList = (organisationType: OrganisationType) => {
    return this.billingAccounts.get(organisationType) || []
  }
}
