import React from 'react'
import { observer } from 'mobx-react-lite'
import {
  createStyles,
  withStyles,
  WithStyles,
  Theme,
} from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'

import RootStore from 'common/stores/RootStore'
import { Component } from 'component/stores/types'
import Can, { Role } from 'common/components/Can'

type Props = WithStyles<typeof styles> & {
  className?: string
  enabled: boolean
  component: Component
  disabled?: boolean
}

const styles = ({ palette }: Theme) =>
  createStyles({
    root: {},
  })

const ComponentDeploySwitch = observer(
  ({ enabled, disabled = false, ...restProps }: Props) => {
    const { component } = React.useContext(RootStore)
    const [isEnabled, setIsEnabled] = React.useState(enabled)

    const handleChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      checked: boolean,
    ) => {
      setIsEnabled(checked)
      component.autodeploy(restProps.component, checked)
    }

    return (
      <Can
        requiredRole={Role.deployer}
        yes={
          <FormControlLabel
            control={
              <Switch
                checked={isEnabled}
                onChange={handleChange}
                value={true}
                color="primary"
                disabled={disabled}
              />
            }
            label="Auto Deploy"
          />
        }
        no={
          <FormControlLabel
            control={
              <Switch
                checked={isEnabled}
                value={true}
                color="primary"
                disabled={true}
              />
            }
            label="Auto Deploy"
          />
        }
      />
    )
  },
)

export default withStyles(styles)(ComponentDeploySwitch)
