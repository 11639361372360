import React from 'react'
import { observer } from 'mobx-react-lite'
import { debounce } from 'dettle'
import ComponentCatalogue from 'component/components/ComponentCatalogue'
import RootStore from 'common/stores/RootStore'
import Routes from 'component/routes'
import { Paper, TextField } from '@material-ui/core'

const ComponentContainer = observer(() => {
  const { component } = React.useContext(RootStore)

  const [queryInput, setQueryInput] = React.useState('')
  const [appliedQuery, setAppliedQuery] = React.useState('')

  const setQueryDebounced = React.useCallback(
    debounce((query: string) => {
      setAppliedQuery(query)
    }, 200),
    [],
  )

  const handleChange = React.useCallback(
    (event) => {
      const query = event.target.value
      setQueryInput(query)
      setQueryDebounced(query)
    },
    [setQueryDebounced],
  )

  // biome-ignore lint/correctness/useExhaustiveDependencies: initial fetch, called once
  React.useEffect(() => {
    component.fetch()
  }, [])

  return (
    <React.Fragment>
      <Paper style={{ marginBottom: '1rem', padding: '1rem' }}>
        <TextField
          label="Search"
          placeholder="Search components by name..."
          value={queryInput}
          onChange={handleChange}
          variant="outlined"
        />
      </Paper>
      <ComponentCatalogue
        appliedQuery={appliedQuery}
        components={component.list}
        route={Routes.INDEX}
        isLoading={component.isLoading}
      />
    </React.Fragment>
  )
})

export default ComponentContainer
