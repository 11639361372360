import React from 'react'
import ReactDOM from 'react-dom'

import RootStore from 'common/stores/RootStore'

interface Props {
  children: React.ReactChild
}

const HeaderContainer = ({ children, ...restProps }: Props) => {
  const containerElemRef = React.useRef<HTMLDivElement>(null)
  const { navigation } = React.useContext(RootStore)

  React.useEffect(() => {
    const elem = navigation.sectionRef
    if (elem && containerElemRef.current) {
      elem.appendChild(containerElemRef.current)
    }

    return () => {
      if (containerElemRef.current) {
        containerElemRef.current.remove()
      }
    }
  }, [])

  const getContainerElem = () => {
    if (!containerElemRef.current) {
      // current is now mutable but types not updated
      // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/31065
      // @ts-ignore
      containerElemRef.current = document.createElement('div')
    }

    return containerElemRef.current
  }

  const target = getContainerElem()
  return ReactDOM.createPortal(children, target)
}

export default HeaderContainer
