import React, { Fragment } from 'react'

interface Props {}

export default class DashboardContainer extends React.Component<Props> {
  render() {
    return (
      <Fragment>
        <h1>Admin Header</h1>
      </Fragment>
    )
  }
}
