const INDEX = '/provision'

const provisionRoutes = {
  INDEX,
  ADDROOT: `${INDEX}/AddBillingRoot`,
  ADDCUST: `${INDEX}/AddCustomer`,
  ADDORG: `${INDEX}/AddOrganisation`,
  VERIFY: `${INDEX}/Verify`,
}

export default provisionRoutes
