import React, { Fragment } from 'react'
import { observer } from 'mobx-react-lite'
import { RouteComponentProps } from 'react-router'
import {
  styled,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'

import RootStore from 'common/stores/RootStore'
import NoResults from 'common/components/NoResults'
import Loading from 'common/components/Loading'
import { usePolicies, Policy, PolicyAttachment } from 'common/api'
import { Organisation } from 'organisation/stores/types'
import { DateText } from 'common/components/DateText'
import { Status } from 'common/components/Status'

const headers = [
  { title: 'Id' },
  { title: 'Name' },
  { title: 'Description' },
  { title: 'Organisation Attached' },
  { title: 'Policy Type' },
  { title: 'Policy Owner' },
  { title: 'Status' },
  { title: 'Modified' },
  { title: 'Attachments' },
  { title: 'Content' },
]

const Table = styled(MuiTable)({
  '& td': {
    verticalAlign: 'top',
  },
})

export const PoliciesListContainer: React.FC<
  RouteComponentProps<{ id: string }>
> = observer(({ history, match }) => {
  const { organisation: organisationStore } = React.useContext(RootStore)

  const organisationId = (organisationStore.current as Organisation)?.id || ''

  const {
    policies = [],
    policy_attachments = [],
    loading,
    error,
  } = usePolicies(organisationId || '')

  console.log({ policies, loading, error })

  if (!organisationStore.current) {
    return <NoResults title="No organisation selected" />
  }

  if (organisationStore.current.status === 'SUSPENDED') {
    return <NoResults title="Organisation is suspended" />
  }

  if (policies.length === 0) {
    return (
      <React.Fragment>
        <NoResults title="No policies found" />
      </React.Fragment>
    )
  }

  if (organisationStore.isLoading || loading) return <Loading />

  const OrganisationAttachment = ({ policy }: { policy: Policy }) => {
    const value = policy.OrganisationAttachment || []
    if (value.length === 0) {
      return <Fragment>FALSE</Fragment>
    }
    return <Fragment>TRUE</Fragment>
  }

  const PolicyAttachments = ({
    policy,
    policy_attachments,
  }: { policy: Policy; policy_attachments: PolicyAttachment[] }) => {
    return (
      <Fragment>
        {policy_attachments
          .filter((item) => item.PolicyId === policy.Id)
          .map((item) => (
            <div
              key={item.Id}
              style={{ paddingTop: '5px', paddingBottom: '5px' }}
            >
              <span style={{ display: 'block' }}>
                Type: {item.PolicyAttachmentType}
              </span>
              <span style={{ display: 'block' }}>
                Target: {item.OrganisationalUnitId || item.AccountId}
              </span>
              <span style={{ display: 'block' }}>Status: {item.Status}</span>
            </div>
          ))}
      </Fragment>
    )
  }

  const Content = ({ policy }: { policy: Policy }) => {
    return <Fragment>{JSON.stringify(policy.Content)}</Fragment>
  }

  return (
    <Fragment>
      <Table>
        <TableHead>
          <TableRow>
            {headers.map((header, index) => (
              <TableCell key={header.title}>{header.title}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {policies.map((policy, index) => (
            <TableRow key={policy.Id}>
              <TableCell>
                {policy.Id} ({policy.AwsId})
              </TableCell>
              <TableCell>{policy.Name}</TableCell>
              <TableCell>{policy.Description}</TableCell>
              <TableCell>
                <OrganisationAttachment policy={policy} />
              </TableCell>
              <TableCell>{policy.PolicyType}</TableCell>
              <TableCell>{policy.PolicyOwner}</TableCell>
              <TableCell>
                <Status status={policy.Status} />
              </TableCell>
              <TableCell>
                <DateText dateString={policy.ModifiedTS} />
              </TableCell>
              <TableCell>
                <PolicyAttachments
                  policy={policy}
                  policy_attachments={policy_attachments}
                />
              </TableCell>
              <TableCell>
                <Content policy={policy} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Fragment>
  )
})
