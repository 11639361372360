import React from 'react'
import { Avatar } from '@material-ui/core'
import { UnstyledButton } from 'common/components/UnstyledButton'

interface Props {
  onClick: React.MouseEventHandler<any>
  classes: string
  text?: string
}

const AvatarButton = ({ classes, text, ...restProps }: Props) => (
  <UnstyledButton {...restProps}>
    <Avatar className={classes}>{text}</Avatar>
  </UnstyledButton>
)

export default AvatarButton
