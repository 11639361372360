import { SvgIcon } from '@material-ui/core'
import React from 'react'

export const StaxLogo = (props: React.ComponentProps<typeof SvgIcon>) => {
  return (
    <SvgIcon viewBox="0 0 35 42" {...props}>
      <path d="M17.34 0L0 7.52V34l17.34 7.68L34.677 34V7.52L17.34 0zM2.534 32.28V9.24l14.804-6.48 14.845 6.48v23.04l-14.845 6.48-14.804-6.48z" />
      <path d="M17.34 27.08l-9.777-4.64v5.12l9.776 4.6zM27.155 22.56L7.563 13.4v5.08l19.592 9.16zM27.155 13.56L17.34 8.84v5.24l9.816 4.48z" />
    </SvgIcon>
  )
}
