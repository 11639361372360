import React from 'react'
import classNames from 'classnames'
import green from '@material-ui/core/colors/green'
import amber from '@material-ui/core/colors/amber'
import MaterialSnackbar, {
  SnackbarOrigin,
  SnackbarProps as MaterialSnackbarProps,
} from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles'
import { Warning } from '@material-ui/icons'

const styles = (theme: Theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
    opacity: 0.9,
    marginRight: theme.spacing(2),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
})

type BaseProps = MaterialSnackbarProps & WithStyles<typeof styles>

export type Variant = 'success' | 'warning' | 'error' | 'info'

export interface Props extends BaseProps {
  message: React.ReactNode
  variant?: Variant
  className?: string
  action?: React.ReactNode
}

// type IconType = { [key in Variant]: IconProp }

// const defaultIcon: IconType = {
//   success: 'check',
//   warning: 'exclamation-triangle',
//   error: 'exclamation-triangle',
//   info: 'info-circle',
// }

const defaultOrigin: SnackbarOrigin = {
  horizontal: 'center',
  vertical: 'top',
}

function Snackbar(props: Props) {
  const { variant = 'info', open = false, anchorOrigin = defaultOrigin } = props
  const {
    classes,
    className,
    message,
    onClose,
    autoHideDuration,
    action,
    ...other
  } = props

  return (
    <MaterialSnackbar
      {...other}
      anchorOrigin={anchorOrigin}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
    >
      <SnackbarContent
        className={classNames(classes[variant], className)}
        action={action}
        message={
          <span className={classes.message}>
            <Warning className={classNames(classes.icon)} />

            {message}
          </span>
        }
      />
    </MaterialSnackbar>
  )
}

export default withStyles(styles)(Snackbar)
