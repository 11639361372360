import React from 'react'
import { createStyles, withStyles, Theme } from '@material-ui/core/styles'
import SvgIcon from '@material-ui/core/SvgIcon'

interface ShoppingBagIconProps {
  classes: any
  className: any
  fill?: any
  text?: any
}

const styles = (theme: Theme) =>
  createStyles({
    root: {},
  })

const ShoppingBagIcon: React.SFC<ShoppingBagIconProps> = (
  props: ShoppingBagIconProps,
) => (
  <SvgIcon {...props} viewBox="0 0 24.5 27">
    <path d="M22.4 5.5v19.6H2.1V5.5h20.3m1.9-1.8H.2v23.2h24.1V3.7z" />

    <path d="M7.3 9.7l-1.8-.3C5.7 8.5 7.4.2 12.2.2 17.4.2 19 9 19 9.4l-1.8.3c-.4-2.1-2-7.7-4.9-7.7-2.9 0-4.6 5.7-5 7.7z" />

    {props.text ? (
      <text x="50%" y="75%" textAnchor="middle" fill="black" fontSize="14">
        {props.text}
      </text>
    ) : undefined}
  </SvgIcon>
)

export default withStyles(styles)(ShoppingBagIcon)
