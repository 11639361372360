import { ReactNode } from 'react'
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles'

interface Props extends WithStyles<typeof styles> {
  children: ReactNode
}

const styles = ({ breakpoints, spacing }: Theme) => ({
  root: {
    display: 'flex',
    [breakpoints.up('lg')]: {
      justifyContent: 'center',
    },
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    [breakpoints.up('lg')]: {
      width: '1280px',
    },
  },
})

const Layout = (props: Props) => {
  const { classes, children, ...rest } = props
  return (
    <div className={classes.root} {...rest}>
      <div className={classes.container}>{children}</div>
    </div>
  )
}

export default withStyles(styles)(Layout)
