import React, { Fragment } from 'react'
import { observer } from 'mobx-react-lite'

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core'

import RootStore from 'common/stores/RootStore/RootStore'
import NoResults from 'common/components/NoResults'
import FactoryButton from 'customer/components/FactoryButton'
import { Account } from 'account/stores/types'
import Can, { Role } from 'common/components/Can'
import { Status } from 'common/components/Status'
import { ClickToCopy } from 'common/components/ClickToCopy'
import { StaxLogo } from 'component/components/StaxLogo'

import MaybeLogin from 'organisation/components/MaybeLogin'

const headers = [
  { key: 'ids', title: 'IDs' },
  { key: 'cost', title: 'Cost' },
  { key: 'status', title: 'Status' },
  { key: 'factoryVer', title: 'Factory Ver.' },
  { key: 'action', title: 'Action' },
  { key: 'awsLogin', title: 'AWS Login' },
]

const isBillingRoot = ({ billingAccountId }: { billingAccountId: string }) =>
  !billingAccountId

// If an account is either created by stax OR a billing root it is "Stax Managed"
const isStaxManaged = (account: Account): boolean => {
  return !!account.staxCreated || isBillingRoot(account)
}

const AccountsContainer = observer(() => {
  const { account: accountStore, organisation } = React.useContext(RootStore)

  React.useEffect(() => {
    if (organisation.current) {
      accountStore.fetch(organisation.current.id)
    }
  }, [organisation.current])

  if (!organisation.current) {
    return <NoResults title="No organisation selected" />
  }

  const accounts = accountStore
    .list(organisation.current.id)
    .filter((account) => {
      if (isBillingRoot(account)) return false
      return true
    })

  const billingRoots = accountStore
    .list(organisation.current.id)
    .filter((account) => {
      if (isBillingRoot(account)) return true
      return false
    })

  const renderRow = (account: Account) => {
    const runSaga = () => accountStore.invokeSaga(account.id)
    const runDiscovery = () =>
      accountStore.invokeDiscoverySaga(
        account.organisationId,
        account.awsAccountId,
      )

    const runReonboard = () => accountStore.reonboard(account.id)

    const renderSagaButton = (disabled = false) => {
      if (account.status === 'ERROR') {
        if (account.assuranceState === 'NONE') {
          // discovery failure
          return renderDiscoveryButton(disabled)
        }
        if (
          account.assuranceState !== 'NONE' &&
          account.assuranceState !== 'UPDATING'
        ) {
          // onboarding failure
          return (
            <FactoryButton
              onClick={runReonboard}
              key="factory-re-onboard"
              disabled={disabled}
            >
              <Typography
                variant={'caption'}
                style={{ color: 'white', fontWeight: 'bold' }}
              >
                Re-onboard
              </Typography>
            </FactoryButton>
          )
        }
      }

      const assuranceButtonDisabled =
        account.status === 'DISCOVERED' || account.status === 'ONBOARDING'

      if (!assuranceButtonDisabled) {
        return (
          <Fragment>
            <Tooltip arrow title="Runs Account Assurance for this Account">
              <span>
                <FactoryButton
                  onClick={runSaga}
                  key="factory"
                  disabled={disabled}
                >
                  <Typography
                    variant={'caption'}
                    style={{ fontWeight: 'bold' }}
                  >
                    Run Assurance
                  </Typography>
                </FactoryButton>
              </span>
            </Tooltip>
          </Fragment>
        )
      } else {
        return renderDiscoveryButton(disabled)
      }
    }

    const renderDiscoveryButton = (disabled = false) => {
      const buttonDisabled = account.status !== 'DISCOVERED' || disabled

      return (
        <Fragment>
          <FactoryButton
            onClick={runDiscovery}
            key="factory-re-discovery"
            disabled={buttonDisabled}
          >
            <Typography variant={'caption'} style={{ fontWeight: 'bold' }}>
              Run Discovery
            </Typography>
          </FactoryButton>
        </Fragment>
      )
    }

    return (
      <TableRow key={account.id}>
        <TableCell>
          <Typography>
            {account.name}
            {isStaxManaged(account) && (
              <Tooltip title="Stax-managed Account">
                <span>
                  <StaxLogo
                    style={{ marginBottom: '-7px', marginLeft: '0.5rem' }}
                  />
                </span>
              </Tooltip>
            )}
          </Typography>
        </TableCell>
        <TableCell>
          <Box>
            <small>
              AWS ID: {account.awsAccountId}{' '}
              <ClickToCopy text={account.awsAccountId} />
            </small>
          </Box>
          <Box>
            <small>
              Stax ID: {account.id} <ClickToCopy text={account.id} />
            </small>
          </Box>
        </TableCell>
        <TableCell>
          {account.latestCost ? `$${account.latestCost}` : '$0.00'}
        </TableCell>
        <TableCell>
          <Status status={account.status} />
        </TableCell>
        <TableCell>
          <small>
            {account.factoryVersion}{' '}
            <ClickToCopy text={account.factoryVersion} />
          </small>
        </TableCell>
        <TableCell style={{ width: '15%' }}>
          <Can
            requiredRole={Role.deployer}
            yes={renderSagaButton()}
            no={renderSagaButton(true)}
          />
        </TableCell>
        <TableCell style={{ width: '15%' }}>
          <MaybeLogin {...{ account, organisation }} />
        </TableCell>
      </TableRow>
    )
  }

  return (
    <Table>
      {billingRoots.length > 0 && (
        <React.Fragment>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography>
                  <b>Billing roots</b>
                </Typography>
              </TableCell>
              {headers.map((header, i) => (
                <TableCell
                  key={header.key}
                  style={{ minWidth: i === 0 ? '20%' : 'none' }}
                >
                  {header.title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{billingRoots.map(renderRow)}</TableBody>
          <br />
          <br />
        </React.Fragment>
      )}
      <TableHead>
        <TableRow>
          <TableCell>
            <Typography>
              <b>Member accounts</b>
            </Typography>
          </TableCell>
          {headers.map((header, i) => (
            <TableCell
              key={header.key}
              style={{ minWidth: i === 0 ? '20%' : 'none' }}
            >
              {header.title}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>{accounts.map(renderRow)}</TableBody>
    </Table>
  )
})

export default AccountsContainer
