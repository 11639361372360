import { post } from 'common/api'

export interface ProvisionApi {
  invoke: (endpoint: string, data: {}) => Promise<any>
}

class ProvisionService implements ProvisionApi {
  invoke = (endpoint: string, data: {}) => {
    return post(endpoint, data)
  }
}

export default ProvisionService
