import { styled, useTheme } from '@material-ui/core'
import { CheckCircle, FileCopy } from '@material-ui/icons'
import { useCopyToClipboard } from 'common/hooks'
import { UnstyledButton } from './UnstyledButton'

const StyledButton = styled(UnstyledButton)({
  '& svg': {
    // fontSize: '0.875rem',
    fontSize: 'inherit',
  },
})

export const ClickToCopy = ({
  text = '',
  ...rest
}: { text?: string } & React.ComponentProps<typeof StyledButton>) => {
  const { palette } = useTheme()
  const [copied, copy] = useCopyToClipboard(text)

  return (
    <StyledButton
      onClick={copy}
      title={copied ? 'Copied!' : 'Click to copy'}
      {...rest}
    >
      {copied && <CheckCircle style={{ color: palette.success.light }} />}
      {!copied && <FileCopy style={{ color: palette.text.secondary }} />}
    </StyledButton>
  )
}
