import { observer } from 'mobx-react-lite'
import className from 'classnames'
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import { StaxLogo } from 'component/components/StaxLogo'

interface Props extends WithStyles<typeof styles> {
  compact: boolean
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      color: 'white',
    },
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '6rem',
    },
    icon: {
      width: '2.4rem',
      height: '2.4rem',
    },
    compact: {
      width: '1.8rem',
      height: '1.8rem',
    },
  })

const NavLogo = observer((props: Props) => {
  const { classes, compact } = props
  const iconClasses = className(
    classes.root,
    compact === true ? classes.compact : classes.icon,
  )

  return (
    <Link to="/" className={classes.container} title="Juma">
      <StaxLogo classes={{ root: iconClasses }} />
    </Link>
  )
})

export default withStyles(styles)(NavLogo)
