import React from 'react'
import { observer } from 'mobx-react-lite'
import {
  createStyles,
  withStyles,
  WithStyles,
  Theme,
} from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'

import RootStore from 'common/stores/RootStore'
import { Component } from 'component/stores/types'
import Can, { Role } from 'common/components/Can'

interface State {
  comment: string | null
  enabled: boolean
  open: boolean
}

type Props = WithStyles<typeof styles> & {
  className?: string
  enabled: boolean
  component: Component
}

const styles = ({ palette }: Theme) =>
  createStyles({
    root: {},
  })

const ComponentLockSwitch = observer(({ enabled, ...restProps }: Props) => {
  const { component } = React.useContext(RootStore)
  const [state, setState] = React.useState<State>({
    comment: '',
    enabled: enabled,
    open: false,
  })

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    setState({ ...state, open: true })
  }

  const toggleLock = () => {
    if (state.comment) {
      component.lock(restProps.component, !state.enabled, state.comment)
      setState({
        ...state,
        enabled: !state.enabled,
        open: false,
        comment: state.comment,
      })
    } else {
      setState({ ...state, open: false })
    }
  }

  const handleClose = () => {
    setState({ ...state, open: false })
  }

  const updateText = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setState({ ...state, comment: event.target.value })
  }

  return (
    <Can
      requiredRole={Role.deployer}
      yes={
        <React.Fragment>
          <FormControlLabel
            control={
              <Switch
                checked={state.enabled}
                onChange={handleChange}
                value={true}
                color="primary"
              />
            }
            label="Lock Deploy"
          />
          <Dialog
            open={state.open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Lock</DialogTitle>
            {!state.enabled ? (
              <DialogContent>
                <DialogContentText>
                  To lock this component a comment is required.
                </DialogContentText>
                <TextField
                  autoFocus
                  required
                  margin="dense"
                  value={state.comment ? state.comment : ''}
                  onChange={updateText}
                  id="comment"
                  label="comment"
                  type="text"
                  fullWidth
                />
              </DialogContent>
            ) : (
              <DialogContent>
                <DialogContentText>
                  To unlock this component a comment is required.
                </DialogContentText>
                <TextField
                  autoFocus
                  required
                  margin="dense"
                  value={state.comment ? state.comment : ''}
                  onChange={updateText}
                  id="comment"
                  label="comment"
                  type="text"
                  fullWidth
                />
              </DialogContent>
            )}
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              {state.enabled ? (
                <Button onClick={toggleLock} color="primary">
                  Unlock
                </Button>
              ) : (
                <Button onClick={toggleLock} color="primary">
                  Lock
                </Button>
              )}
            </DialogActions>
          </Dialog>
        </React.Fragment>
      }
      no={
        <React.Fragment>
          <FormControlLabel
            control={
              <Switch
                checked={state.enabled}
                value={true}
                color="primary"
                disabled={true}
              />
            }
            label="Lock Deploy"
          />
        </React.Fragment>
      }
    />
  )
})

export default withStyles(styles)(ComponentLockSwitch)
