import React from 'react'
import { observer } from 'mobx-react-lite'
import {
  Button,
  Select,
  Radio,
  RadioGroup,
  FormControl,
} from '@material-ui/core'
import { withStyles, WithStyles } from '@material-ui/core/styles'
import TextInput from 'common/components/TextInput'
import RootStore from 'common/stores/RootStore'
import { useField, Formik, FormikProps, Form } from 'formik'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import {
  FIELDS,
  CUSTOMER_TYPES,
  AWS_SUPPORT_TYPES,
  FIELD_HELPER_TEXTS,
  CUSTOMER_FORM_INITIAL_STATE,
} from './constants'
import {
  computeFieldStyleProps,
  renderSelectLabel,
  renderSelectHelperText,
  validateRequired,
  SelectAndLabel,
  parseAndSetField,
} from './helpers'
import { CUSTOMER_SUPPORT_PLANS } from '../../constants'

const styles = () => ({
  select: {
    '& > fieldset': {
      backgroundColor: 'white !important',
    },
  },
  selectError: {
    '& > fieldset': {
      backgroundColor: 'white !important',
      borderColor: 'red !important',
    },
  },
})

type Props = FormikProps<typeof CUSTOMER_FORM_INITIAL_STATE> & WithStyles

const AddCustomerFormWithoutStyles: React.FC<Props> = observer(
  ({ classes, handleSubmit, setFieldValue }) => {
    const { billingroot } = React.useContext(RootStore)

    const [
      customerTypeField,
      { error: customerTypeError, touched: customerTypeTouched },
    ] = useField({ name: FIELDS.CUSTOMER_TYPE, validate: validateRequired })

    const customerTypeDirect = customerTypeField.value === CUSTOMER_TYPES.DIRECT

    const [
      customerSupportPlanField,
      { error: customerSupportPlanError, touched: customerSupportPlanTouched },
    ] = useField({
      name: FIELDS.CUSTOMER_SUPPORT_PLAN,
      validate: validateRequired,
    })

    const [
      awsSupportTypeField,
      // { error: awsSupportTypeError, touched: awsSupportTypeTouched },
    ] = useField({ name: FIELDS.AWS_SUPPORT_TYPE, validate: validateRequired })

    const [internalCustomerField] = useField({
      name: FIELDS.INTERNAL_CUSTOMER,
      validate: validateRequired,
    })

    const [partnerSupportField] = useField({
      name: FIELDS.PARTNER_SUPPORT,
      validate: !customerTypeDirect ? validateRequired : undefined,
    })

    const [marketplaceSignupField] = useField({
      name: FIELDS.MARKETPLACE_SIGNUP,
      validate: validateRequired,
    })

    const [
      billingRootField,
      { touched: billingRootTouched, error: billingRootError },
    ] = useField({ name: FIELDS.BILLING_ROOT, validate: validateRequired })

    const [
      companyNameField,
      { touched: companyNameTouched, error: companyNameError },
    ] = useField({ name: FIELDS.COMPANY_NAME, validate: validateRequired })

    const [
      staxAliasField,
      { touched: staxAliasTouched, error: staxAliasError },
    ] = useField({ name: FIELDS.STAX_ALIAS, validate: validateRequired })

    const [
      emailTemplateField,
      { touched: emailTemplateTouched, error: emailTemplateError },
    ] = useField({ name: FIELDS.EMAIL_TEMPLATE, validate: validateRequired })

    const [
      rootEmailField,
      { touched: rootEmailTouched, error: rootEmailError },
    ] = useField({ name: FIELDS.ROOT_EMAIL, validate: validateRequired })

    const [spotlightIdField] = useField({ name: FIELDS.SPOTLIGHT_ID })

    const [salesforceIdField] = useField({ name: FIELDS.SALESFORCE_ID })

    const [
      idamUserEmailField,
      { touched: idamUserEmailTouched, error: idamUserEmailError },
    ] = useField({ name: FIELDS.IDAM_USER_EMAIL, validate: validateRequired })

    const [
      idamUserFirstNameField,
      { touched: idamUserFirstNameTouched, error: idamUserFirstNameError },
    ] = useField({
      name: FIELDS.IDAM_USER_FIRST_NAME,
      validate: validateRequired,
    })

    const [
      idamUserLastNameField,
      { touched: idamUserLastNameTouched, error: idamUserLastNameError },
    ] = useField({
      name: FIELDS.IDAM_USER_LAST_NAME,
      validate: validateRequired,
    })

    React.useEffect(() => {
      billingroot.fetch('UNUSED')
    }, [])

    const updateRootEmailField =
      (onChange: FormikProps<any>['handleChange']) =>
      (event: React.ChangeEvent<any>) => {
        const parsedEvent = {
          ...event,
          target: {
            ...event.target,
            value: event.target.value,
            // Shouldn't HAVE to pass this, because we've spread event.target, wtf?
            name: event.target.name,
          },
        }

        // Ensure that the root email matches the billing root
        setFieldValue(rootEmailField.name, event.target.selectedOptions[0].text)

        onChange(parsedEvent)
      }

    return (
      <Form onSubmit={handleSubmit}>
        <FormControl fullWidth={true}>
          <SelectAndLabel>
            {renderSelectLabel(customerTypeField.name, 'Customer Type*')}
            <Select {...customerTypeField}>
              {Object.values(CUSTOMER_TYPES).map((custType, index) => (
                <option value={custType} key={index}>
                  {custType}
                </option>
              ))}
            </Select>
            {renderSelectHelperText(customerTypeError, customerTypeTouched)}
          </SelectAndLabel>
        </FormControl>

        {customerTypeDirect === false ? (
          <SelectAndLabel>
            {renderSelectLabel(partnerSupportField.name, 'Partner Support?')}
            <RadioGroup row>
              <div className="radio">
                <Radio
                  name={partnerSupportField.name}
                  value={true}
                  checked={partnerSupportField.value === true}
                  onChange={parseAndSetField(partnerSupportField.onChange)}
                />
                Yes
              </div>
              <div className="radio">
                <Radio
                  name={partnerSupportField.name}
                  value={false}
                  checked={partnerSupportField.value === false}
                  onChange={parseAndSetField(partnerSupportField.onChange)}
                />
                No
              </div>
            </RadioGroup>
          </SelectAndLabel>
        ) : null}

        <SelectAndLabel>
          {renderSelectLabel(internalCustomerField.name, 'Internal Customer?')}
          <RadioGroup row>
            <div className="radio">
              <Radio
                name={internalCustomerField.name}
                value={true}
                checked={internalCustomerField.value === true}
                onChange={parseAndSetField(internalCustomerField.onChange)}
              />
              Yes
            </div>
            <div className="radio">
              <Radio
                name={internalCustomerField.name}
                value={false}
                checked={internalCustomerField.value === false}
                onChange={parseAndSetField(internalCustomerField.onChange)}
              />
              No
            </div>
          </RadioGroup>
        </SelectAndLabel>

        <SelectAndLabel>
          {renderSelectLabel(
            marketplaceSignupField.name,
            'AWS Marketplace Signup?',
          )}
          <RadioGroup row>
            <div className="radio">
              <Radio
                name={marketplaceSignupField.name}
                value={true}
                checked={marketplaceSignupField.value === true}
                onChange={parseAndSetField(marketplaceSignupField.onChange)}
              />
              Yes
            </div>
            <div className="radio">
              <Radio
                name={marketplaceSignupField.name}
                value={false}
                checked={marketplaceSignupField.value === false}
                onChange={parseAndSetField(marketplaceSignupField.onChange)}
              />
              No
            </div>
          </RadioGroup>
        </SelectAndLabel>

        <FormControl fullWidth={true}>
          <SelectAndLabel>
            {renderSelectLabel(awsSupportTypeField.name, 'AWS Support Type*')}
            <Select {...awsSupportTypeField}>
              {Object.values(AWS_SUPPORT_TYPES).map((awsSupportType, index) => (
                <option value={awsSupportType} key={index}>
                  {awsSupportType}
                </option>
              ))}
            </Select>
            {renderSelectHelperText(
              customerSupportPlanError,
              customerSupportPlanTouched,
            )}
          </SelectAndLabel>
        </FormControl>

        <FormControl fullWidth={true}>
          <SelectAndLabel>
            {renderSelectLabel(
              customerSupportPlanField.name,
              'Stax Support Plan*',
            )}
            <Select {...customerSupportPlanField}>
              {Object.values(CUSTOMER_SUPPORT_PLANS).map(
                (custSupportPlan, index) => (
                  <option value={custSupportPlan} key={index}>
                    {custSupportPlan}
                  </option>
                ),
              )}
            </Select>
            {renderSelectHelperText(
              customerSupportPlanError,
              customerSupportPlanTouched,
            )}
          </SelectAndLabel>
        </FormControl>

        <FormControl fullWidth={true}>
          <SelectAndLabel>
            {renderSelectLabel(billingRootField.name, 'Billing Root*')}
            <Select
              {...billingRootField}
              onChange={updateRootEmailField(billingRootField.onChange)}
            >
              <option disabled value="">
                Select a billing root
              </option>
              {billingroot.poolList().map((root) => (
                <option value={root.awsAccountId} key={root.awsAccountId}>
                  {root.email}
                </option>
              ))}
            </Select>
            {renderSelectHelperText(billingRootError, billingRootTouched)}
          </SelectAndLabel>
        </FormControl>

        <TextInput
          fullWidth
          monospace
          id={companyNameField.name}
          label="Company Name*"
          {...companyNameField}
          {...computeFieldStyleProps(
            companyNameError,
            companyNameTouched,
            FIELD_HELPER_TEXTS[FIELDS.COMPANY_NAME],
          )}
        />
        <TextInput
          fullWidth
          monospace
          id={staxAliasField.name}
          label="Stax Alias*"
          {...staxAliasField}
          {...computeFieldStyleProps(
            staxAliasError,
            staxAliasTouched,
            FIELD_HELPER_TEXTS[FIELDS.STAX_ALIAS],
          )}
        />
        <div style={{ opacity: 1 }}>
          <TextInput
            fullWidth
            monospace
            id={emailTemplateField.name}
            label="Email domain template*"
            {...emailTemplateField}
            {...computeFieldStyleProps(
              emailTemplateError,
              emailTemplateTouched,
              FIELD_HELPER_TEXTS[FIELDS.EMAIL_TEMPLATE],
            )}
          />
        </div>
        <TextInput
          fullWidth
          monospace
          id={rootEmailField.name}
          label="Root Email*"
          {...rootEmailField}
          {...computeFieldStyleProps(
            rootEmailError,
            rootEmailTouched,
            FIELD_HELPER_TEXTS[FIELDS.ROOT_EMAIL],
          )}
        />
        <TextInput
          fullWidth
          monospace
          id={spotlightIdField.name}
          label="Spotlight External ID"
          helperText={FIELD_HELPER_TEXTS[FIELDS.SPOTLIGHT_ID]}
          {...spotlightIdField}
        />
        <TextInput
          fullWidth
          monospace
          id={salesforceIdField.name}
          label="Salesforce ID"
          helperText={FIELD_HELPER_TEXTS[FIELDS.SALESFORCE_ID]}
          {...salesforceIdField}
        />
        <div>
          <h2>First IDAM User</h2>
          <TextInput
            fullWidth
            monospace
            id={idamUserEmailField.name}
            label="Email*"
            {...idamUserEmailField}
            {...computeFieldStyleProps(
              idamUserEmailError,
              idamUserEmailTouched,
              FIELD_HELPER_TEXTS[FIELDS.IDAM_USER_EMAIL],
            )}
          />
          <TextInput
            fullWidth
            monospace
            id={idamUserFirstNameField.name}
            label="First Name*"
            {...idamUserFirstNameField}
            {...computeFieldStyleProps(
              idamUserFirstNameError,
              idamUserFirstNameTouched,
              FIELD_HELPER_TEXTS[FIELDS.IDAM_USER_FIRST_NAME],
            )}
          />
          <TextInput
            fullWidth
            monospace
            id={idamUserLastNameField.name}
            label="Last Name*"
            {...idamUserLastNameField}
            {...computeFieldStyleProps(
              idamUserLastNameError,
              idamUserLastNameTouched,
              FIELD_HELPER_TEXTS[FIELDS.IDAM_USER_LAST_NAME],
            )}
          />
        </div>
        <Button
          type="submit"
          disableRipple
          style={{
            height: '4rem',
            width: '100%',
            backgroundColor: 'red',
            color: 'white',
          }}
        >
          <b>Create Customer</b>
        </Button>
      </Form>
    )
  },
)

const AddCustomerForm = withStyles(styles)(AddCustomerFormWithoutStyles)

const AddCustomerContainer: React.FC<RouteComponentProps> = ({ history }) => {
  const { provision } = React.useContext(RootStore)

  const handleSubmit = async (values: typeof CUSTOMER_FORM_INITIAL_STATE) => {
    const payload = {
      OwnerName: values.companyName,
      Email: values.rootEmail,
      OrgAlias: values.staxAlias,
      CompanyName: values.companyName,
      Type: values.customerType,
      StaxSupportPlan: values.customerSupportPlan,
      AwsSupportType: values.awsSupportType,
      BillingAccount: values.billingRoot,
      MarketplaceSignup: values.marketplaceSignup,
      InternalCustomer: values.internalCustomer,
      IdamUserEmail: values.idamUserEmail,
      IdamUserFirstName: values.idamUserFirstName,
      IdamUserLastName: values.idamUserLastName,
    }

    // Optional payload keys
    if (values.spotlightId)
      Object.assign(payload, { SpotlightId: values.spotlightId })
    if (values.emailTemplate)
      Object.assign(payload, { EmailTemplate: values.emailTemplate })
    if (values.salesforceId)
      Object.assign(payload, { SalesforceId: values.salesforceId })

    if (values.partnerSupport) {
      Object.assign(payload, { AwsPartnerSupport: values.partnerSupport })
    } else {
      Object.assign(payload, { AwsPartnerSupport: false })
    }

    const res = await provision.add_customer(payload)

    if (res.status === 200) {
      window.alert('Success! Factory execution started')
      history.push('/provision')
    } else {
      window.alert('An unknown error occurred')
    }
  }

  return (
    <Formik
      initialValues={CUSTOMER_FORM_INITIAL_STATE}
      validateOnChange={false}
      onSubmit={handleSubmit}
    >
      {(formikProps) => <AddCustomerForm {...formikProps} />}
    </Formik>
  )
}

export default withRouter(AddCustomerContainer)
