import memoize from 'lodash/memoize'
import { get, post, put } from 'common/api'

import { AccessRequest } from 'organisation/stores/types'

export interface OrganisationApi {
  fetch: (endpoint: string, force?: boolean, data?: any) => Promise<any>
  invoke: (endpoint: string, orgId: string) => Promise<any>
  rediscoverAllAccounts: (endpoint: string, orgId: string) => Promise<any>
}

class OrganisationService implements OrganisationApi {
  cachedGet = memoize(get)

  fetch = (endpoint: string, force: boolean = false, data = {}) => {
    if (force) {
      const res = get(endpoint, data)
      this.cachedGet.cache.set(endpoint, res)
      return res
    }

    return this.cachedGet(endpoint, data)
  }

  invoke = (endpoint: string, orgId: string) => {
    return post(endpoint, { Id: orgId })
  }

  runAllAccountsSaga = (endpoint: string, orgId: string) => {
    return post(endpoint, { OrganisationId: orgId })
  }

  rediscoverAllAccounts = (endpoint: string, orgId: string) => {
    return post(endpoint, { OrganisationId: orgId })
  }

  requestAccountAccess = (orgId: string, accessRequest: AccessRequest) => {
    return post(`/organisations/${orgId}/account-access`, accessRequest)
  }

  setConfig = (endpoint: string, value: string, comment: string) => {
    return put(endpoint, { value, comment })
  }

  applyStaxPolicies = (orgId: string) => {
    return put('/organisation/' + orgId + '/policies/stax', {
      OrganisationId: orgId,
    })
  }

  syncPolicies = (orgId: string) => {
    return put('/organisation/' + orgId + '/policies/sync', {
      OrganisationId: orgId,
    })
  }

  syncOrganisationalUnits = (orgId: string) => {
    return put('/organisation/' + orgId + '/organisational-units/sync', {
      OrganisationId: orgId,
    })
  }
}

export default OrganisationService
