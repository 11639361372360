import memoize from 'lodash/memoize'
import { get, post } from 'common/api'

export interface AccountApi {
  fetch: (endpoint: string, force?: boolean, data?: any) => Promise<any>
  invoke: (endpoint: string, accountId: string) => Promise<any>
  rediscover: (
    endpoint: string,
    orgId: string,
    awsAccountId: string,
  ) => Promise<any>
}

class AccountService implements AccountApi {
  cachedGet = memoize(get)

  fetch = (endpoint: string, force: boolean = false, data = {}) => {
    if (force) {
      const res = get(endpoint, data)
      this.cachedGet.cache.set(endpoint, res)
      return res
    }

    return this.cachedGet(endpoint, data)
  }

  invoke = (endpoint: string, accountId: string) => {
    return post(endpoint, { Id: accountId })
  }

  rediscover = (endpoint: string, orgId: string, awsAccountId: string) => {
    return post(endpoint, { OrganisationId: orgId, AwsAccountId: awsAccountId })
  }
}

export default AccountService
