import {
  withStyles,
  WithStyles,
  Theme,
  createStyles,
} from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import Typography from '@material-ui/core/Typography'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'
import { isAfter } from 'date-fns/isAfter'
import { parseISO } from 'date-fns/parseISO'

import { isDev } from 'navigation/constants'
import ComponentTime from 'component/components/ComponentTime'
import ComponentBuild from 'component/components/ComponentBuild'
import ComponentDeploySwitch from 'component/components/ComponentDeploySwitch'
import { Component } from 'component/stores/types'
import ComponentLockSwitch from './ComponentLockSwitch'
import { Status } from 'common/components/Status'
import Loading from 'common/components/Loading'

type Props = WithStyles<typeof styles> & {
  component?: Component
  isLoading: boolean
  failing: Component[]
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(3),
    },
    title: {
      fontWeight: 600,
    },
    header: {
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: theme.spacing(3),
      marginBottom: theme.spacing(2),
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
    },
    flex: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    text: {
      maxHeight: '75px',
      overflowX: 'scroll',
      overflowY: 'scroll',
    },
    sidebar: {
      display: 'flex',
      justifyContent: 'right',
    },
  })

const ComponentItemContent = observer(
  ({ classes, component, failing, isLoading, ...restProps }: Props) => {
    if (!component) return null

    const availableDeployments = component.availableDeployments || []

    const deployments = [component.activeDeployment, ...availableDeployments]

    const blocking = failing.filter(
      (item) => item.deploymentOrder < component.deploymentOrder,
    )

    if (isLoading) return <Loading />

    return (
      <div className={classes.root} {...restProps}>
        <div className={classes.header}>
          {component && (
            <Typography variant="h4" className={classes.title}>
              <div className={classes.flex}>
                <div>{component.name}</div>
                <div>
                  {!component.deployPassing && <Status status={'FAILING'} />}
                </div>
              </div>
            </Typography>
          )}
          <div className={classes.flex}>
            {component.activeDeployment ? (
              <ComponentTime time={component.activeDeployment.modifiedTS} />
            ) : (
              <span>No active deployment</span>
            )}
            <div className={classes.sidebar}>
              {blocking && blocking.length > 0
                ? `Blocked by ${blocking[0].name}`
                : undefined}
            </div>
          </div>

          <div>
            <ComponentDeploySwitch
              enabled={component.autoDeploy}
              component={component}
              disabled={!isDev()}
            />
            <ComponentLockSwitch
              enabled={component.locked}
              component={component}
            />
            <div className={classes.flex}>
              <div className={classes.text}>
                {component.comments && component.comments.length > 0
                  ? `${
                      component.comments.reduce((prev, current) => {
                        if (prev) {
                          return isAfter(
                            parseISO(prev.createdTS),
                            parseISO(current.createdTS),
                          )
                            ? prev
                            : current
                        } else {
                          return current
                        }
                      }).comment
                    }`
                  : undefined}
              </div>
            </div>
          </div>
        </div>

        <div className={classes.content}>
          <List component="ul">
            {component &&
              deployments.map((deployment) => {
                if (!deployment) return null

                const isNew =
                  component.activeDeployment &&
                  isAfter(
                    parseISO(deployment.createdTS),
                    parseISO(component.activeDeployment.createdTS),
                  )
                const isCurrent =
                  component.activeDeployment &&
                  component.activeDeployment.id === deployment.id

                return (
                  <ListItem component="li" key={deployment.id}>
                    <ComponentBuild
                      activeVersion={
                        component.activeDeployment &&
                        component.activeDeployment.version
                      }
                      deployment={deployment}
                      isCurrent={isCurrent || false}
                      isNew={isNew || false}
                      locked={component.locked}
                    />
                  </ListItem>
                )
              })}
          </List>
        </div>
      </div>
    )
  },
)

export default withStyles(styles)(ComponentItemContent)
