import React from 'react'
import Routes from '../../routes'
import { Switch, Route } from 'react-router'
import PageTitle from 'common/components/PageTitle'
import PageContent from 'common/components/PageContent'
import AddBillingRootScreen from '../screens/AddBillingRootScreen'
import AddCustomerScreen from '../screens/AddCustomerScreen'
import AddOrgScreen from './AddOrgScreen'
import VerificationScreen from './VerificationScreen'
import RootStore from 'common/stores/RootStore'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import BillingRootContainer from '../containers/BillingRootContainer'
import BillingRootPoolContainer from '../containers/BillingRootPoolContainer'
import Can, { Role } from 'common/components/Can'
import { observer } from 'mobx-react-lite'
import { Button } from '@material-ui/core'

const ProvisionScreen: React.FC<RouteComponentProps> = observer(
  ({ history }) => {
    const { billingroot } = React.useContext(RootStore)

    React.useEffect(() => {
      billingroot.fetch('UNUSED')
    }, [])

    const numActiveBillingRoots = billingroot.poolList().length

    return (
      <React.Fragment>
        <PageContent>
          <Switch>
            <Route
              exact
              path={Routes.provision.ADDORG}
              component={AddOrgScreen}
            />
            <Route
              exact
              path={Routes.provision.ADDROOT}
              component={AddBillingRootScreen}
            />
            <Route
              exact
              path={Routes.provision.ADDCUST}
              component={AddCustomerScreen}
            />
            <Route
              exact
              path={Routes.provision.VERIFY}
              component={VerificationScreen}
            />
            <Route exact path={Routes.provision.INDEX}>
              <PageTitle title="Provision">
                <div style={{ display: 'flex', gap: '1rem' }}>
                  <Can
                    requiredRole={Role.deployer}
                    yes={
                      <Button
                        onClick={() => history.push(Routes.provision.ADDROOT)}
                      >
                        + New Billing Root
                      </Button>
                    }
                    no={
                      <Button
                        title="You aren't allowed to create billing roots"
                        style={{
                          opacity: 0.4,
                        }}
                      >
                        + New Billing Root
                      </Button>
                    }
                  />
                  <Can
                    requiredRole={Role.customersuccess}
                    yes={
                      <Button
                        onClick={() => history.push(Routes.provision.VERIFY)}
                        title="Verify Onboarding Status"
                      >
                        Verify Onboarding
                      </Button>
                    }
                    no={
                      <Button
                        title="You aren't allowed to verify provisioned installations"
                        style={{
                          opacity: 0.4,
                        }}
                      >
                        Verify Onboarding
                      </Button>
                    }
                  />
                  <Can
                    requiredRole={Role.deployer}
                    yes={
                      <Button
                        title={
                          numActiveBillingRoots === 0
                            ? 'No available billing roots'
                            : 'Create a customer'
                        }
                        onClick={() =>
                          numActiveBillingRoots > 0 &&
                          history.push(Routes.provision.ADDCUST)
                        }
                        style={{
                          opacity: numActiveBillingRoots === 0 ? 0.4 : 1,
                          cursor:
                            numActiveBillingRoots === 0
                              ? 'not-allowed'
                              : 'pointer',
                        }}
                      >
                        + New Customer
                      </Button>
                    }
                    no={
                      <Button
                        title="You aren't allowed to create customers"
                        style={{
                          opacity: 0.4,
                        }}
                      >
                        + New Customer
                      </Button>
                    }
                  />
                  <Can
                    requiredRole={Role.deployer}
                    yes={
                      <Button
                        title={
                          numActiveBillingRoots === 0
                            ? 'No available billing roots'
                            : 'Create an organisation'
                        }
                        onClick={() =>
                          numActiveBillingRoots > 0 &&
                          history.push(Routes.provision.ADDORG)
                        }
                        style={{
                          opacity: numActiveBillingRoots === 0 ? 0.4 : 1,
                          cursor:
                            numActiveBillingRoots === 0
                              ? 'not-allowed'
                              : 'pointer',
                        }}
                      >
                        + New Organisation
                      </Button>
                    }
                    no={
                      <Button
                        title="You aren't allowed to create organisations"
                        style={{
                          opacity: 0.4,
                        }}
                      >
                        + New Organisation
                      </Button>
                    }
                  />
                </div>
              </PageTitle>

              <h2>Available Billing Roots</h2>
              <BillingRootPoolContainer />

              <h2 className="mt-4">All Billing Roots</h2>
              <BillingRootContainer />
            </Route>
          </Switch>
        </PageContent>
      </React.Fragment>
    )
  },
)

export default withRouter(ProvisionScreen)
