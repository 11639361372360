import React from 'react'
import { WithStyles, Theme, withStyles } from '@material-ui/core'
import { createStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

const styles = ({ spacing, palette }: Theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: spacing(4),
      margin: 0,
      display: 'block',
      // TODO: re-evaluate this design choice
      maxWidth: '100%',
    },
  })

interface Props extends WithStyles<typeof styles> {
  children: React.ReactNode
}

export default withStyles(styles)(({ classes, children }: Props) => (
  <Grid
    className={classes.root}
    container={true}
    direction="column"
    justifyContent="center"
    spacing={10}
    component="main"
  >
    {children}
  </Grid>
))
