import Routes from '../routes'

export type NavLinkName =
  | 'customers'
  | 'aws-support'
  | 'aws-health-events'
  | 'components'
  | 'provision'
  | 'billing'

export type TNavLinks = Array<{
  to: string
  title: string
  name: NavLinkName
}>

export const NavLinks: TNavLinks = [
  {
    to: Routes.customer.INDEX,
    name: 'customers',
    title: 'Customers',
  },
  {
    to: Routes.components.INDEX,
    name: 'components',
    title: 'Components',
  },
  {
    to: Routes.provision.INDEX,
    name: 'provision',
    title: 'Provision',
  },
]
