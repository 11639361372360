import React from 'react'
import {
  createStyles,
  withStyles,
  WithStyles,
  Theme,
} from '@material-ui/core/styles'
import RootStore from 'common/stores/RootStore'

import ReleaseSidebarBody from './ReleaseSidebarBody'
import ReleaseSidebarFooter from './ReleaseSidebarFooter'

import { Observer } from 'mobx-react-lite'

type Props = WithStyles<typeof styles>

const styles = ({ spacing, palette }: Theme) =>
  createStyles({
    root: {
      height: '100%',
      width: spacing(55),
      backgroundColor: '#F2F2F2',
      display: 'flex',
      flexDirection: 'column',
    },
    body: {
      marginTop: spacing(18),
      marginBottom: spacing(40),
      width: '100%',
      overflowY: 'scroll',
      backgroundColor: '#F2F2F2',
      flex: 1,
    },
    footer: {
      backgroundColor: palette.background.paper,
      height: spacing(40),
      width: '100%',
      position: 'absolute',
      zIndex: 400,
      bottom: '0px',
      alignSelf: 'flex-end',
      boxShadow: '0px 0px 15px #a6a6a6',
    },
  })

const ReleaseSidebar: React.FC<Props> = ({ classes }) => {
  const { release } = React.useContext(RootStore)

  return (
    <Observer>
      {() => (
        <div className={classes.root}>
          <div className={classes.body}>
            <ReleaseSidebarBody
              items={release.releaseItems}
              onRemoveItem={release.removeReleaseItem}
            />
          </div>
          <div className={classes.footer}>
            <ReleaseSidebarFooter
              onReleaseButtonClick={release.runRelease}
              numReleaseItems={release.releaseItems.length}
              storeStatus={release.status}
              numReleaseSuccesses={release.numReleaseSuccesses}
              numReleaseFails={release.numReleaseFails}
            />
          </div>
        </div>
      )}
    </Observer>
  )
}

export default withStyles(styles)(ReleaseSidebar)
