import React from 'react'

// TODO: wrap @/common/components/Button instead
import { openInNewTabConfirm, openInNewTab } from 'common/utility/opener'
import { Button, Tooltip, Typography } from '@material-ui/core'
import { ExitToApp } from '@material-ui/icons'
import Can, { Role } from 'common/components/Can'
import { isDev } from 'navigation/constants'

const requiredRoleFor =
  ({
    isBillingRoot,
    organisationAlias,
  }: {
    isBillingRoot: boolean
    organisationAlias: string
  }) =>
  (memberAccountName: string, customerType: string): Role => {
    if (isBillingRoot && customerType !== 'DIRECT') {
      return Role.finance
    } else if (
      organisationAlias === 'juma' &&
      (memberAccountName === 'billing' ||
        memberAccountName.startsWith('billing-central'))
    ) {
      return Role.finance
    } else {
      return Role.customersupport
    }
  }

interface ConfirmButtonProps {
  targetURL: string
  memberAccountName: string
  customerType?: string
  organisationName?: string
  isBillingRoot?: boolean
  organisationAlias?: string
  awsSupportCaseStaxManaged?: boolean
  text?: string
  tooltip?: string
}

export const LoginToAwsButton: React.FC<ConfirmButtonProps> = ({
  targetURL,
  memberAccountName,
  customerType = 'none',
  organisationName = 'none',
  isBillingRoot = false,
  organisationAlias = 'none',
  awsSupportCaseStaxManaged = false,
  text = 'Login',
  tooltip = 'Open in AWS Console',
}) => {
  // confirmation is skipped according to these conditions
  const isConfirmationSkipped = (): boolean => {
    if (isDev()) {
      return true
    } else if (organisationName === 'jumaroot') {
      return true
    } else if (customerType === 'SPAM') {
      return true
    } else if (customerType === 'ECAM' && isBillingRoot) {
      return true
    } else if (customerType === 'DIRECT') {
      return false
    } else if (awsSupportCaseStaxManaged) {
      return true
    }
    return false
  }

  const requiredRole = requiredRoleFor({ isBillingRoot, organisationAlias })

  return (
    <Can
      requiredRole={requiredRole(memberAccountName, customerType)}
      yes={
        <Tooltip title={tooltip}>
          <Button
            onClick={() =>
              isConfirmationSkipped()
                ? openInNewTab(targetURL)
                : openInNewTabConfirm(targetURL)
            }
            style={{
              background: 'transparent',
              color: isConfirmationSkipped() ? 'green' : 'red',
              border: isConfirmationSkipped()
                ? '1px solid green'
                : '1px solid red',
            }}
          >
            {text}{' '}
            <ExitToApp style={{ marginLeft: '4px', fontSize: '1.15rem' }} />
          </Button>
        </Tooltip>
      }
      no={
        <DisabledLoginToAwsButton tooltip="You don't have permission to login to AWS Console" />
      }
    />
  )
}

interface RequestButtonProps {
  memberAccountName: string
  onClick: React.ComponentProps<typeof Button>['onClick']
  customerType?: string
  isBillingRoot?: boolean
  organisationAlias?: string
  text?: string
  tooltip?: string
}
export const RequestLoginToAwsButton: React.FC<RequestButtonProps> = ({
  memberAccountName,
  onClick,
  customerType = 'none',
  isBillingRoot = false,
  organisationAlias = 'none',
  text = 'Request Access',
  tooltip = 'Admin access expired',
}) => {
  const requiredRole = requiredRoleFor({ isBillingRoot, organisationAlias })

  return (
    <Can
      requiredRole={requiredRole(memberAccountName, customerType)}
      yes={
        <Tooltip title={tooltip}>
          <Button
            onClick={onClick}
            style={{
              background: 'transparent',
              color: 'grey',
              border: '1px solid grey',
            }}
          >
            <Typography variant={'caption'} style={{ fontWeight: 'bold' }}>
              {text}
            </Typography>
          </Button>
        </Tooltip>
      }
      no={
        <DisabledLoginToAwsButton tooltip="You don't have permission to login to AWS Console" />
      }
    />
  )
}

interface DisabledButtonProps {
  text?: string
  tooltip: string
}
export const DisabledLoginToAwsButton: React.FC<DisabledButtonProps> = ({
  text = 'Login',
  tooltip,
}) => (
  <Tooltip title={tooltip}>
    <Button
      style={{
        background: 'transparent',
        color: 'grey',
        border: '1px solid grey',
      }}
    >
      {text} <ExitToApp style={{ marginLeft: '4px', fontSize: '1.15rem' }} />
    </Button>
  </Tooltip>
)
