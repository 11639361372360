// type Props = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
type Props = JSX.IntrinsicElements['button']

export const UnstyledButton = ({ children, ...props }: Props) => (
  <button
    style={{ border: 'none', background: 'transparent', cursor: 'pointer' }}
    {...props}
  >
    {children}
  </button>
)
