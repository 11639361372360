import { Fragment, ReactChild } from 'react'
import {
  createStyles,
  withStyles,
  WithStyles,
  Theme,
} from '@material-ui/core/styles'
import { amber } from '@material-ui/core/colors'
import { observer } from 'mobx-react-lite'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import className from 'classnames'
import { teal } from '@material-ui/core/colors'
import { isAfter } from 'date-fns/isAfter'
import { parseISO } from 'date-fns/parseISO'

import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'

import ComponentTime from 'component/components/ComponentTime'
import { Component, Deployment } from 'component/stores/types'
import ComponentVersion from 'component/components/ComponentVersion'
import { Status } from 'common/components/Status'

type Props = RouteComponentProps &
  WithStyles<typeof styles> & {
    children?: ReactChild
    data?: Component
    id?: string
    isLoading: boolean
    active: boolean
    onClick?: (id: string) => void
  }

const styles = ({ palette, spacing }: Theme) =>
  createStyles({
    root: {
      backgroundColor: 'white',
      padding: 0,
      margin: 0,
    },
    wrapper: {
      display: 'flex',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '1rem',
    },
    name: {
      fontWeight: 600,
      color: palette.grey[800],
    },
    active: {
      backgroundColor: `${palette.grey[100]} !important`,
    },
    divider: {
      width: '4px',
    },
    primary: {
      background: palette.primary.main,
    },
    secondary: {
      background: 'transparent',
    },
    time: {
      fontSize: '0.75rem',
    },
    statusContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: spacing(1),
    },
    statusBadge: {
      display: 'flex',
      alignItems: 'center',
      padding: spacing(1),
      fontSize: '0.75rem',
      height: spacing(3),
      borderRadius: '4px',
      background: amber[500],
      color: 'white',
      fontWeight: 'bold',
    },
    badge: {
      display: 'flex',
      alignItems: 'center',
      padding: spacing(1),
      fontSize: '0.75rem',
      height: spacing(3),
      borderRadius: '4px',
      background: teal.A400,
      color: 'white',
      fontWeight: 'bold',
    },
  })

const failing = 'FAILING'

const getUpdates = (data: Component | undefined) => {
  const availableDeployments = data?.availableDeployments ?? []
  const activeDeployment = data?.activeDeployment

  if (activeDeployment) {
    return availableDeployments.filter((deployment: Deployment) =>
      isAfter(
        parseISO(deployment.createdTS),
        parseISO(activeDeployment.createdTS),
      ),
    )
  }
  return []
}

const ComponentListItem = observer((props: Props) => {
  const { classes, data, active, isLoading, onClick } = props

  const updates = getUpdates(data)

  const handleOnClick = () => {
    if (onClick && data) {
      onClick(data.name)
    }
  }
  return (
    <ListItem
      classes={{ root: classes.root, selected: classes.active }}
      selected={active}
      button
      onClick={handleOnClick}
      component="li"
      divider
      disabled={isLoading}
    >
      <div className={classes.wrapper}>
        <div
          className={className(classes.divider, {
            [classes.primary]: active,
            [classes.secondary]: !active,
          })}
        >
          &nbsp;
        </div>

        <div className={classes.content}>
          {isLoading && !data && <Typography>Loading...</Typography>}
          {
            <Fragment>
              {data && data.name && (
                <Typography className={classes.name} variant="h6">
                  {data.name}
                </Typography>
              )}

              {data && data.activeDeployment ? (
                <ComponentVersion
                  type="GitHash"
                  version={data.activeDeployment.version}
                />
              ) : isLoading ? (
                <Typography>Loading...</Typography>
              ) : (
                <Typography variant="caption">No active deployment</Typography>
              )}

              {data && data.activeDeployment && (
                <ComponentTime
                  className={classes.time}
                  time={data.activeDeployment.modifiedTS}
                />
              )}
              <div className={classes.statusContainer}>
                {updates.length > 0 && (
                  <span className={classes.badge}>Update Available</span>
                )}
                {data && !data.deployPassing && <Status status={failing} />}
                {data && data.locked && (
                  <div className={classes.statusBadge} children={'LOCKED'} />
                )}
              </div>
            </Fragment>
          }
        </div>
      </div>
    </ListItem>
  )
})

export default withStyles(styles)(withRouter(ComponentListItem))
