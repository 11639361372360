import React from 'react'
import { observer } from 'mobx-react-lite'

import RootStore from 'common/stores/RootStore'
import NoResults from 'common/components/NoResults'
import { Workload } from 'workload/stores/types'
import { Status } from '../../common/components/Status'
import { Table, TableBody, TableCell, TableHead } from '@material-ui/core'
import TableRow from '@material-ui/core/TableRow'
import Loading from 'common/components/Loading'
import { ClickToCopy } from 'common/components/ClickToCopy'

const headers = [
  { title: 'Name' },
  { title: 'Modified' },
  { title: 'Status' },
  { title: 'ID' },
  { title: 'AccountId' },
  { title: 'Region' },
]

const getTableRows = (workloads: Workload[]) => {
  return workloads.map((item) => ({
    key: item.id,
    cells: [
      item.name,
      item.modifiedTS,
      <Status key="status" status={item.status} />,
      <React.Fragment key={item.id}>
        {item.id} <ClickToCopy text={item.id} />
      </React.Fragment>,
      item.accountId,
      item.region,
    ],
  }))
}

const WorkloadsContainer = observer(() => {
  const { workload, organisation } = React.useContext(RootStore)

  React.useEffect(() => {
    if (organisation.current) {
      workload.fetch(organisation.current.id)
    }
  }, [organisation.current])

  if (!organisation.current) {
    return <NoResults title="No organisation selected" />
  }

  const workloads = workload.list(organisation.current.id)
  const rows = getTableRows(workloads)

  const loading = organisation.isLoading || workload.isLoading

  if (loading) return <Loading />

  return (
    <Table>
      <TableHead>
        <TableRow>
          {headers.map((header) => (
            <TableCell key={header.title}>{header.title}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row) => (
          <TableRow key={row.key}>
            {row.cells.map((cell, i) => (
              <TableCell key={i}>{cell}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
})

export default WorkloadsContainer
