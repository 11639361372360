import {
  action,
  observable,
  computed,
  ObservableMap,
  makeObservable,
} from 'mobx'
import { OrgId } from 'account/stores/types'
import { CatalogueApi } from 'catalogue/stores/CatalogueApi'
import { RootStore } from 'common/stores/RootStore'
import { Status } from 'common/api'

import { WorkloadCatalogue } from 'catalogue/stores/types'

/* CatalogueStore queries the API for a Workload Catalogue for a chosen
 * Stax Organisation. The API returns a list of Workloads the constitutes the catalogue
 */

export default class CatalogueStore {
  constructor(
    root: RootStore,
    api: CatalogueApi,
    endpoint = '/workload-catalogue',
  ) {
    makeObservable(this, {
      catalogue: observable,
      fetch: action,
      isLoading: computed,
    })

    this.root = root
    this.api = api
    this.endpoint = endpoint
  }

  root: RootStore
  // Separate out concrete API calls to make it easier to
  // test the stores in isolation
  api: CatalogueApi
  endpoint: string

  error: Error | undefined
  status: Status = Status.Idle

  catalogue: ObservableMap<OrgId, WorkloadCatalogue[]> = observable.map()

  fetch = async (orgId: OrgId, force = false) => {
    try {
      this.status = Status.Pending
      const res = await this.api.fetch(`${this.endpoint}/${orgId}`, force)
      this.catalogue.set(orgId, res.data.workloadCatalogue)
      this.status = Status.Success
    } catch (err: any) {
      this.status = Status.Error
      this.error = err
    }
  }

  get isLoading() {
    return this.status === Status.Pending
  }

  list = (orgId: OrgId) => {
    return this.catalogue.get(orgId) || []
  }
}
