import React, { Fragment } from 'react'

import PageTitle from 'common/components/PageTitle'
import PageContent from 'common/components/PageContent'
import DashboardContainer from 'dashboard/containers/DashboardContainer'

interface DashboardScreenProps {
  classes: any
}

class DashboardScreen extends React.Component<DashboardScreenProps, {}> {
  render() {
    return (
      <Fragment>
        <PageTitle title="Dashboard" />
        <PageContent>
          <DashboardContainer />
        </PageContent>
      </Fragment>
    )
  }
}

export default DashboardScreen
