import React from 'react'
import RootStore from 'common/stores/RootStore'
import { Status } from 'component/stores/types'
import { Button, Typography } from '@material-ui/core'

const IDLE_TIMEOUT = 10000

type Props = {
  userId: string
  organisationId: string
  disabled: boolean
  text: string
}

const ComponentSendVerifyEmail = ({
  userId,
  organisationId,
  disabled,
  text,
  ...restProps
}: Props) => {
  const { user: userStore } = React.useContext(RootStore)
  const [status, setStatus] = React.useState<Status>('IDLE')

  const handleSendVerifyEmail = (userId: string) => {
    setStatus('PENDING')
    userStore
      .sendVerifyEmail(organisationId, userId)
      .then(() => setStatus('SUCCESS'))
      .catch(() => setStatus('ERROR'))
      .finally(() => setTimeout(() => setStatus('IDLE'), IDLE_TIMEOUT))
  }

  if (status === 'PENDING') {
    return <Button disabled>Loading...</Button>
  }

  return (
    <Button
      onClick={() => handleSendVerifyEmail(userId)}
      disabled={disabled}
      style={{
        opacity: disabled ? 0.4 : 1,
      }}
    >
      <Typography variant={'caption'} style={{ fontWeight: 'bold' }}>
        {status === 'ERROR' ? 'Error!' : text}
      </Typography>
    </Button>
  )
}

export default ComponentSendVerifyEmail
