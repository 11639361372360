import React from 'react'
import { observer } from 'mobx-react-lite'

import {
  Paper,
  Tooltip,
  Box,
  styled,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core'
import Select from '@material-ui/core/Select'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Typography, {
  TypographyProps,
} from '@material-ui/core/Typography/Typography'
import { useHistory, useRouteMatch } from 'react-router'
import { Link } from '@material-ui/core'
import RootStore from 'common/stores/RootStore'
import FactoryButton from 'customer/components/FactoryButton'
import NoResults from 'common/components/NoResults'
import Can, { Role } from 'common/components/Can'
import LocalUserInviteSwitch from '../components/LocalUserInviteSwitch'
import OrganisationStore from '../stores/OrganisationStore'
import ProvisionRoutes from '../../provision/routes'
import { ClickToCopy } from 'common/components/ClickToCopy'
import routes from 'customer/routes'
import { CUSTOMER_SUPPORT_PLANS } from '../../constants'
import { Customer } from 'customer/stores/types'

type Props = {
  customerId: string
  organisationId?: string
}

const ValueWrapper = styled(Box)({
  marginRight: '1.5rem',
})

const fetchData = async (
  customerId: string,
  store: OrganisationStore,
  organisationId?: string,
) => {
  await store.fetch(customerId)
  if (organisationId) {
    store.setCurrent(customerId, organisationId)
  } else {
    store.setDefaultOrg(customerId)
  }
}

export const getCustomerSupportPlan = (
  customer: Customer | undefined,
): CUSTOMER_SUPPORT_PLANS => {
  const plan = Object.values(CUSTOMER_SUPPORT_PLANS).find(
    (x) => x === customer?.supportPlan,
  )
  return plan || CUSTOMER_SUPPORT_PLANS.STARTER
}

const OrganisationListAndDetailsContainer = observer(
  ({ customerId, organisationId }: Props) => {
    const {
      account: accountStore,
      customer: customerStore,
      organisation,
      billingroot,
    } = React.useContext(RootStore)

    React.useEffect(() => {
      customerStore.fetch()

      billingroot.fetch('UNUSED')

      fetchData(customerId, organisation, organisationId)

      return () => organisation.reset()
    }, [])

    const history = useHistory()
    const match = useRouteMatch<{ id?: string; orgId?: string }>()

    const handleChangeOrg: React.ComponentProps<typeof Select>['onChange'] = (
      event,
    ) => {
      organisation.setCurrent(customerId, event.currentTarget.value as string)
      history.push(`${routes.INDEX}/${customerId}`)
    }

    const customer = customerStore.active

    const runSaga = () => organisation.invokeSaga(organisation.current!.id)
    const runOrgValidation = () =>
      organisation.runOrgValidation(organisation.current!.id)
    const runAllAccountsSaga = () =>
      organisation.runAllAccountsSaga(organisation.current!.id)

    const Label = ({ children, ...rest }: TypographyProps) => (
      <Typography component="span" variant="overline" {...rest}>
        {children} <br />
      </Typography>
    )

    const Value = ({ children, ...rest }: TypographyProps) => (
      <Typography component="span" style={{ fontWeight: 500 }} {...rest}>
        {children} <br />
      </Typography>
    )

    if (organisation.isLoading) return <span>Loading...</span>
    if (!organisation.current) {
      const orgs = organisation.list(customerId).map((org) => (
        <option value={org.id} key={org.id}>
          {org.name}
        </option>
      ))
      orgs.splice(
        0,
        0,
        <option value={'none'} key={'none'}>
          {'No org selected'}
        </option>,
      )
      return (
        <React.Fragment>
          <Paper
            style={{ width: '100%', marginBottom: '1rem', padding: '1rem' }}
          >
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-customer-details-content"
                id="panel-customer-details-header"
              >
                <Typography variant="h4">Customer Details</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box display="flex" flexDirection="row" flexWrap="wrap">
                  <ValueWrapper>
                    <Label>Customer Name</Label>
                    <Value>{customer?.name || '—'}</Value>
                  </ValueWrapper>
                  <ValueWrapper>
                    <Label>Company Name</Label>
                    <Value>{customer?.companyName}</Value>
                  </ValueWrapper>
                  <ValueWrapper>
                    <Label>Customer ID</Label>
                    <Value>
                      {customer?.id || '—'} <ClickToCopy text={customer?.id} />
                    </Value>
                  </ValueWrapper>
                  <ValueWrapper>
                    <Label>Customer Email</Label>
                    <Value>
                      {customer?.email || '—'}{' '}
                      <ClickToCopy text={customer?.email} />
                    </Value>
                  </ValueWrapper>
                  <ValueWrapper>
                    <Label>Status</Label>
                    <Value>{customer?.status || '—'}</Value>
                  </ValueWrapper>
                  <ValueWrapper>
                    <Label>Support Plan</Label>
                    <Value>{getCustomerSupportPlan(customer)}</Value>
                  </ValueWrapper>
                  <ValueWrapper>
                    <Label>Type</Label>
                    <Value>
                      <Link
                        href="https://stax.atlassian.net/wiki/spaces/JUMA/pages/551387149/Stax+Customer+Types+Summary"
                        target="_blank"
                      >
                        {customer?.type || '—'}
                      </Link>
                    </Value>
                  </ValueWrapper>
                  <ValueWrapper>
                    <Label>Internal Customer</Label>
                    <Value>
                      {customer?.internal ? 'Internal' : 'External'}
                    </Value>
                  </ValueWrapper>
                  <ValueWrapper>
                    <Label>Factory Version</Label>
                    <Value>{customer?.factoryVersion}</Value>
                  </ValueWrapper>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Box display="flex" alignItems="flex-end">
              <Box width="12rem">
                <Typography variant="overline">Organisation</Typography>
                <Select
                  fullWidth
                  native
                  variant="outlined"
                  onChange={handleChangeOrg}
                  value={'No Org selected'}
                  input={<OutlinedInput labelWidth={0} />}
                  inputProps={{
                    style: {
                      paddingTop: '0.7rem',
                      paddingBottom: '0.7rem',
                    },
                  }}
                >
                  {orgs}
                </Select>
              </Box>
            </Box>
            <Box>
              <NoResults title="No organisation selected" />
            </Box>
          </Paper>
        </React.Fragment>
      )
    }
    const accounts = accountStore.list(organisation.current.id)
    let hasExternalBillingRoot = false
    for (let i = 0; i < accounts.length; i++) {
      if (!accounts[i].billingAccountId && accounts[i].origin === 'EXTERNAL') {
        hasExternalBillingRoot = true
        break
      }
    }

    const numDiscoveredAccounts = accounts.reduce((currCount, account) => {
      if (account.status === 'DISCOVERED') return currCount + 1
      return currCount
    }, 0)
    const numActiveAccounts = accounts.reduce((currCount, account) => {
      if (account.status === 'ACTIVE') return currCount + 1
      return currCount
    }, 0)

    const numActiveBillingRoots = billingroot.poolList().length

    const enableLocalUserInvite: string =
      organisation
        .listConfig(organisation.current.id)
        .find((config) => config.key === 'EnableLocalUserInvite')?.value || ''

    return (
      <React.Fragment>
        <Paper style={{ width: '100%', marginBottom: '1rem', padding: '1rem' }}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel-customer-details-content"
              id="panel-customer-details-header"
            >
              <Typography variant="h4">Customer Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box display="flex" flexDirection="row" flexWrap="wrap">
                <ValueWrapper>
                  <Label>Customer Name</Label>
                  <Value>{customer?.name || '—'}</Value>
                </ValueWrapper>
                <ValueWrapper>
                  <Label>Company Name</Label>
                  <Value>{customer?.companyName}</Value>
                </ValueWrapper>
                <ValueWrapper>
                  <Label>Customer ID</Label>
                  <Value>
                    {customer?.id || '—'} <ClickToCopy text={customer?.id} />
                  </Value>
                </ValueWrapper>
                <ValueWrapper>
                  <Label>Customer Email</Label>
                  <Value>
                    {customer?.email || '—'}{' '}
                    <ClickToCopy text={customer?.email} />
                  </Value>
                </ValueWrapper>
                <ValueWrapper>
                  <Label>Status</Label>
                  <Value>{customer?.status || '—'}</Value>
                </ValueWrapper>
                <ValueWrapper>
                  <Label>Support Plan</Label>
                  <Value>{getCustomerSupportPlan(customer)}</Value>
                </ValueWrapper>
                <ValueWrapper>
                  <Label>Type</Label>
                  <Value>
                    <Link
                      href="https://stax.atlassian.net/wiki/spaces/JUMA/pages/551387149/Stax+Customer+Types+Summary"
                      target="_blank"
                    >
                      {customer?.type || '—'}
                    </Link>
                  </Value>
                </ValueWrapper>
                <ValueWrapper>
                  <Label>Internal Customer</Label>
                  <Value>{customer?.internal ? 'Internal' : 'External'}</Value>
                </ValueWrapper>
                <ValueWrapper>
                  <Label>Factory Version</Label>
                  <Value>{customer?.factoryVersion}</Value>
                </ValueWrapper>
              </Box>
            </AccordionDetails>
          </Accordion>

          <Box display="flex" alignItems="flex-end">
            <Box width="12rem">
              <Typography variant="overline">Organisation</Typography>
              <Select
                fullWidth
                native
                variant="outlined"
                onChange={handleChangeOrg}
                value={organisation.current && organisation.current.id}
                input={<OutlinedInput labelWidth={0} />}
                inputProps={{
                  style: {
                    paddingTop: '0.7rem',
                    paddingBottom: '0.7rem',
                  },
                }}
              >
                {organisation.list(customerId).map((org) => (
                  <option value={org.id} key={org.id}>
                    {org.name}
                  </option>
                ))}
              </Select>
            </Box>

            <Box>
              <Can
                requiredRole={Role.deployer}
                yes={
                  <div style={{ display: 'flex' }}>
                    {customer && customer.email && (
                      <React.Fragment>
                        <Button
                          title={
                            numActiveBillingRoots === 0
                              ? 'No available billing roots'
                              : 'Create an organisation'
                          }
                          onClick={() =>
                            numActiveBillingRoots > 0 &&
                            history.push({
                              pathname: ProvisionRoutes.ADDORG,
                              state: {
                                customerName: customer && customer.name,
                                customerId: match.params.id,
                              },
                            })
                          }
                          style={{
                            opacity: numActiveBillingRoots === 0 ? 0.4 : 1,
                            cursor:
                              numActiveBillingRoots === 0
                                ? 'not-allowed'
                                : 'pointer',
                            marginLeft: '0.5rem',
                          }}
                        >
                          + Add Organisation
                        </Button>
                      </React.Fragment>
                    )}
                  </div>
                }
              />
            </Box>
          </Box>
        </Paper>

        <Box display="flex" mb="1rem">
          <Paper style={{ width: '60%', marginRight: '1rem', padding: '1rem' }}>
            <Typography variant="h4">Organisation Details</Typography>

            <Box display="flex" flexDirection="row" flexWrap="wrap">
              <ValueWrapper>
                <Label>Name</Label>
                <Value>{organisation.current.name}</Value>
              </ValueWrapper>
              <ValueWrapper>
                <Label>Alias</Label>
                <Value>{organisation.current.alias}</Value>
              </ValueWrapper>
              <ValueWrapper>
                <Label>Org Id</Label>
                <Value>
                  {organisation.current.id}{' '}
                  <ClickToCopy text={organisation.current.id} />
                </Value>
              </ValueWrapper>
              <ValueWrapper>
                <Label>Billing Account ID</Label>
                <Value>
                  {organisation.current.billingAccountId}{' '}
                  <ClickToCopy text={organisation.current.billingAccountId} />
                </Value>
              </ValueWrapper>
              <ValueWrapper>
                <Label>Salesforce ID</Label>
                <Value>
                  {organisation.current.salesforceId || '—'}{' '}
                  <ClickToCopy text={organisation.current.salesforceId} />
                </Value>
              </ValueWrapper>
              <ValueWrapper>
                <Label>Spotlight ID</Label>
                <Value>
                  {organisation.current.externalStaxId}{' '}
                  <ClickToCopy text={organisation.current.externalStaxId} />
                </Value>
              </ValueWrapper>
              <ValueWrapper>
                <Label>Status</Label>
                <Value>{organisation.current.status}</Value>
              </ValueWrapper>
              <ValueWrapper>
                <Label>Compliance Type</Label>
                <Value>
                  <span
                    style={
                      organisation.current.complianceType
                        ? {
                            color: 'red',
                          }
                        : {}
                    }
                  >
                    {organisation.current.complianceType || 'None'}
                  </span>
                </Value>
              </ValueWrapper>
              <ValueWrapper>
                <Label>AWS Support Plan</Label>
                <Value>{organisation.current.awsSupportType}</Value>
              </ValueWrapper>
              <ValueWrapper>
                <Label>AWS Partner Support</Label>
                <Value>
                  {organisation.current.awsPartnerSupport ? 'Yes' : 'No'}
                </Value>
              </ValueWrapper>
              <ValueWrapper>
                <Label>External Billing Root</Label>
                <Value>{hasExternalBillingRoot ? 'Yes' : 'No'}</Value>
              </ValueWrapper>
              <ValueWrapper>
                <Label>Factory Version</Label>
                <Value>
                  {organisation.current.factoryVersion}{' '}
                  <ClickToCopy text={organisation.current.factoryVersion} />
                </Value>
              </ValueWrapper>
              <ValueWrapper>
                <Label>Allowed domains</Label>
                <Value>{organisation.current.allowedDomains || '—'}</Value>
              </ValueWrapper>
              <ValueWrapper>
                <Label>Compliance Type</Label>
                <Value>{organisation.current.complianceType || '—'}</Value>
              </ValueWrapper>
              <ValueWrapper>
                <Label>Marketplace Enabled?</Label>
                <Value>
                  {organisation.current.marketplaceSignup ? 'Yes' : 'No'}
                </Value>
              </ValueWrapper>
              <ValueWrapper>
                <Label>AWS Partner Support?</Label>
                <Value>
                  {organisation.current.awsPartnerSupport ? 'Yes' : 'No'}
                </Value>
              </ValueWrapper>
              <ValueWrapper>
                <Label>AWS Email Template</Label>
                <Value>
                  {organisation.current.awsAccountEmailTemplate || '—'}{' '}
                  <ClickToCopy
                    text={organisation.current.awsAccountEmailTemplate}
                  />
                </Value>
              </ValueWrapper>
              <ValueWrapper>
                <Label>Local User Invite Enabled?</Label>
                <Value>
                  <LocalUserInviteSwitch
                    enabled={enableLocalUserInvite.toLowerCase() !== 'false'}
                    orgId={organisation.current.id}
                  />
                </Value>
              </ValueWrapper>
              <ValueWrapper>
                <Label>Regions</Label>
                <Value>{organisation.current.regions.join(', ') || '—'}</Value>
              </ValueWrapper>
              <ValueWrapper>
                <Label>Features</Label>
                <Value>
                  <List disablePadding>
                    {Object.entries(organisation.current.features ?? {}).map(
                      ([feature, value]) => (
                        <ListItem key={feature} style={{ padding: '0' }}>
                          <ListItemText
                            style={{ margin: '0' }}
                            primary={feature}
                            secondary={value.toString()}
                          />
                        </ListItem>
                      ),
                    )}
                  </List>
                </Value>
              </ValueWrapper>
            </Box>
          </Paper>

          <Paper style={{ width: '40%', padding: '1rem' }}>
            <Box display="flex" flexDirection="row" flexWrap="wrap">
              <ValueWrapper>
                <Box mb="0.5rem">
                  <Label>Org Factory Version</Label>
                  <Value>{organisation.current.factoryVersion}</Value>
                </Box>

                <Tooltip
                  arrow
                  title="Updates the Organization and runs Account Assurance over the Management, Security and Logging Accounts"
                >
                  <span>
                    <Can
                      requiredRole={Role.deployer}
                      yes={
                        <FactoryButton onClick={runSaga}>
                          <Typography
                            variant={'caption'}
                            style={{ fontWeight: 'bold' }}
                          >
                            Run Org Saga
                          </Typography>
                        </FactoryButton>
                      }
                      no={
                        <FactoryButton disabled>
                          <Typography
                            variant={'caption'}
                            style={{ fontWeight: 'bold' }}
                          >
                            Run Org Saga
                          </Typography>
                        </FactoryButton>
                      }
                    />
                  </span>
                </Tooltip>

                <Box mt="0.5rem">
                  <Tooltip arrow title="Run Org Validation">
                    <span>
                      <Can
                        requiredRole={Role.deployer}
                        yes={
                          <FactoryButton onClick={runOrgValidation}>
                            <Typography
                              variant={'caption'}
                              style={{
                                fontWeight: 'bold',
                              }}
                            >
                              Run Org Validation
                            </Typography>
                          </FactoryButton>
                        }
                        no={
                          <FactoryButton disabled>
                            <Typography
                              variant={'caption'}
                              style={{
                                fontWeight: 'bold',
                              }}
                            >
                              Run Org Validation
                            </Typography>
                          </FactoryButton>
                        }
                      />
                    </span>
                  </Tooltip>
                </Box>
              </ValueWrapper>
              <ValueWrapper>
                <Box mb="0.5rem">
                  <Label>Stax Accounts</Label>
                  <Value>{`${numActiveAccounts} active accounts`}</Value>
                </Box>

                <Tooltip
                  arrow
                  title="Runs Account Assurance over all active Accounts (except for the Management, Security and Logging Accounts). If you are running this for multiple Orgs, it is best to only run 5 Orgs at a time."
                >
                  <span>
                    <Can
                      requiredRole={Role.deployer}
                      yes={
                        <FactoryButton onClick={runAllAccountsSaga}>
                          <Typography
                            variant={'caption'}
                            style={{ fontWeight: 'bold' }}
                          >
                            Run All Accounts Saga
                          </Typography>
                        </FactoryButton>
                      }
                      no={
                        <FactoryButton
                          disabled={true}
                          onClick={runAllAccountsSaga}
                        >
                          <Typography
                            variant={'caption'}
                            style={{ fontWeight: 'bold' }}
                          >
                            Run All Accounts Saga
                          </Typography>
                        </FactoryButton>
                      }
                    />
                  </span>
                </Tooltip>
              </ValueWrapper>
              <ValueWrapper>
                <Box mb="0.5rem">
                  <Label>Stax account discovery</Label>
                  <Value>{`${numDiscoveredAccounts} discovered accounts`}</Value>
                </Box>

                <Tooltip
                  arrow
                  title="Checks if there are any Accounts within the Organization that have not been onboarded to Stax and presents them to the customer as 'Discovered' within the Stax Console"
                >
                  <span>
                    <Can
                      requiredRole={Role.customersupport}
                      yes={
                        <FactoryButton
                          onClick={() =>
                            organisation.rediscoverAllAccounts(
                              organisation.current!.id,
                            )
                          }
                        >
                          <Typography
                            variant={'caption'}
                            style={{ fontWeight: 'bold' }}
                          >
                            Run discovery
                          </Typography>
                        </FactoryButton>
                      }
                      no={
                        <FactoryButton
                          disabled={true}
                          onClick={() =>
                            organisation.rediscoverAllAccounts(
                              organisation.current!.id,
                            )
                          }
                        >
                          <Typography
                            variant={'caption'}
                            style={{ fontWeight: 'bold' }}
                          >
                            Run discovery
                          </Typography>
                        </FactoryButton>
                      }
                    />
                  </span>
                </Tooltip>
              </ValueWrapper>

              <ValueWrapper>
                <Box mb="0.5rem">
                  <Label>Stax Policies</Label>
                </Box>

                <Tooltip
                  arrow
                  title="Applys the Stax Policies to the customers AWS Organization"
                >
                  <span>
                    <Can
                      requiredRole={Role.admin}
                      yes={
                        <FactoryButton
                          onClick={() =>
                            organisation.runApplyStaxPolicies(
                              organisation.current!.id,
                            )
                          }
                        >
                          <Typography
                            variant={'caption'}
                            style={{ fontWeight: 'bold' }}
                          >
                            Apply Stax Policies
                          </Typography>
                        </FactoryButton>
                      }
                      no={
                        <FactoryButton
                          disabled={true}
                          onClick={() =>
                            organisation.runApplyStaxPolicies(
                              organisation.current!.id,
                            )
                          }
                        >
                          <Typography
                            variant={'caption'}
                            style={{ fontWeight: 'bold' }}
                          >
                            Apply Stax Policies
                          </Typography>
                        </FactoryButton>
                      }
                    />
                  </span>
                </Tooltip>

                <Box mt="0.5rem">
                  <Tooltip
                    arrow
                    title="Syncs the customers policies from AWS to Stax"
                  >
                    <span>
                      <Can
                        requiredRole={Role.admin}
                        yes={
                          <FactoryButton
                            onClick={() =>
                              organisation.runPolicySync(
                                organisation.current!.id,
                              )
                            }
                          >
                            <Typography
                              variant={'caption'}
                              style={{ fontWeight: 'bold' }}
                            >
                              Sync AWS Policies to Stax
                            </Typography>
                          </FactoryButton>
                        }
                        no={
                          <FactoryButton
                            disabled={true}
                            onClick={() =>
                              organisation.runPolicySync(
                                organisation.current!.id,
                              )
                            }
                          >
                            <Typography
                              variant={'caption'}
                              style={{ fontWeight: 'bold' }}
                            >
                              Sync AWS Policies to Stax
                            </Typography>
                          </FactoryButton>
                        }
                      />
                    </span>
                  </Tooltip>
                </Box>
              </ValueWrapper>

              <ValueWrapper>
                <Box mb="0.5rem">
                  <Label>Stax Organisational Units</Label>
                </Box>

                <Tooltip arrow title="Syncs the customers OUs from AWS to Stax">
                  <span>
                    <Can
                      requiredRole={Role.admin}
                      yes={
                        <FactoryButton
                          onClick={() =>
                            organisation.runOrganisationalUnitSync(
                              organisation.current!.id,
                            )
                          }
                        >
                          <Typography
                            variant={'caption'}
                            style={{ fontWeight: 'bold' }}
                          >
                            Sync AWS OUs to Stax
                          </Typography>
                        </FactoryButton>
                      }
                      no={
                        <FactoryButton
                          disabled={true}
                          onClick={() =>
                            organisation.runOrganisationalUnitSync(
                              organisation.current!.id,
                            )
                          }
                        >
                          <Typography
                            variant={'caption'}
                            style={{ fontWeight: 'bold' }}
                          >
                            Sync AWS OUs to Stax
                          </Typography>
                        </FactoryButton>
                      }
                    />
                  </span>
                </Tooltip>
              </ValueWrapper>
            </Box>
          </Paper>
        </Box>
      </React.Fragment>
    )
  },
)

export default OrganisationListAndDetailsContainer
