import React from 'react'
import { observer } from 'mobx-react-lite'

import RootStore from 'common/stores/RootStore'
import NoResults from 'common/components/NoResults'
import { WorkloadCatalogue } from 'catalogue/stores/types'
import { Status } from 'common/components/Status'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import Loading from 'common/components/Loading'
import { ClickToCopy } from 'common/components/ClickToCopy'

const headers = [{ title: 'Name' }, { title: 'Status' }, { title: 'ID' }]

const getTableRows = (items: WorkloadCatalogue[]) => {
  return items.map((item) => ({
    key: item.id,
    cells: [
      item.name,
      <Status key="status" status={item.status} />,
      <React.Fragment key={item.id}>
        {item.id} <ClickToCopy text={item.id} />
      </React.Fragment>,
    ],
  }))
}

const CatalogueContainer = observer(() => {
  const { catalogue, organisation } = React.useContext(RootStore)

  React.useEffect(() => {
    if (organisation.current) {
      catalogue.fetch(organisation.current.id)
    }
  }, [organisation.current])

  if (!organisation.current) {
    return <NoResults title="No organisation selected" />
  }

  const items = catalogue.list(organisation.current.id)
  const rows = getTableRows(items)

  if (organisation.isLoading || catalogue.isLoading) return <Loading />

  return (
    <Table>
      <TableHead>
        <TableRow>
          {headers.map((header, index) => (
            <TableCell key={index}>{header.title}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row, index) => (
          <TableRow key={index}>
            {row.cells.map((cell, index) => (
              <TableCell key={index}>{cell}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
})

export default CatalogueContainer
