import React from 'react'
import { observer } from 'mobx-react-lite'
import { RouteComponentProps } from 'react-router'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'

import RootStore from 'common/stores/RootStore'
import NoResults from 'common/components/NoResults'
import { User } from 'user/stores/types'
import { Organisation } from 'organisation/stores/types'
import Can, { Role } from 'common/components/Can'
import ComponentSendVerifyEmail from '../components/ComponentSendVerifyEmail'
import Loading from 'common/components/Loading'
import { ClickToCopy } from 'common/components/ClickToCopy'

const headers = [
  { title: 'Name' },
  { title: 'Email' },
  { title: 'Federated' },
  { title: 'MFA' },
  { title: 'Enabled' },
  { title: 'Email Verified' },
]

const UsersContainer: React.FC<RouteComponentProps> = observer(
  ({ history, match }) => {
    const { user: userStore, organisation: organisationStore } =
      React.useContext(RootStore)

    React.useEffect(() => {
      if (organisationStore.current) {
        userStore.fetch(organisationStore.current.id)
      }
    }, [organisationStore.current])

    if (!organisationStore.current) {
      return <NoResults title="No organisation selected" />
    }

    if (organisationStore.current.status === 'SUSPENDED') {
      return <NoResults title="Organisation is suspended" />
    }

    const users = userStore.list(organisationStore.current.id)
    const organisationId = (organisationStore.current as Organisation).id

    if (users.length === 0) {
      return <NoResults title="No users found" />
    }

    const getTableRows = (users: User[]) => {
      const renderButton = (user: User, disabled = false) => {
        let text = 'Re-send'
        if (user.emailVerified || !user.enabled || user.federated) {
          text = user.emailVerified ? 'Verified' : 'Not Verified'
          disabled = true
        }

        return (
          <ComponentSendVerifyEmail
            userId={user.id}
            organisationId={organisationId}
            disabled={disabled}
            text={text}
          />
        )
      }

      return users.map((user) => ({
        key: user.id,
        cells: [
          <React.Fragment key={user.id}>
            {user.firstName} {user.lastName} <br />
            {user.id} <ClickToCopy text={user.id} />
          </React.Fragment>,
          <React.Fragment key={user.email}>
            {user.email} <ClickToCopy text={user.email} />
          </React.Fragment>,
          // TODO: "non-federated" or "user"?
          user.federated ? 'Federated' : 'User',
          user.mfa ? 'Enabled' : 'Disabled',
          user.enabled ? 'Enabled' : 'Disabled',
          <Can
            key={user.id}
            requiredRole={Role.customersuccess}
            yes={renderButton(user)}
            no={renderButton(user, true)}
          />,
        ],
      }))
    }

    const rows = getTableRows(users)

    if (organisationStore.isLoading || userStore.isLoading) return <Loading />

    return (
      <Table>
        <TableHead>
          <TableRow>
            {headers.map((header, index) => (
              <TableCell key={index}>{header.title}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index}>
              {row.cells.map((cell, index) => (
                <TableCell key={index}>{cell}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    )
  },
)

export default UsersContainer
