import { ThemeOptions } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'

const themeConfig: ThemeOptions = {
  palette: {
    background: {
      default: grey[50],
    },
    primary: {
      main: '#0A7BA7',
    },
    secondary: {
      main: '#00CCCF',
    },
  },
  typography: {
    fontFamily:
      "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        backgroundColor: 'transparent',
        border: '1px solid #0A7BA7',
        color: '#0A7BA7',
        '&:hover, &:disabled': {
          color: '#0A7BA7',
          backgroundColor: 'transparent',
        },
      },
    },
    MuiStepIcon: {
      active: {
        color: '#00CCCF !important',
      },
    },
    MuiSwitch: {
      colorSecondary: {
        '&$checked$disabled  $thumb': {
          color: '#A1E5E5 !important',
        },
        '&$checked$disabled + $track': {
          backgroundColor: '#00CCCF !important',
        },
      },
    },
    MuiTypography: {
      h1: {
        fontSize: '2.3rem',
        fontWeight: 500,
      },
      h2: {
        fontSize: '1.2rem',
        fontWeight: 600,
        marginBottom: '0.5rem',
      },
      h3: {
        fontSize: '1.15rem',
        fontWeight: 600,
      },
      h4: {
        fontWeight: 600,
        fontSize: '0.95rem',
      },
      h6: {
        fontSize: '0.95rem',
      },
      body1: {
        fontSize: '0.875rem',
      },
      overline: {
        fontSize: '0.75rem',
        fontWeight: 500,
        color: grey[500],
        marginBottom: '0.5rem',
      },
    },
    MuiAccordionSummary: {
      root: {
        padding: 0,

        '&.Mui-expanded': {
          minHeight: '48px',
        },
      },
      content: {
        margin: '0.25rem 0',

        '&.Mui-expanded': {
          margin: '0.5rem 0',
        },
      },
    },
    MuiAccordionDetails: {
      root: {
        padding: 0,
      },
    },
  },
  props: {
    // @ts-ignore
    MuiTextField: {
      variant: 'outlined',
    },
    MuiButton: {
      variant: 'contained',
      color: 'primary',
      disableRipple: true,
    },
    MuiPaper: {
      elevation: 0,
    },
    MuiTab: {
      disableRipple: true,
    },
    MuiCheckbox: {
      disableRipple: true,
    },
    // @ts-ignore
    MuiLink: {
      underline: 'always',
    },
    MuiTypography: {
      variant: 'body1',
    },
    MuiStepper: {
      alternativeLabel: true,
    },
    MuiListItem: {},
    MuiTooltip: {
      arrow: true,
      leaveDelay: 500,
      interactive: true,
    },
    MuiSelect: {
      native: true,
    },
    MuiFormControl: {
      variant: 'outlined',
    },
  },
}

export default themeConfig
