import { observer } from 'mobx-react-lite'
import {
  createStyles,
  withStyles,
  WithStyles,
  Theme,
} from '@material-ui/core/styles'
import classNames from 'classnames'
import { formatDistance, parseISO } from 'date-fns'

import Typography from '@material-ui/core/Typography'

type Props = WithStyles<typeof styles> & {
  time: string
  className?: string
  isNew?: boolean
}

const styles = ({ palette }: Theme) =>
  createStyles({
    root: {
      color: palette.grey[500],
      fontWeight: 600,
    },
  })

const ComponentTime = observer((props: Props) => {
  const { classes, time } = props
  const parsedTime = formatDistance(parseISO(time), new Date())
  return (
    <Typography className={classNames(classes.root, props.className)}>
      {props.isNew ? 'Merged' : 'Last deployed'} {parsedTime} ago
    </Typography>
  )
})

export default withStyles(styles)(ComponentTime)
