import React from 'react'
import AddOrgContainer from '../containers/AddOrgContainer'
import { Grid } from '@material-ui/core'
import PageTitle from 'common/components/PageTitle'
import { withRouter, RouteComponentProps } from 'react-router-dom'

type Props = RouteComponentProps<
  {},
  {},
  { customerName?: string; customerId?: string }
>

const AddOrgScreen: React.FC<Props> = ({ location }) => {
  const { state } = location

  return (
    <React.Fragment>
      <PageTitle title="Provision" />
      <Grid container justifyContent="center">
        <Grid item md={4}>
          <h2>
            {`Add new organisation to
              ${
                state && state.customerName
                  ? state.customerName
                  : 'a Stax customer'
              }
            `}
          </h2>
          <AddOrgContainer selectedCustomerId={state && state.customerId} />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default withRouter(AddOrgScreen)
