import React from 'react'
import { observer } from 'mobx-react-lite'
import { Account } from 'account/stores/types'
import RootStore from 'common/stores/RootStore/RootStore'
import Loading from 'common/components/Loading'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import NoResults from 'common/components/NoResults'

const getTableRows = (accounts: Account[]) => {
  return accounts.map((item) => {
    return {
      key: item.id,
      cells: [item.name, item.awsAccountId, item.email],
    }
  })
}

const headers = [{ title: 'Name' }, { title: 'Account ID' }, { title: 'Email' }]

const BillingRootPoolContainer = observer(() => {
  const { billingroot } = React.useContext(RootStore)

  React.useEffect(() => {
    billingroot.fetch('UNUSED')
  }, [])

  const accounts = billingroot.poolList()
  const rows = getTableRows(accounts)

  if (billingroot.isLoading) return <Loading />

  if (rows.length < 1)
    return (
      <Paper>
        <NoResults title="No Available Billing Roots" />
      </Paper>
    )

  return (
    <Paper>
      <Table>
        <TableHead>
          <TableRow>
            {headers.map((header, index) => (
              <TableCell key={index}>{header.title}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index}>
              {row.cells.map((cell, index) => (
                <TableCell key={index}>{cell}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  )
})

export default BillingRootPoolContainer
