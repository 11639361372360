import { observer } from 'mobx-react-lite'
import { Link } from '@material-ui/core'
import { ExitToApp } from '@material-ui/icons'

type Props = {
  activeVersion: string | undefined
  targetVersion: string
  repoName: string
}

function constructUrl(
  activeVersion: string,
  targetVersion: string,
  repoName: string,
): string {
  // Construct GitHub diff URL
  return `https://github.com/jumacloud/${repoName}/compare/${activeVersion}...${targetVersion}`
}

const ComponentDiff = observer((props: Props) => {
  const { activeVersion, targetVersion, repoName } = props

  if (!activeVersion) return null

  const diffUrl = constructUrl(activeVersion, targetVersion, repoName)

  return (
    <Link href={diffUrl} target="_blank" title="Diff">
      Diff{' '}
      <ExitToApp
        style={{
          fontSize: '1rem',
          marginBottom: -'3px',
        }}
      />
    </Link>
  )
})

export default ComponentDiff
