import Auth from '@aws-amplify/auth'
import { Hub } from '@aws-amplify/core'
import { HubCallback, HubPayload } from '@aws-amplify/core/lib/Hub'
import React, { useEffect, useReducer } from 'react'

import {
  Action,
  CognitoUser,
  Dispatch,
  HocConsumerComponent,
  HocConsumerProps,
  Props,
  State,
  User,
} from 'common/AmplifyListener/AmplifyListener.d'

/**
 * USAGE
 * Choose your own adventure:
 *   - use the AmplifyAuthConsumer component for an implementation like:
 *      ```
 *        { state => (
            <code>
              {state.loggedIn && state.user && JSON.stringify(state.user)}
            </code>
          ) }
        ```
    - use the withAmplifyAuth higher-order component for a neat little HOC impl:
        ```
          // MyComponent gets a prop called authState, which = State
          export default withAmplifyAuth(MyComponent)
        ```
 */

const DEFAULT_STATE: State = {
  loggedIn: false,
  isLoading: true,
}

export const AuthContext = React.createContext<HocConsumerProps>({
  authState: DEFAULT_STATE,
  // @ts-ignore figure out a better way to deal with these fns being injected
  login: () => {},
  // @ts-ignore figure out a better way to deal with these fns being injected
  resetPassword: () => {},
})

/**
 * Get auth state from Amplify (with AmplifyAuthProvider)
 *    and use it anywhere in your app (with AmplifyAuthConsumer)
 */
export const AmplifyAuthConsumer = AuthContext.Consumer

export const AmplifyAuthProvider = ({ children }: Props) => {
  const [authState, dispatch] = useReducer(reducer, DEFAULT_STATE)

  useEffect(
    () => {
      const hubCallback: HubCallback = (capsule) =>
        dispatchHubPayload(dispatch, capsule.payload)

      Hub.listen('auth', hubCallback)

      return function unMount() {
        Hub.remove('auth', hubCallback)
      }
    },
    [], // run once, à la componentDidMount
  )

  const login: HocConsumerProps['login'] = (
    username: string,
    password: string,
  ) => {
    dispatch({ type: 'SET_LOGIN_USERNAME', data: username })
    return Auth.signIn(username, password)
  }

  const forgotPassword: HocConsumerProps['forgotPassword'] = (
    username: string,
  ) => {
    dispatch({ type: 'SET_LOGIN_USERNAME', data: username })
    dispatch({ type: 'RESET_PASSWORD' })
    return Auth.forgotPassword(username)
  }

  const forgotPasswordSubmit: HocConsumerProps['forgotPasswordSubmit'] = (
    username: string,
    code: string,
    password: string,
  ) => {
    dispatch({ type: 'SET_LOGIN_USERNAME', data: username })
    return Auth.forgotPasswordSubmit(username, code, password)
  }

  const logout = async () => await Auth.signOut({ global: true })

  const fetchUserSessionProp = async () => await fetchUserSession(dispatch)

  return (
    <AuthContext.Provider
      value={{
        authState,
        login,
        logout,
        forgotPassword,
        forgotPasswordSubmit,
        fetchUserSession: fetchUserSessionProp,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

// HOC implementation, assumes it has a parent Provider somewhere up the tree
export const withAmplifyAuth =
  (Component: HocConsumerComponent) => (otherProps: any) => (
    <AuthContext.Consumer>
      {(ourProps: HocConsumerProps) => (
        <Component {...ourProps} {...otherProps} />
      )}
    </AuthContext.Consumer>
  )

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'LOGGED_IN':
      return {
        ...state,
        isLoading: false,
        loggedIn: true,
        user: action.data,
      }
    case 'PASSWORD_RESET_REQUIRED':
      return {
        ...state,
        isLoading: false,
        loggedIn: false,
        user: undefined,
        requiresNewPassword: true,
      }
    case 'LOGIN_ERROR':
      return {
        ...state,
        isLoading: false,
        loggedIn: false,
        user: undefined,
        error: {
          message: action.data.message,
          code: action.data.code, // ie: "InvalidParameterException"
        },
      }
    case 'LOGGED_OUT':
      return {
        ...state,
        isLoading: false,
        loggedIn: false,
      }
    case 'SET_LOGIN_USERNAME':
      return {
        ...state,
        loginUsername: action.data,
      }
    case 'RESET_PASSWORD':
      return {
        ...state,
        requiresNewPassword: false,
      }
  }

  return state
}

/**
 * Handle payloads from Amplify Hub
 *    - essentially this is a layer between the React reducer and Amplify.Auth events
 * @param dispatch React state setter
 * @param action Hub Payload
 */
function dispatchHubPayload(dispatch: Dispatch, action: HubPayload) {
  console.log({ action })
  switch (action.event) {
    case 'signIn':
      console.debug('AmplifyAuthProvider: user signed in', { action })
      // we could immediately dispatch the login action here, but let's
      //  confirm login state and parse the user attributes in fetchUserSession
      fetchUserSession(dispatch)
      break

    case 'signIn_failure':
      console.debug('AmplifyAuthProvider: user sign in failed', { action })

      if (
        action.data &&
        action.data.code === 'PasswordResetRequiredException'
      ) {
        return dispatch({ type: 'PASSWORD_RESET_REQUIRED', data: action })
      }

      return dispatch({ type: 'LOGIN_ERROR', data: action })

    case 'signOut':
      console.debug('AmplifyAuthProvider: user signed out', { action })
      return dispatch({ type: 'LOGGED_OUT' })

    case 'configured':
      console.debug('AmplifyAuthProvider: the Auth module is configured', {
        action,
      })
      break
  }
}

async function fetchUserSession(dispatch: Dispatch) {
  try {
    // TODO: in the future, should we substitute this for a call to fetchUser in Graphql?
    const user = await fetchCognitoSession()

    if (!user) throw new Error('Could not fetch cognito sessions')

    console.debug('fetchUserSession success', { user })

    // TODO: deal with MFA challenge

    // TODO: deal with user.challengeName === 'NEW_PASSWORD_REQUIRED'

    dispatch({
      type: 'LOGGED_IN',
      data: user,
    })
  } catch (error) {
    console.debug('fetchUserSession failed', error)
    dispatch({ type: 'LOGGED_OUT' })
  }
}

async function fetchCognitoSession(): Promise<User | null> {
  try {
    // TODO: in the future, should we substitute this for a call to fetchUser in Graphql?
    const cognitoUser = await Auth.currentAuthenticatedUser()

    console.debug('fetchCognitoSession success', { cognitoUser })

    return parseUser(cognitoUser)
  } catch (error) {
    console.debug('fetchCognitoSession failed', error)
  }

  return null
}

/**
 * Parse the different shapes of user into a Stax-friendly one
 * @param attributes
 */
export const parseUser = (user: CognitoUser): User => {
  console.log('parseUser', { user })
  return {
    username: user.attributes.email,
    email: user.attributes.email,
    name: user.attributes.name,
    id: user.attributes.sub,
    idamRoles: splitIdamRoles(user.attributes['custom:idam_roles']),
  }
}

function splitIdamRoles(idamRoles: string): string[] {
  // If we have idamRoles
  if (idamRoles) {
    // If we are in multiple groups determined by [] then we need to split the string
    if (idamRoles.match(/\[[^\]]*\]/)) {
      return idamRoles.replace('[', '').replace(']', '').split(', ')
      // Must be in a single group
    } else {
      return [idamRoles]
    }
  }

  // No idamRoles supplied
  return []
}
