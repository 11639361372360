import React from 'react'
import {
  createStyles,
  withStyles,
  WithStyles,
  Theme,
} from '@material-ui/core/styles'
import { Status } from 'common/api'
import { Button, Typography } from '@material-ui/core'

type Props = WithStyles<typeof styles> & {
  numReleaseItems: number
  onReleaseButtonClick: any
  storeStatus: Status
  numReleaseSuccesses: number
  numReleaseFails: number
}

const styles = ({ spacing, palette }: Theme) =>
  createStyles({
    container: {
      borderTop: `1px solid ${palette.divider}`,
      height: '100%',
      width: '100%',
    },
    banner: {
      backgroundColor: palette.grey[200],
      marginTop: spacing(3),
      marginLeft: spacing(1),
      marginRight: spacing(1),
    },
    numberText: {
      fontWeight: 'bold',
    },
    buttonText: {
      fontWeight: 'bold',
      color: palette.common.white,
    },
    button: {
      marginTop: spacing(2),
      height: spacing(6),
      width: '100%',
    },
    buttonContainer: {
      marginTop: spacing(3),
      marginLeft: spacing(3),
      marginRight: spacing(3),
      boxSizing: 'border-box',
    },
    releaseSummary: {
      marginTop: spacing(2),
      marginLeft: spacing(3),
    },
    successText: {
      color: 'green',
    },
  })

const ReleaseSidebarFooter: React.FC<Props> = ({
  classes,
  numReleaseItems,
  onReleaseButtonClick,
  storeStatus,
  numReleaseSuccesses,
  numReleaseFails,
}) => {
  const releaseComponents = () => {
    onReleaseButtonClick()
  }

  const isButtonDisabled = (): boolean => {
    if (numReleaseItems === 0) return true
    if (storeStatus !== Status.Idle) return true
    return false
  }

  const renderReleaseSummary = () => {
    return (
      <div className={classes.releaseSummary}>
        <Typography variant={'caption'} className={classes.successText}>
          {numReleaseSuccesses} components successfully released
        </Typography>
        <Typography variant={'caption'} color={'error'}>
          {numReleaseFails} components failed to release
        </Typography>
      </div>
    )
  }

  return (
    <div className={classes.container}>
      <div className={classes.banner}>
        <Typography
          variant={'h6'}
          color={'textSecondary'}
          className={classes.numberText}
          align={'center'}
          children={`${numReleaseItems} components selected for release`}
        />
      </div>
      <div className={classes.buttonContainer}>
        <Typography>
          Process will begin immediately. Components will deploy in the above
          order.
        </Typography>
        <Button
          className={classes.button}
          onClick={releaseComponents}
          disabled={isButtonDisabled()}
        >
          <Typography
            variant={'h6'}
            className={classes.buttonText}
            align={'center'}
          >
            {(storeStatus === Status.Error && 'Error') || 'RELEASE NOW'}
          </Typography>
        </Button>
      </div>
      {storeStatus !== Status.Idle ? renderReleaseSummary() : undefined}
    </div>
  )
}

export default withStyles(styles)(ReleaseSidebarFooter)
