import className from 'classnames'
import {
  createStyles,
  withStyles,
  WithStyles,
  Theme,
} from '@material-ui/core/styles'
import { NavLink } from 'react-router-dom'
import TextIcon from 'common/components/TextIcon'
import { NavLinkName } from 'navigation/NavLinks'

type Props = WithStyles<typeof styles> &
  React.ComponentProps<typeof NavLink> & {
    name: NavLinkName
    compact: boolean
  }

const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'center',
      marginLeft: spacing(2),
      marginRight: spacing(2),
      color: 'white',
      opacity: 0.6,
      textDecoration: 'none',
      transition: 'opacity .5s, height .6s, border-bottom 500ms',
      '&:hover': {
        opacity: 1,
      },
    },
    compact: {
      height: '3.5rem',
      borderBottom: '3px solid transparent',
    },
    regular: {
      height: '5rem',
    },
  })

const NavItem = (props: Props) => {
  const { classes, to, name, title = '', compact } = props
  return (
    <NavLink
      to={to}
      className={className(
        classes.root,
        compact ? classes.compact : classes.regular,
      )}
      activeStyle={{
        color: '#fff',
        opacity: 1,
        borderBottom: compact ? '3px solid #00CCCF' : 'none',
      }}
    >
      <TextIcon name={name} title={title} layout="column" compact={compact} />
    </NavLink>
  )
}

export default withStyles(styles)(NavItem)
